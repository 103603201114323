import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";

  // Customizable Area Start
export const configJSON = require("./config");
import CreateRestApiMessage from "../../../components/src/CreateRestApiMessage.web";
import { IProjectData } from "../../dashboard/src/types";
import { getStorageData } from "framework/src/Utilities";
import { IInvitation } from "./FreelancerInvitationsController.web";
import { toast } from "react-toastify";
import { ChangeEvent } from "react";
import moment from "moment";
import { IProfile } from "../../rolesandpermissions/src/Roles.types";
interface IInputValues{ 
  amount:string;
  startDate:string;
  endDate:string;
  description:string;
}
const inputInitialValue={
  amount:"",
  startDate:"",
  endDate:"",
  description:""
}
  // Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  classes?:any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
 
  // Customizable Area Start
  getProjectLoading:boolean;
  projectInfo:IProjectData | null;
  getInvitationLoading:boolean;
  invitationInfo:IInvitation | null;
  openAcceptInviteModal:boolean;
  acceptLoading:boolean;
  rejectInviteModal:boolean;
  rejectInviteLoading:boolean;
  openAcceptInviteRadio:boolean;
  bidInput:IInputValues;
  bidErrors:IInputValues;
  currentUser:IProfile | null;
  // Customizable Area End

}

interface SS { }

export default class FreelancerInvitationDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProjectDetailsApiCallID:string="";
  getInvitationDetailsApiCallId:string="";
  acceptInvitationApiCallId:string="";
  rejectInvitationApiCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      // Customizable Area Start
      getName(MessageEnum.LoginUserMessage),
      // Customizable Area End
    ]);

    this.state = {
        // Customizable Area Start
        getProjectLoading:false,
        projectInfo:null,
        getInvitationLoading:false,
        invitationInfo:null,
        openAcceptInviteModal:false,
        acceptLoading:false,
        openAcceptInviteRadio:false,
        rejectInviteLoading:false,
        rejectInviteModal:false,
        bidErrors:inputInitialValue,
        bidInput:inputInitialValue,
        currentUser:null,
        // Customizable Area End
    };
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (message.id === getName(MessageEnum.LoginUserMessage)) {
      const user = message.getData(getName(MessageEnum.LoginUserBodyMessage));
       this.handleCurrentUserResponse(user);
    }
    // Customizable Area End
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

    // Customizable Area Start
     this.handleApiResponses(apiRequestCallId,responseJson)
    // Customizable Area End

    }
  }
// Customizable Area Start
handleCurrentUserResponse=(user:any)=>{
  if(user?.id){
    this.setState({ currentUser: user });
  }
}
handleApiResponses=(apiReqCallId:string,responseJson:any)=>{
  switch(apiReqCallId){
     case this.getProjectDetailsApiCallID:{
        this.handleProjectApiResponse(responseJson)
        break;
     }
     case this.getInvitationDetailsApiCallId:{
         this.handleInvitationApiResponse(responseJson);
         break;
     }
     case this.acceptInvitationApiCallId:{
         this.handleAcceptInvitationApiResponse(responseJson);
         break;
     }
     case this.rejectInvitationApiCallId:{
         this.handleRejectInvitationApiResponse(responseJson);
         break;
     }
  }
}
handleRejectInvitationApiResponse=(responseJson:any)=>{
  this.setState({rejectInviteLoading:false,rejectInviteModal:false})
  if(responseJson?.invitation?.data?.id){
    toast.success("Invitation rejected successfully.")
    this.setState(prev=>({
       invitationInfo:prev.invitationInfo?{
         ...prev.invitationInfo,
         attributes:{
           ...prev.invitationInfo.attributes,
           status:"rejected"
         }
       }:null,
       rejectInviteModal:false,
    }))
  }else{
    toast.error("Invitation Failed to Reject!")
  }
}
handleAcceptInvitationApiResponse=(responseJson:any)=>{
  this.setState({acceptLoading:false,openAcceptInviteModal:false})
  if(responseJson?.invitation?.data?.id){
    toast.success("Invitation accepted successfully.")
    this.setState(prev=>({
       invitationInfo:prev.invitationInfo?{
         ...prev.invitationInfo,
         attributes:{
           ...prev.invitationInfo.attributes,
           status:"accepted"
         }
       }:null,
       openAcceptInviteModal:false,
       openAcceptInviteRadio:false,
       bidInput:inputInitialValue
    }))
  }else{
    toast.error("Invitation Failed to Accept!")
  }
}
handleProjectApiResponse=(responseJson:any)=>{
    this.setState({getProjectLoading:false})
   if(responseJson?.data?.id){
      this.setState({projectInfo:responseJson?.data},()=>{
        const {projectInfo}=this.state;
        this.calculateBidEndDate(projectInfo?.attributes?.select_duration_type as string,Number(projectInfo?.attributes?.project_size))
      })
   }else{
     toast.error("No Project Found!!")
   }
}
handleInvitationApiResponse=(responseJson:any)=>{
   this.setState({getInvitationLoading:false})
   if(responseJson?.invitation?.data?.id){
      this.setState({invitationInfo:responseJson?.invitation?.data},()=>{
         const {invitationInfo}=this.state;
         this.getProjectData(invitationInfo?.attributes?.project_id?.toString() as string);
      })
   }else{
    toast.error("Invitation Not Found!!")
   }
}
async componentDidMount() {
  this.getInvitationsInfo();
  this.sendGetCurrentUserMessage();
}
sendGetCurrentUserMessage=()=>{
  const msg: Message = new Message(
    getName(MessageEnum.GetCurrentUserMessage)
  );

  this.send(msg);
}
handleOpenRejectInviteModal=()=>{
  this.setState({
    rejectInviteModal:true
  })
}

handleCloseRejectInviteModal=(open:boolean)=>{
  this.setState({rejectInviteModal:open})
}
handleOpenAcceptInviteModal=()=>{
  const isNonBidProject=this.state.projectInfo?.attributes?.bid_status===1;
  if(isNonBidProject){
    this.setState({
      openAcceptInviteModal:true
    })
  }else{
    this.setState(prev=>(
      {
        openAcceptInviteRadio:!prev.openAcceptInviteRadio
     }
    ))
  }
}

handleCloseAcceptInviteModal=(open:boolean)=>{
  this.setState({openAcceptInviteModal:open})
}

handleBidInputChange=(e:ChangeEvent<HTMLInputElement>)=>{
  const keyName=e.target?.name as any;
  const value=e.target?.value;
  this.setState((prev)=>({
     bidInput:{
      ...prev.bidInput,
      [keyName]:value
     }
  }),()=>{
    if(keyName==="startDate"){
      const {projectInfo}=this.state;
      if(this.isDateValid()){
        this.calculateBidEndDate(projectInfo?.attributes?.select_duration_type as string,Number(projectInfo?.attributes?.project_size),value)
      }
    }
  })
}

isDateValid=()=>{
  const {bidInput:{startDate}}=this.state;
  const changedStartDate = new Date(startDate);
  let isValid=true;
  const currentDate=this.getCurrentDate();
  if(startDate && startDate<currentDate){
    isValid=false;
  }
  if(changedStartDate.getFullYear()>9999 || isNaN(changedStartDate.getFullYear())){
      isValid=false;
  } 
  return isValid;
}

getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};



handleSubmit=()=>{
  if(this.state.acceptLoading) return
  const {bidInput:{amount,description,endDate,startDate},projectInfo}=this.state;
  const projectType=projectInfo?.attributes.bid_status;
  let newError:any={};
  if(!amount?.trim() && projectType!==1){
    newError.amount="Please enter bid amount"
  }
  if(!description?.trim()){
    newError.description="Please enter bid description"
  }
  if(!endDate?.trim()){
    newError.endDate="Please select end date"
  }
   if(!startDate?.trim()){
    newError.startDate="Please select start date"
  }
  const currentDate=this.getCurrentDate();
  if(startDate && startDate<currentDate){
    newError.startDate="Please select a start date that is later than today."
  }
  if(endDate && endDate<currentDate){
    newError.endDate="Please select a end date that is later than today."
  
  }
  if(startDate && endDate){
     this.validateDate(newError)
  }
  if(Object.keys(newError)?.length>0){
    this.setState({bidErrors:newError})
    return;
  }
 this.setState({bidErrors:inputInitialValue});
 this.acceptInvitations();
}
validateDate=(newError:any)=>{
  const {bidInput:{startDate,endDate}}=this.state;
  const stDate = new Date(startDate);
  const enDate = new Date(endDate);
  if (stDate > enDate) {
    newError.startDate="start date should not be later than end date"
  }
  if(stDate.getFullYear()>9999 || isNaN(stDate.getFullYear())){
    newError.startDate="Please enter valid year"
  } 
  if(enDate.getFullYear()>9999 || isNaN(enDate.getFullYear())){
    newError.endDate="Please enter valid year"
  }
}

acceptInvitationDirectly=()=>{
  this.setState((prev)=>{
    return{
       bidInput:{
         ...prev.bidInput,
         description:"Automate Created Bid from Lancerscape",
         startDate:this.getCurrentDate()
       }
    }
  },()=>{
     this.acceptInvitations();
  })
}

calculateBidEndDate=(projectSizeType:string,projectDuration:number,value?:string)=>{
  let todayDate=moment(value);
  if(projectSizeType.includes("year")){
    todayDate.add(projectDuration,"years");
  }else if(projectSizeType.includes("month")){
    todayDate.add(projectDuration,"months");
  }else if(projectSizeType.includes("hour")){
    todayDate.add(projectDuration,"hours");
  }else if(projectSizeType.includes("day")){
    todayDate.add(projectDuration,"days");
  }
  const formattedDate=todayDate.format('YYYY-MM-DD');
  this.setState(prev=>({
     bidInput:{
       ...prev.bidInput,
       endDate:formattedDate
     }
  }))

}
getHeader=async()=>{
  const token = await getStorageData("loginSuccessToken");
  const header = {
    token: token
  };
  return header;
}
acceptInvitations=async()=>{
  this.setState({
     acceptLoading:true
  })  
  const {invitationInfo,projectInfo,bidInput}=this.state;
  const query:string[]=[];
  query.push(`invitation_id=${invitationInfo?.id as string}`);
  query.push(`project_id=${projectInfo?.id as string}`);
  query.push(`profile_id=${invitationInfo?.attributes.freelancer_id?.toString() as string}`);
  query.push(`proposal=${bidInput.description}`);
  query.push(`start_date=${bidInput.startDate}`);
  query.push(`end_date=${bidInput.endDate}`);
  if(bidInput.amount){
     query.push(`amount=${bidInput.amount}`);
  }
  const queryString=query.join("&");
  const header=await this.getHeader();
  const apiRequestMessage = CreateRestApiMessage({
   header:header,
   apiUrl: `${configJSON.acceptInvitationApiEndPoint}?${queryString}`,
   body: null,
   method: "POST",
 });
 this.acceptInvitationApiCallId = apiRequestMessage.messageId;
 runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);

}
rejectInvitations=async()=>{
    this.setState({
      rejectInviteLoading:true
  })  
  const {invitationInfo}=this.state;
  const query:string[]=[
    `id=${invitationInfo?.id}`,
    `message=asdf`
  ];
  
  const queryString=query.join("&");
  const header=await this.getHeader();
  const apiRequestMessage = CreateRestApiMessage({
    header:header,
    apiUrl: `${configJSON.rejectInvitationApiEndPoint}?${queryString}`,
    body: null,
    method: "POST",
  });
  this.rejectInvitationApiCallId = apiRequestMessage.messageId;
  runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
}

  getProjectData=async(projectId:string)=>{
     this.setState({getProjectLoading:true});
     const header=await this.getHeader();
     const apiRequestMessage = CreateRestApiMessage({
      header:header,
      apiUrl: `${configJSON.getSingleProjectApiUrl}/${projectId}`,
      body: null,
      method: "GET",
    });
    this.getProjectDetailsApiCallID = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  }
  getInvitationsInfo=async()=>{
     this.setState({getInvitationLoading:true});
     const invitationId=this.props.navigation.getParam("invitationId");
     const header=await this.getHeader();
     const apiRequestMessage = CreateRestApiMessage({
      header:header,
      apiUrl: `${configJSON.getSingleInvitationApiEndPoint}/${invitationId}`,
      body: null,
      method: "GET",
    });
    this.getInvitationDetailsApiCallId = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  }
  handleBack=()=>{
    this.props.navigation?.goBack();
  }

  // Customizable Area End
}