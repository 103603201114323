Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "projecttemplates";
exports.labelBodyText = "projecttemplates Body";

exports.btnExampleTitle = "CLICK ME";
exports.headingTxt = 'Create a New Project'
exports.projectTitleLabelTxt = "Write a title for your Project"
exports.projectTitlePlaceHolder = "Enter project title"
exports.roleLabelTxt = 'Add description about the project'
exports.rolePlaceholderTxt ='Enter description'
exports.projectCategoryLabelTxt = 'Project Category'
exports.projectCategoryPlaceholderTxt='Select Project Category'
exports.specialtyLabelTxt = 'Speciality'
exports.specialtyPlaceholderTxt = 'Select Speciality'
exports.uploadFileTxt ='Upload file'
exports.maxFileSizeTxt = 'Max file size: 15MB'
exports.noOfBidsTxt = 'No of Quotes'
exports.noOfBidsPlaceholderTxt = 'Please Enter No of Quotes'
exports.typeOfBidsTxt = 'Type of Quote'
exports.typeOfBidsPlaceholderTxt = 'Please Select Type of Quote'
exports.fileSize= '12MB'
exports.fileName= 'Logo Variation 2.pdf'
exports.continueBtnTxt = 'Continue'
exports.backBtnTxt = 'Back'
exports.ReviewandPostProjectTxt = 'Review and Post Your Project'
exports.reviewAndUpdateProjectTxt = 'Review and Update Your Project'
exports.editBtnTxt = 'Edit'
exports.titleLabelTxt = 'Title'
exports.projetTitleLabelTxt = 'Project Title'
exports.descriptionLabelTxt = 'Description of Your Project'
exports.skillsLabelTxt = 'Skills'
exports.languageLabelTxt = 'Language to Converse'
exports.languageValueTxt = 'English, Native'
exports.scopeLabelTxt = 'Scope'
exports.projectValueTxt ='1 to 2 Months'
exports.freelancerExperienceLabelTxt = 'Freelancer Experience Level'
exports.freelancerExperienceValueTxt = 'Intermediate'
exports.budgetLabelTxt = 'Budget'
exports.budgetValueTxt = '$2000, Negotiable'
exports.estimatedBudgetLabelTxt = 'Estimated Budget'
exports.saveasDraftBtnTxt ='Save as Draft'
exports.postProjectBtnTxt ='Post a Project Now'
exports.editProjectBtnTxt ='Save Changes'
exports.congratulationsTxt = 'Congratulations'
exports.successfulMsgTxt = 'Project Posted Successfully'
exports.draftProjectSuccessMessage = 'Project Saved as a Draft Successfully'
exports.editProjectSuccessMessage = 'Project Successfully Updated'
exports.getNotificationMsgTxt = 'You will receive a notification once a freelancer will apply.'
exports.postAnotherProjectBtnTxt = 'Post Another Project'
exports.browsePostedProjectsBtnTxt = 'Browse Posted Projects'
exports.addSkillsLabelTxt = 'Search and Add Up to 10 Skills'
exports.searchSkillsPlaceholderTxt = 'Search Skill'
exports.selectedSkillsLabelTxt='Selected Skills :'
exports.languagePlaceholderTxt = 'Select Your Language'
exports.languageLevelLabelTxt='Level of Language'
exports.languageLevelPlaceholderTxt = 'Select Your level'
exports.otherSkillsLabelTxt='Other Skills to Add'
exports.searchPlaceholder = 'Project title, keyword or company'
exports.filterBtnTxt = 'Filter'
exports.skillsLabelTxt = 'Skills'
exports.titleLabelTxt = 'Title'
exports.scopeLabelTxt = 'Scope'
exports.budgetLabelTxt = 'Budget'
exports.projectSizePlaceholder = 'Freelancer Experience Level'
exports.projectSizeLabelTxt = 'Expected Duration'
exports.freelancerLabelTxt = 'Freelancer Experience (Years)'
exports.durationPlaceholderTxt = 'Select Duration'
exports.experiancePlaceholderTxt = 'Select Experiance Level'
exports.numberOne = '1'
exports.numberTwo = '2'
exports.numberThree = '3'
exports.numberFour = '4'
exports.fixedPriceBtnTxt = 'Fixed Price'
exports.hourlyRateBtnTxt ='Hourly Price'
exports.hoursPlaceholder = 'Please Enter Hours'
exports.amountLabelTxt = 'Amount'
exports.negotiablePlaceholderTxt = 'Please select negotialble or not'
exports.negotiableLabelTxt = 'Negotiable'
exports.fromLabelTxt = 'From'
exports.toLabelTxt = 'To'
exports.hourLabelTxt = 'Hours'
exports.failureText = 'Oops !'
exports.postProjectEmptyTxt = 'Project Drafts are Empty'
exports.letsPostProjectTxt = "Let's post a project for your next opening"
exports.gotoDashboardBtnTxt = "Go to Dashboard"
exports.searchPlaceholderTxt = "Search Project Title or Company"
exports.experianceLabelTxt = 'Experience'
exports.reuseAndEditBtnTxt = 'Reuse & Edit'
exports.deleteBtnTxt = 'Delete'
exports.showMoreBtnTxt = 'Show More'
exports.httpGetMethod = 'GET'
exports.httpPostMethod = 'POST'
exports.httpDeleteMethod = 'DELETE'
exports.httpPutMethod = 'PUT'
exports.getAllSkillsEndpoint = 'bx_block_profile/skills/get_skills'
exports.getAllLanguagesEndPoint = 'bx_block_profile/projects/get_languages'
exports.getAllProjectCategoriesEndPoint = 'bx_block_profile/projects/get_project_categories'
exports.getAllSpecialitiesEndPoint = 'bx_block_profile/projects/get_project_spaciality'
exports.postProjectEndPoint = 'bx_block_profile/projects'
exports.getAllDraftProjectsEndPoint = 'bx_block_profile/projects/get_my_project?'
exports.getSingleProjectEndPoint = 'bx_block_profile/projects'
exports.getProfileIdEndpoint = 'bx_block_profile/profiles/null'
exports.getskills = 'bx_block_profile/search_algorithms/fetch_skills'
exports.getlanguage = 'bx_block_profile/search_algorithms/fetch_language'
exports.getcategory = 'bx_block_profile/search_algorithms/fetch_category'
exports.getspeciality = 'bx_block_profile/search_algorithms/fetch_specialist'
exports.emptyProjectsTxt = 'No Projects to show, please create a new project'
exports.filterLabelTxt = 'Filter'
exports.numberRegex = /^-?\d+(\.\d+)?$/;
exports.minimumLabelTxt = 'Minimum'
exports.maximumLabelTxt = 'Maximum'
exports.fixedPricePlaceholder = 'Please Enter Price'
exports.rateperHourLabelTxt = 'Rate per hour'
exports.projectDurationPlaceholder = 'Please Enter Number'
exports.getBidderCountEndpoint  = 'bx_block_profile/projects/get_bidder_count?category_name='

exports.milestoneTitleLabelTxt = 'Milestone Title'
exports.titlePlaceholder = 'Enter milestone Title'
exports.milestoneDescriptionLabelTxt = 'Milestone Description'
exports.descriptionPlaceholder = 'Enter milestone Description'
exports.milestoneDuedateLabelTxt = 'Required Days'
exports.requiredDaysPlaceholder = 'Enter Required Days to Complete Milestone'
exports.milestoneDepositAmountLabelTxt = 'Deposit Amount'
exports.despostiAMountPlaceholder = 'Enter Deposit Amount'
exports.cancelBtnTxt = 'Cancel'
exports.sendBtnTxt = 'Send'
exports.addNewMilestoneBtntxt='Add New Milestone'
exports.getCategoryMilestoneApiURl="bx_block_scheduling/get_milestones_through_category?category_id="
exports.getFreelancingExperienceListApiUrl="bx_block_auctionbidding/freelancer_experiences"
exports.dashboardbutton = 'Go to dashboard'
exports.postbutton = 'Create a New Project'
// Customizable Area End