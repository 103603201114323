// Customizable Area Start
import React from "react";
import FreelancerProjectsController, {
  Props,
} from "./FreelancerProjectsController.web";
import { Typography } from "@material-ui/core";
import FreelancerProjectsLayout from "./FreelancerProjectsLayout.web";
import FreelancerBrowseProject from "./FreelancerBrowseProject.web";
import FreelancerCompletedProject from "./FreelancerCompletedProject.web";
import FreelancerProgressProject from "./FreelancerProgressProject.web";
import FreelancerMyBid from "./FreelancerMyBid.web";
import FreelancerInvitations  from "../../../auctionbidding/src/FreelancerInvitations.web";

const ProjectsComponents: any = {
  "browse-all": {
    component: FreelancerBrowseProject,
    title: "Browse All Projects",
  },
  completed: {
    component: FreelancerCompletedProject,
    title: "Completed Projects",
  },
  progress: {
    component: FreelancerProgressProject,
    title: "Projects in Progress",
  },
  closed: {
    component: FreelancerProgressProject,
    title: "Closed Projects",
  },
  dispute: {
    component: FreelancerProgressProject,
    title: "Disputed Projects",
  },
  onhold: {
    component: FreelancerProgressProject,
    title: "On-Hold Projects",
  },
  "my-bid": {
    component: FreelancerMyBid,
    title: "My Bids",
  },
   "my-invitations": {
    component: FreelancerInvitations,
    title: "My Invitations",
  },
};

class FreelancerProjects extends FreelancerProjectsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const param: any = this.getParamName() ;

    const renderComponent = ProjectsComponents[param];

    let Component;
    let title;
    if (renderComponent) {
      Component = renderComponent.component;
      title = renderComponent.title;
    } else {
      Component = <Typography>Page Not Found</Typography>;
      title = "Page Not Found";
    }
    return (
      <FreelancerProjectsLayout
        navigation={this.props.navigation}
        title={title}
        route={param}
      >
        <Component navigation={this.props.navigation} param={param} />
      </FreelancerProjectsLayout>
    );
  }
}

export default FreelancerProjects;

// Customizable Area End
