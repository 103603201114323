import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  history?: any;
  location?: any;
  classes?: any;
  route?: any;
  id?: any;
}

export interface S {
  // Customizable Area Start
  currentUser: any;
  profileInfo:any;
  isLoading : boolean
  reviwdata:any;
  role:string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class PublicProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserProfileDetailsId: string = "";
  currentUser: any;
  getUserReviewDetailsId:string="";

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.LoginUserMessage)
    ];

    this.state = {
      // Customizable Area Start
      currentUser: null,
      profileInfo:"",
      isLoading:false,
      reviwdata:[],
      role:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async componentDidMount() {
    this.sendGetCurrentUserMessage();
    this.getUserProfileDetails();
    super.componentDidMount();
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
    if (!prevState.currentUser && this.state.currentUser && (prevState.currentUser !== JSON.stringify(this.state.currentUser))) {
      this.getUserReview()
    } 
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.LoginUserMessage)) {
      const user = message.getData(getName(MessageEnum.LoginUserBodyMessage));
      this.setState({ currentUser: user , role : user?.currentState });
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getUserProfileDetailsId) {
        this.setState({profileInfo:responseJson.data , isLoading:false})
      }
      if (apiRequestCallId === this.getUserReviewDetailsId) {
         responseJson?.reviews?.data && this.setState({reviwdata: responseJson?.reviews?.data})
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  sendGetCurrentUserMessage = () => {
    const msg: Message = new Message(
      getName(MessageEnum.GetCurrentUserMessage)
    );

    this.send(msg);
  }
  onGoBack = () => {
    this.props.navigation.goBack()
  }

  getUserProfileDetails = async () => {
    this.setState({isLoading:true})
    const token = await getStorageData("loginSuccessToken");
    const header = {
      token: token,
    };
    let id = this.props.navigation.getParam('profileId')
    const getUserProfileRequest: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserProfileDetailsId = getUserProfileRequest.messageId;

    getUserProfileRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getProfileDetailsEndPoint}/${id}`

    );
    getUserProfileRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    getUserProfileRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(getUserProfileRequest.id, getUserProfileRequest);
  };

  getUserReview = async () => {
    const token = await getStorageData("loginSuccessToken");
    const header = {
      token: token,
    };
    let id = this.props.navigation.getParam('profileId')
    let role = this.state.currentUser?.currentState?.toLowerCase()?.includes("sponsor") ? "sponsor": "freelancer"


    const getUserProfileRequest: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserReviewDetailsId = getUserProfileRequest.messageId;

    getUserProfileRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getProfileReviewDetailsEndPoint}?profile_id=${id}&current_page=1&per_page=5&rated_from=${role}`
    );
    getUserProfileRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    getUserProfileRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(getUserProfileRequest.id, getUserProfileRequest);
  };
  // Customizable Area End
}
