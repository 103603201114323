import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import CreateRestApiMessage from "../../../components/src/CreateRestApiMessage.web";
import { getStorageData } from "../../../framework/src/Utilities";

interface SponsorProjectListType{
  project_id: number | string,
  project_name: string,
}

interface SponsorProjectChartListType
  {
    id: number;
    name: string;
    expectedCompletedDate: null | string;
    exactCompletedDate: null | string;
    milestone: any;
    due_date: string;
    cost: string;
    milestone_status: string;
    start_date: string;
    end_date: string;
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  currentUser: any,
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  searchTerm: string;
  dropdownOptions: any;
  selectedOption: any;
  data: any;
  piedata: any;
  color: any;
  chipdata: any;
  chipNames: { name: string, state: string, isActive: boolean }[];
  url: string,
  projects: any,
  searched: any,
  ticket: any,
  searched2: any,
  filteredtickets: any,
  authtoken: any,
  projectdata: any,
  bannerImage: any,
  profileidstate: any,
  search: string,
  biddata:any,
  talentdata : any,
  userprofilecount : number,
  ratingData:[];
  projectListLoading:boolean;
  projectChartLoading:boolean;
  sponsorProjectListData:SponsorProjectListType[];
  sponsorProjectChartList:any[];
  sponsorChartValues:string;
  sponsorProjectListError:string;
  currentPage: number
  isPaymentNextPage: boolean
  getPaymentHistoryLoading: boolean,
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  ProjectListID: string = "";
  TalentDataID :string="";
  PiechartdataID: string = "";
  apiDashboardBannerItemCallId: any;
  profileid: string = "";
  activebidId:string="";
  apiGetCurrentUserCallId:string=""
  getRatingsAPICallId:string = ""
  getAllSponsorProjectApiCallId: string=""
  getSponsorProjectChartListApiCallId: string=""
  getSingleProjectInfoApiCallId:string="";
  getPaymentDataApiCallId:string="";
  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      currentUser: null,
      dashboardData: [],
      errorMsg: "",
      token: "",
      loading: false,
      searchTerm: "",
      profileidstate: "",
      authtoken: window.localStorage.getItem('loginSuccessToken'),
      dropdownOptions: [
        { value: "Option 1", label: "Option 1" },
        { value: "Option 2", label: "Option 2" },
        { value: "Option 3", label: "Option 3" }
      ],
      selectedOption: null,
      projectdata: [],
      data: [
        {
          name: "Jan",
          amt: 0,
          month: 100,
        },
        {
          name: "Feb",
          amt: 100,
          month: 20,
        },
        {
          name: "Mar",
          amt: 200,
          month: 200,
        },
        {
          name: "Apr",
          amt: 300,
          month: 390,
        },
        {
          name: "May",
          amt: 400,
          month: 350,
        },
        {
          name: "Jun",
          amt: 500,
          month: 160,
        },
        {
          name: "Jul",
          amt: 400,
          month: 260
        },
        {
          name: "Aug",
          amt: 300,
          month: 360
        },
        {
          name: "Sep",
          amt: 50,
          month: 16
        },
        {
          name: "Oct",
          amt: 170,
          month: 80
        },
        {
          name: "Nov",
          amt: 375,
          month: 289
        },
        {
          name: "Dec",
          amt: 500,
          month: 460
        }
      ],
      piedata: null,
      color: ["#00A7A2", "#00B9FF", "#F3B229", "#00D066", "#E84A4A"],
      chipdata: [
        { key: 0, label: 'Adobe Photoshop' },
        { key: 1, label: 'Logo Design' },
        { key: 2, label: 'Branding' },
        { key: 3, label: 'Adobe Illustrator' },
      ],
      chipNames: [
        { name: 'Active', state: 'active', isActive: true },
        { name: 'In Progress', state: 'in-progress', isActive: false },
        { name: 'Completed', state: 'completed', isActive: false },
        { name: 'Closed', state: 'closed', isActive: false },
        { name: 'Drafts', state: 'draft', isActive: false },
      ],
      url: 'https://example.com',
      projects:[],
      searched: "",
      ticket: [
        {
          id: 1,
          project: 'Website Development',
          freelancer: 'John Doe',
          designation: 'Expert Graphic Designer',
          avatar: 'https://s3-alpha-sig.figma.com/img/a7f5/ebfd/4a59a05161122f86ab1d5330871a8565?Expires=1687737600&Signature=CrTJurlEtiyVI9vp3LHHDfXZO91xw6-Vv7AG7XXeKr94UfvHG0qrB1sJNsCcVtetByniiosEEbMCGalxhTjGh01mpbFB05AJg8c8GbpAXWPpuaodzg~lkEpLK5whbK0cD0b9f5Zm0PAeXET7MCGy0LeuMvZVVePoIAo8-6mDpkTpG6CpxSzrXmvqR7~qPX4GhKeDn5xqsmr8LYtAO8AwbYPnxpAMxrz979TpsyPL1oi5aQ8otDx~hZu39Oi88q6l424ULS9hm7imJLpc7gQj2aqY3SicExIDxcu6EPlBvxeSjg52GwspwMwcNFVkkZrW4qXWXs0NX-K4-jwvZZg~MQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
          subject: "any",
          category: "abc",
          amount: 205
        },
        {
          id: 2,
          project: 'Mobile App Design',
          freelancer: 'Jane Smith',
          designation: 'Expert Graphic Designer',
          avatar: 'https://s3-alpha-sig.figma.com/img/a7f5/ebfd/4a59a05161122f86ab1d5330871a8565?Expires=1687737600&Signature=CrTJurlEtiyVI9vp3LHHDfXZO91xw6-Vv7AG7XXeKr94UfvHG0qrB1sJNsCcVtetByniiosEEbMCGalxhTjGh01mpbFB05AJg8c8GbpAXWPpuaodzg~lkEpLK5whbK0cD0b9f5Zm0PAeXET7MCGy0LeuMvZVVePoIAo8-6mDpkTpG6CpxSzrXmvqR7~qPX4GhKeDn5xqsmr8LYtAO8AwbYPnxpAMxrz979TpsyPL1oi5aQ8otDx~hZu39Oi88q6l424ULS9hm7imJLpc7gQj2aqY3SicExIDxcu6EPlBvxeSjg52GwspwMwcNFVkkZrW4qXWXs0NX-K4-jwvZZg~MQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
          subject: "any",
          category: "abc",
          amount: 1000
        },
        {
          "id": 3,
          "project": "Logo Design",
          "freelancer": "Emily Johnson",
          "designation": "Expert Graphic Designer",
          avatar: 'https://s3-alpha-sig.figma.com/img/a7f5/ebfd/4a59a05161122f86ab1d5330871a8565?Expires=1687737600&Signature=CrTJurlEtiyVI9vp3LHHDfXZO91xw6-Vv7AG7XXeKr94UfvHG0qrB1sJNsCcVtetByniiosEEbMCGalxhTjGh01mpbFB05AJg8c8GbpAXWPpuaodzg~lkEpLK5whbK0cD0b9f5Zm0PAeXET7MCGy0LeuMvZVVePoIAo8-6mDpkTpG6CpxSzrXmvqR7~qPX4GhKeDn5xqsmr8LYtAO8AwbYPnxpAMxrz979TpsyPL1oi5aQ8otDx~hZu39Oi88q6l424ULS9hm7imJLpc7gQj2aqY3SicExIDxcu6EPlBvxeSjg52GwspwMwcNFVkkZrW4qXWXs0NX-K4-jwvZZg~MQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
          "subject": "any",
          "category": "abc",
          "amount": 500
        },
        {
          "id": 4,
          "project": "Brochure Design",
          "freelancer": "Michael Brown",
          "designation": "Senior Graphic Designer",
          avatar: 'https://s3-alpha-sig.figma.com/img/a7f5/ebfd/4a59a05161122f86ab1d5330871a8565?Expires=1687737600&Signature=CrTJurlEtiyVI9vp3LHHDfXZO91xw6-Vv7AG7XXeKr94UfvHG0qrB1sJNsCcVtetByniiosEEbMCGalxhTjGh01mpbFB05AJg8c8GbpAXWPpuaodzg~lkEpLK5whbK0cD0b9f5Zm0PAeXET7MCGy0LeuMvZVVePoIAo8-6mDpkTpG6CpxSzrXmvqR7~qPX4GhKeDn5xqsmr8LYtAO8AwbYPnxpAMxrz979TpsyPL1oi5aQ8otDx~hZu39Oi88q6l424ULS9hm7imJLpc7gQj2aqY3SicExIDxcu6EPlBvxeSjg52GwspwMwcNFVkkZrW4qXWXs0NX-K4-jwvZZg~MQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
          "subject": "any",
          "category": "abc",
          "amount": 800
        },
        {
          "id": 5,
          "project": "UI/UX Design",
          "freelancer": "Sarah Davis",
          "designation": "Creative Designer",
          avatar: 'https://s3-alpha-sig.figma.com/img/a7f5/ebfd/4a59a05161122f86ab1d5330871a8565?Expires=1687737600&Signature=CrTJurlEtiyVI9vp3LHHDfXZO91xw6-Vv7AG7XXeKr94UfvHG0qrB1sJNsCcVtetByniiosEEbMCGalxhTjGh01mpbFB05AJg8c8GbpAXWPpuaodzg~lkEpLK5whbK0cD0b9f5Zm0PAeXET7MCGy0LeuMvZVVePoIAo8-6mDpkTpG6CpxSzrXmvqR7~qPX4GhKeDn5xqsmr8LYtAO8AwbYPnxpAMxrz979TpsyPL1oi5aQ8otDx~hZu39Oi88q6l424ULS9hm7imJLpc7gQj2aqY3SicExIDxcu6EPlBvxeSjg52GwspwMwcNFVkkZrW4qXWXs0NX-K4-jwvZZg~MQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
          "subject": "any",
          "category": "abc",
          "amount": 1200
        }
      ],
      searched2: '',
      filteredtickets: [{}],
      bannerImage: [],
      search: "",
      biddata:[],
      talentdata:[],
      userprofilecount:0,
      ratingData:[],
      projectListLoading:false,
      projectChartLoading:false,
      sponsorProjectListData:[],
      sponsorProjectChartList:[],
      sponsorChartValues:'',
      sponsorProjectListError:'',
      currentPage: 1,
      isPaymentNextPage: false,
      getPaymentHistoryLoading: false,
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getProfileId()
    this.gettalentdata()
    this.getDashboardBanner();
    this.getActiveBidData()
    this.getCurrentUser()
    this.getRatingData()
    this.getAllSponsorProject()
    this.GetPaymentData()
    // Customizable Area End
  }
  
  getToken=()=>{
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.defaultcode(responseJson)
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.defaultcode2(errorReponse)
      }
    }

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (
      response && apiRequestCallId === this.profileid
    ) {
      this.setState({ profileidstate: response?.data?.id , currentUser:response?.data },()=>{
        this.prepareFinalProjectFetchQuery()
      })

    }
    if (
      response &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.ProjectListID.length > 0 &&
      apiRequestCallId === this.ProjectListID
    ) {
      if (response.projects) {
        this.setState({ projectdata: response.projects.data })
      }
      else{
        this.setState({ projectdata: [] })
      }
    }

    this.handleresponse (apiRequestCallId,response)

    if(response && apiRequestCallId == this.getRatingsAPICallId &&response.reviews){
      this.setState({ratingData:response?.reviews?.data})    
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleresponse = (apiRequestCallId: any, response: any) => {
    switch (apiRequestCallId) {
      case this.TalentDataID:
        this.setState({ talentdata: response?.data });
        break;
      case this.PiechartdataID:
        this.handlePieData(response);
        break;
      case this.activebidId:
        response?.bids &&
        this.setState({ biddata: response?.bids.data  });
        break;
      case this.getSingleProjectInfoApiCallId:{
        this.handleNavigateToEditProject(response);
        break;
      }
      case this.apiDashboardBannerItemCallId:
        this.setState({ bannerImage: response?.data });
        break;
        case this.getPaymentDataApiCallId:
          const isNextPageAvailable = !!response?.pagination?.next_page
          this.setState((prev) => ({ projects:[ ...prev.projects,...response?.reports?.data || []], getPaymentHistoryLoading: false, isPaymentNextPage: isNextPageAvailable }));
          break;
        case this.apiGetCurrentUserCallId:
          this.setState({  userprofilecount:response.profile_completion_percentage});
          break;
          case this.getAllSponsorProjectApiCallId:
            if(response.data){
              const extractedProjectListData = [
                  { project_id: "", project_name: "All Projects" },
                ...response.data.map((item: any) => ({
                  project_id: item.project_id.toString(),
                  project_name: item.project_name,
                })),
              ];
  
              this.setState({
                sponsorProjectListData: extractedProjectListData,
                sponsorChartValues: "",
                projectListLoading:false
              });
  
           this.getSponsorProjectChartList()
          }else if(typeof response.errors[0]==="string"){
            this.setState({sponsorProjectListError:response.errors[0]})
          }  
            this.setState({
              projectListLoading:false
            });

            break;
            case this.getSponsorProjectChartListApiCallId:
              this.setState({projectChartLoading:false})
              if(response &&  Array.isArray(response)){
                const extractedChartData = response?.map((item: any) => {
                  return {
                  id: item.id,
                  name: item.name,
                  expectedCompletedDate: item.expectedCompletedDate,
                  exactCompletedDate: item.exactCompletedDate,
                  due_date: item.milestone.due_date,
                  milestone_status: item.milestone.milestone_status,
                  start_date: item.milestone.start_date,
                  end_date: item.milestone.end_date,
                };
              });
            
              this.setState({ sponsorProjectChartList: extractedChartData });
              }
  
              break;
      default:
        break;
    }
  }
  
  handlePieData = (response: any) => {
    if (response.data) {
      this.setState({ piedata: response.data.piedata })
    }
   
  }

  trim = (value:any) =>{
    if(value.length > 12){
        return value.slice(0,12) + "..."
    }
    else{
        return value
    }
  } 
  hadleListAllProject = () => {
    this.props.navigation.navigate("ListOfAllPraposals");
  };

  // Customizable Area Start

  handleNavigateToEditProject=(response_json:any)=>{
    if(!response_json?.data?.id){
      return;
    }
    const editNavigationMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    editNavigationMsg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Projecttemplates"
    );
    editNavigationMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
     
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {type:"edt_project",data:response_json});
    editNavigationMsg.addData(
      getName(MessageEnum.NavigationRaiseMessage),
      raiseMessage
    );
    this.send(editNavigationMsg);
  }
  getChildData = (data: any) => {
    localStorage.setItem('childData', data);
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
    if (prevState.profileidstate !== this.state?.profileidstate) {
      this.getpiedata('')
      const initialOptions = ["Option 1", "Option 2", "Option 3"];
      this.setState({ dropdownOptions: initialOptions });
    }
  }

  handlesearch = (value: string) => {
    this.setState({ search: value },()=>{
      this.prepareFinalProjectFetchQuery();
    })
    if (value.length == 0) {
      this.setState({ search: "" },()=>{
        this.prepareFinalProjectFetchQuery();
      })
    }
  }

  getCurrentUser = async () => {
    this.setState({ loading: true });
    const userToken = localStorage.getItem("loginSuccessToken");
    const header = {
      "Content-Type": configJSON.jsonContentType,
      token: userToken,
    };
    const requestMessage = CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.getCurrentUserApiUrl}`,
      method: "GET",
      body: null,
    });
    this.apiGetCurrentUserCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSingleProjectInfoData=(_:boolean | undefined,id?:string|undefined)=>{
    const token = localStorage.getItem("loginSuccessToken");
    const header = {
      token: token,
      "Content-Type": "application/json",
    };
    const projectMsg = CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.getProjectApiUrl}/${id}`,
      body: null,
      method: "GET",
    });

    this.getSingleProjectInfoApiCallId = projectMsg.messageId;
    runEngine.sendMessage(projectMsg.id, projectMsg);
  }
  
  prepareFinalProjectFetchQuery = () => {
    const PROJECT_PER_PAGE=5;
    let finalProjectQuery: string[] = [];
    const { search } = this.state;
    finalProjectQuery.push(`profile_id=${this.state?.profileidstate}`)
    finalProjectQuery.push(`current_page=1`);
    finalProjectQuery.push(`per_page=${PROJECT_PER_PAGE}`);
    if (search) {
      finalProjectQuery.push(`search=${search}`);
    }
    const activechip = this.state.chipNames.find((item) => item.isActive === true)

    const pageQuery = `project_status=${activechip?.state}`;
    if (pageQuery) {
      finalProjectQuery.push(pageQuery);
    }
    const newQueryText = finalProjectQuery.join("&");
    this.getMyProjects(newQueryText);
  };

  handleChipClick = (index: number) => {
    // const newState = {
    // this.setState(newState);
    this.setState((prev)=>({
      chipNames:prev.chipNames.map((item,itemIndex)=>({...item,isActive:index===itemIndex})),
      projectdata:[],
    }),()=>{
      this.prepareFinalProjectFetchQuery();
    })
  };

   getRouteFromActiveChip(activechip:any) {
    switch (activechip.name) {
      case 'Active':
        return 'activeprojects';
      case 'In Progress':
        return 'projectinprogress';
      case 'Completed':
        return 'completedproject';
      case 'Closed':
        return 'closedproject';
      case 'Drafts':
        return 'draft';
      default:
        return '';
    }
  }
  handlenavigation = () => {
    const activechip = this.state.chipNames.find((item) => item.isActive === true)
    const route =this.getRouteFromActiveChip(activechip)
    this.props.navigation.navigate('Projects', { type:route})
  }

  handlenavigation2 = () => {
    const route ='activeprojects'
    this.props.navigation.navigate('Projects', { type:route})
  }

  handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(this.state.url);
    } catch (error) {
      console.error('Failed to copy text: ', error);
    }
  };

  getPaymentReports=()=>{
     const {searched,projects}=this.state;
     if(!searched.trim()){
      return projects;
     }
      const filteredProjects = this.state.projects.filter((project: any) => {
        const projectName = project.attributes.project_name?.toLowerCase();
        const projectTitle = project.attributes.title?.toLowerCase();
        const searchTerm = searched.toLowerCase();
        return projectName?.includes(searchTerm) || projectTitle?.includes(searchTerm);
      }); 
    return filteredProjects;
  }
  requestSearch = (searchedVal: string) => {      
    this.setState({ searched: searchedVal});
  };

  cancelSearch = () => {
    this.setState({ searched: "" });
  };

  requestSearch2 = (searchedVal: string) => {
    const filteredtickets = this.state.ticket.filter((project: any) => {
      return project.project.toLowerCase().includes(searchedVal.toLowerCase());
    });
    this.setState({ searched2: searchedVal, filteredtickets });
  };

  cancelSearch2 = () => {
    this.setState({ searched2: "" });
    this.requestSearch(this.state.searched2);
  };

  defaultcode = (responseJson: any) => {
    if (responseJson.data.length === 0) {
      this.setState({
        errorMsg: "Data Not Found",
        loading: false
      });
    } else {
      this.setState({
        dashboardData: responseJson.data,
        errorMsg: "",
        loading: false
      });
    }
  }

  defaultcode2 = (errorReponse: any) => {
    if (errorReponse === undefined) {
      this.setState({
        errorMsg: "Something went wrong",
        loading: false
      });
    } else {
      this.setState({
        errorMsg: errorReponse,
        loading: false
      });
    }
  }

  getMyProjects = (query: string) => {
    this.setState({
      loading: true,
    });
    const token = localStorage.getItem("loginSuccessToken");
    const header = {
      token: token,
      "Content-Type": "application/json",
    };
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: `bx_block_profile/projects/get_my_project?${query}`,
      body: null,
      method: "GET",
    });
    this.ProjectListID = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  };

  gettalentdata = () => {
    this.setState({
      loading: true,
    });
    const token = localStorage.getItem("loginSuccessToken");
    const header = {
      token: token,
    };
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: `bx_block_profile/profiles/category_count`,
      body: null,
      method:`${configJSON.httpGetMethod}`,
    });
    this.TalentDataID = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  };

  getProfileId = () => {
    const header = {
      token: this.state.authtoken,
    };
    const getProfileIdRequest: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.profileid = getProfileIdRequest.messageId;
    getProfileIdRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProfileIdEndpoint
    );
    getProfileIdRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    getProfileIdRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(getProfileIdRequest.id, getProfileIdRequest);
  };

  getpiedata = async (value: any) => {

    let formData = new FormData();
    formData.append("profile_id", this.state?.profileidstate)
    const header = {
      token: this.state.authtoken
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.PiechartdataID = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/projects/get_summery_info`
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  };

  getDashboardBanner = async () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.authtoken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardBannerItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardBannerGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getActiveBidData = async () => {
    const header = {
      token: this.state.authtoken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.activebidId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getsponsoractivebidUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getRatingData = async () => {
    const header = {
      token: this.state.authtoken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRatingsAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRatingAndReviewsAPIEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    
  }

  //---------------------Get all sponsor project ----------------------------------------//
getAllSponsorProject = async() => {
  this.setState({projectListLoading:true})
 const token =   await getStorageData('loginSuccessToken', false)
 const header = {
   token: token,
   "Content-Type": configJSON.dashboarContentType,
 };

 const requestMessage = new Message(
   getName(MessageEnum.RestAPIRequestMessage)
 );

 this.getAllSponsorProjectApiCallId = requestMessage.messageId;
 requestMessage.addData(
   getName(MessageEnum.RestAPIResponceEndPointMessage),
   configJSON.getSponsorProjectsApiCallUrl
 );

 requestMessage.addData(
   getName(MessageEnum.RestAPIRequestHeaderMessage),
   JSON.stringify(header)
 );

 requestMessage.addData(
   getName(MessageEnum.RestAPIRequestMethodMessage),
   configJSON.dashboarApiMethodType
 );
 runEngine.sendMessage(requestMessage.id, requestMessage);
};
//-------------------- sponsor projects ends -----------------------------//
//---------------------Get all sponsor project ----------------------------------------//
getSponsorProjectChartList = async() => {
this.setState({projectChartLoading:true})
 const token =   await getStorageData('loginSuccessToken', false)
 const header = {
   token: token,
   "Content-Type": configJSON.dashboarContentType,
 };

 const requestMessage = new Message(
   getName(MessageEnum.RestAPIRequestMessage)
 );

 this.getSponsorProjectChartListApiCallId = requestMessage.messageId;
 requestMessage.addData(
   getName(MessageEnum.RestAPIResponceEndPointMessage),
   configJSON.getSponsorProjectsChartListApiCallUrl + `${this.state.sponsorChartValues}`
 );

 requestMessage.addData(
   getName(MessageEnum.RestAPIRequestHeaderMessage),
   JSON.stringify(header)
 );

 requestMessage.addData(
   getName(MessageEnum.RestAPIRequestMethodMessage),
   configJSON.dashboarApiMethodType
 );
 runEngine.sendMessage(requestMessage.id, requestMessage);
};
//-------------------- freelancer projects ends -----------------------------//
handleSponsorChartChange=(event: any) => {
this.setState({ sponsorChartValues: event.target.value })
this.getSponsorProjectChartList()
}
// ------------------- sponsor payment data -----------------------------// 

GetPaymentData = async() => {
  const { currentPage } = this.state
  const perPageLimit = 4
  this.setState({
    getPaymentHistoryLoading: true
  })
  const token =   await getStorageData('loginSuccessToken', false)
  const header = {
    token: token,
    "Content-Type": configJSON.dashboarContentType,
  };

 const requestMessage = new Message(
   getName(MessageEnum.RestAPIRequestMessage)
 );

 this.getPaymentDataApiCallId = requestMessage.messageId;
 requestMessage.addData(
   getName(MessageEnum.RestAPIResponceEndPointMessage),
   `${configJSON.getPaymentDataApiCallUrl}?user_type=sponsor&per_page=${perPageLimit}&current_page=${currentPage}`
 );

 requestMessage.addData(
   getName(MessageEnum.RestAPIRequestHeaderMessage),
   JSON.stringify(header)
 );

 requestMessage.addData(
   getName(MessageEnum.RestAPIRequestMethodMessage),
   configJSON.dashboarApiMethodType
 );
 runEngine.sendMessage(requestMessage.id, requestMessage);
};
handleHistoryLoadMore = () =>{
  const { getPaymentHistoryLoading } = this.state
  if(!getPaymentHistoryLoading) {
    this.setState(
      (prev) => ({ currentPage: prev.currentPage + 1}),
    this.GetPaymentData
    )
  }
}
// ---------------------------------   //  
truncateTextLength = (str:string) => {
 return str.length > 12? str.substring(0, 12) + '...' : str;
};
navigateToProjectPageWithCategory=(id:string)=>{
  this.props.navigation?.history?.push(`/Projects/activeprojects?c=${id}`)
}
getNavigation=(id:string)=>{
  return ()=>this.navigateToProjectPageWithCategory(id);
}
  // Customizable Area End

}
