import React from "react";

import {
  // Customizable Area Start
  Container,
  Grid,
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
  Input,
  TextField,
  Typography,
  Box,
  Button,
  Chip
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Dropzone from "react-dropzone";
import { deleteIcon, fileIcon, uploadIcon } from "./assets";
import { BlockComponent } from "../../../framework/src/BlockComponent";

const noOfBidItems = [
  {
    id: 1,
    label: 10
  },
  {
    id: 2,
    label: 20
  }
];

const typeOfBidItems = [
  { id: 1, label: "Quote", value: "0" },
  { id: 2, label: "Non-Quote", value: "1" }
];

interface IProps {
  onComponentChange: (view: string) => void;
  onChange: (
    event:any
  ) => void;
  projectTitle: string;
  projectCategory: string;
  allProjectCategories: Category[];
  specialty: (string|number)[];
  allProjectSpecialities: Speciality[];
  typeOfBids: string;
  number_of_bidders: string;
  uploadedFiles: IFile[];
  handleFileDrop: (acceptedFiles: any) => void;
  descritption: string;
  formErrors: IFormErrors;
  deleteUploadedFile: (fileId: number,file:any) => void;
}
interface S {}

interface SS {
  id: any;
}

// Customizable Area End
import ProjecttemplatesController, {
  Category,
  IFile,
  IFormErrors,
  Speciality,
  configJSON
} from "./ProjecttemplatesController";

export default class ProjectTitleDetails extends BlockComponent<IProps, S, SS> {
  constructor(props: IProps) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <Container maxWidth={"lg"} style={webStyle.mainContainer}>
        <FormControl fullWidth style={webStyle.formControlStyle}>
          <FormHelperText style={webStyle.labelStyle} component="p">
            {configJSON.projectTitleLabelTxt}
          </FormHelperText>
          <Input
            id={`project_input_projectTitle`}
            data-test-id="project-titlee"
            placeholder={configJSON.projectTitlePlaceHolder}
            style={webStyle.placeholderStyle}
            name="projectTitle"
            value={this.props.projectTitle}
            onChange={this.props.onChange}
          />
          {this.props.formErrors && this.props.formErrors.projectTitle && (
            <Typography style={webStyle.errorStyle}>
              {this.props.formErrors.projectTitle}
            </Typography>
          )}
        </FormControl>
        <FormControl fullWidth style={webStyle.formControlStyle}>
          <FormHelperText style={webStyle.labelStyle} component="p">
            {configJSON.roleLabelTxt}
          </FormHelperText>
          <TextField
            data-test-id="input2"
            minRows={3}
            multiline
            id={`project_input_description`}
            name="descritption"
            onChange={this.props.onChange}
            value={this.props.descritption}
            placeholder={configJSON.rolePlaceholderTxt}
            InputProps={{
              style: webStyle.placeholderStyle
            }}
          />
          {this.props.formErrors && this.props.formErrors.description && (
            <Typography style={webStyle.errorStyle}>
              {this.props.formErrors.description}
            </Typography>
          )}
        </FormControl>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth style={webStyle.formControlStyle}>
              <FormHelperText style={webStyle.labelStyle} component="p">
                {configJSON.projectCategoryLabelTxt}
              </FormHelperText>
              <Select
                id={`project_input_projectCategory`}
                data-test-id="render1"
                value={this.props.projectCategory}
                onChange={event =>
                  this.props.onChange(event as React.ChangeEvent<
                    HTMLSelectElement
                  >)
                }
                renderValue={
                  this.props.projectCategory !== ""
                    ? undefined
                    : () => (
                        <Typography style={webStyle.placeholderStyle}>
                          {configJSON.projectCategoryPlaceholderTxt}
                        </Typography>
                      )
                }
                style={webStyle.selectItemValuestyle}
                displayEmpty
                name="projectCategory"
              >
                {this.props.allProjectCategories.map(category => {
                  return (
                    <MenuItem
                      key={category.id}
                      value={category.name}
                      style={webStyle.selectItemValuestyle}
                    >
                      {category.name}
                    </MenuItem>
                  );
                })}
              </Select>
              {this.props.formErrors &&
                this.props.formErrors.projectCategory && (
                  <Typography style={webStyle.errorStyle}>
                    {this.props.formErrors.projectCategory}
                  </Typography>
                )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth style={webStyle.formControlStyle}>
              <FormHelperText style={webStyle.labelStyle} component="p">
                  Sub Category
              </FormHelperText>
              <Select
                value={this.props.specialty}
                multiple={true}
                id={`project_input_specialty`}
                onChange={event =>
                  this.props.onChange(event as React.ChangeEvent<
                    HTMLInputElement | HTMLSelectElement
                  >)
                }
                renderValue={(selectedData)=>{
                  if(this.props.specialty.length>0){
                    return(
                      <div style={webStyle.chips}>
                        {(selectedData as string[]).map((value) => (
                          <Chip  
                          style={{
                            fontSize:"10px",
                            marginRight:"5px",
                            height:"23px"
                          }}
                           key={value} label={value}/>
                        ))}
                      </div>
                    )
                  }
                  return(
                    <Typography style={webStyle.placeholderStyle}>
                      Select Sub Category
                    </Typography>
                  )
                }
                  
                }
                displayEmpty
                name="specialty"
                style={webStyle.selectItemValuestyle}
              >
                {!this.props.allProjectSpecialities?.length && (
                   <MenuItem
                      value={""}
                      style={webStyle.selectItemValuestyle}
                      disabled
                    >
                      No subcategory available
                    </MenuItem>
                )}
                {this.props.allProjectSpecialities.map(speciality => {
                  return (
                    <MenuItem
                      key={speciality.id}
                      value={speciality.name}
                      style={webStyle.selectItemValuestyle}
                    >
                      {speciality.name}
                    </MenuItem>
                  );
                })}
              </Select>
              {this.props.formErrors && this.props.formErrors.specialty && (
                <Typography style={webStyle.errorStyle}>
                  {this.props.formErrors.specialty}
                </Typography>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth style={webStyle.formControlStyle}>
              <FormHelperText style={webStyle.labelStyle} component="p">
                {configJSON.typeOfBidsTxt}
              </FormHelperText>
              <Select
                id={`project_input_typeOfBids`}
                value={this.props.typeOfBids}
                onChange={event =>
                  this.props.onChange(event as React.ChangeEvent<
                    HTMLInputElement | HTMLSelectElement
                  >)
                }
                renderValue={
                  this.props.typeOfBids !== ""
                    ? undefined
                    : () => (
                        <Typography
                          style={{
                            ...webStyle.placeholderStyle,
                            height: "16px"
                          }}
                        >
                          {configJSON.typeOfBidsPlaceholderTxt}
                        </Typography>
                      )
                }
                displayEmpty
                name="typeOfBids"
                style={webStyle.selectItemValuestyle}
              >
                {typeOfBidItems.map(type => {
                  return (
                    <MenuItem
                      key={type.id}
                      value={type.value}
                      style={webStyle.selectItemValuestyle}
                    >
                      {type.label}
                    </MenuItem>
                  );
                })}
              </Select>
              {this.props.formErrors && this.props.formErrors.typeOfBids && (
                <Typography style={webStyle.errorStyle}>
                  {this.props.formErrors.typeOfBids}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth style={
               webStyle.formControlStyle
              }>
              <FormHelperText style={  this.props.typeOfBids==="1"?
                {
                  ...webStyle.labelStyle,
                  color:"#b6b6b6"
                }:webStyle.labelStyle} component="p">
                {configJSON.noOfBidsTxt}
              </FormHelperText>
              <TextField
                disabled={this.props.typeOfBids==="1"}
                value={this.props.number_of_bidders}
                id={`project_input_number_of_bidders`}
                type="number"
                onChange={event =>
                  this.props.onChange(event as React.ChangeEvent<
                    HTMLInputElement | HTMLSelectElement
                  >)
                }
                placeholder={configJSON.noOfBidsPlaceholderTxt}
                InputProps={{
                  style: {...webStyle.projectNoOfStyle,}
                }}
                inputProps={{
                  style: {
                    minHeight: "1.187em"
                    //height:"auto"
                  }
                }}
                name="number_of_bidders"
              />
              {this.props.formErrors &&
                this.props.formErrors.number_of_bidders && (
                  <Typography style={{ ...webStyle.errorStyle }}>
                    {this.props.formErrors.number_of_bidders}
                  </Typography>
                )}
            </FormControl>
          </Grid>
        </Grid>
        <Dropzone onDrop={this.props.handleFileDrop} data-testid="dropzone">
          {({ getRootProps, getInputProps }) => (
            <section className="container">
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Box style={webStyle.dropZoneStyle}>
                  <img src={uploadIcon} style={webStyle.downloadIconStyle} />
                  <Typography style={webStyle.uploadFileTxt}>
                    {configJSON.uploadFileTxt}
                  </Typography>
                  <Typography style={webStyle.maxFileSizeTxt}>
                    {configJSON.maxFileSizeTxt}
                  </Typography>
                </Box>
              </div>
            </section>
          )}
        </Dropzone>
        <Box style={webStyle.filesContainerStyle}>
          {this.props.uploadedFiles?.filter((item)=>!item.deleted)?.map((file, index) => {
            return (
              <Box
                style={webStyle.fileDetailsBlockStyle}
                key={file.lastModified}
              >
                <img src={fileIcon} alt="file" style={webStyle.fileIconStyle} />
                <Typography style={webStyle.fileNameStyle}>
                  {file.name.trim().substring(0, 15)}
                  <span style={webStyle.fileSizeStyle}>
                  {
                    file.size < 1048576
                      ? `(${Math.trunc(file.size / 1024)}KB)`
                      : `(${Math.trunc(file.size / (1024 * 1024))}MB)`
                  }
                  </span>
                </Typography>
                <img
                  src={deleteIcon}
                  style={webStyle.deleteIconStyle}
                  alt="delete"
                  onClick={()=>this.props.deleteUploadedFile(index,file)}
                />
              </Box>
            );
          })}
        </Box>
        <Box style={webStyle.continueBtnBlockStyle}>
          <Button
            id="btnchange"
            style={webStyle.continueBtnStyle}
            onClick={this.props.onComponentChange.bind(this, "skills")}
          >
            {configJSON.continueBtnTxt}
          </Button>
        </Box>
      </Container>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  chips:{
    display: 'flex',
    flexWrap: 'wrap',
    gap:"10px",
  } as const,
  mainContainer: {
    padding: 20
  },
  formControlStyle: {
    margin: "15px 0px"
  },
  labelStyle: {
    color: "#8083A3",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 700
  },
  disabledLabel:{
    color: "red",
  },
  placeholderStyle: {
    color: "#8083A3",
    fontSize: "14px",
    fontFamily: "Inter"
    //fontWeight: 700,
  },
  textarea: {
    border: "none",
    color: "#8083A3",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 700
  },
  uploadFileTxt: {
    color: "#222222",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 700
  },
  maxFileSizeTxt: {
    color: "#8083A3",
    fontSize: "10px",
    fontFamily: "Inter",
    fontWeight: 500
  },
  dropZoneStyle: {
    borderRadius: "10px",
    border: "1.5px dashed #00A7A2",
    background:
      "linear-gradient(0deg, rgba(0, 167, 162, 0.04) 0%, rgba(0, 167, 162, 0.04) 100%), #FFF",
    padding: "20px",
    margin: "15px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column" as "column",
    rowGap: 5
  },
  downloadIconStyle: {
    padding: 10,
    borderRadius: "40%",
    background: "rgba(0, 167, 162, 0.06)",
    width: 36,
    height: 36
  },
  fileDetailsBlockStyle: {
    borderRadius: 10,
    border: "1px solid #E4E6E8",
    background: "#F9F9F9",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    padding: "10px 5px",
  } as const,
  fileNameStyle: {
    color: "#8083A3",
    fontSize: "12px",
    fontFamily: "Inter",
    fontWeight: 600,
    padding: "10px"
    //border:"1px solid black"
  },
  fileSizeStyle: {
    color: "#8083A3",
    fontSize: "10px",
    fontFamily: "Inter",
    fontWeight: 600
  },
  deleteIconStyle: {
    borderRadius: "50%",
    border: "1px solid rgba(228, 230, 232, 0.60)",
    background: "#FFF",
    padding: 10,
    cursor:"pointer"
  },
  fileIconStyle: {
    width: 26,
    height: 26
  },
  continueBtnStyle: {
    borderRadius: 23,
    backgroundColor: "#00A7A2",
    color: "#fff",
    textAlign: "center" as "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    textTransform: "capitalize" as "capitalize",
    padding: "5 35"
  },
  continueBtnBlockStyle: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 100
  },
  filesContainerStyle: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    gap:"10px",
    justifyContent:"center",
  } as const,
  errorStyle: {
    paddingTop: 10,
    color: "#eb020a",
    fontSize: "14px"
  },
  selectItemValuestyle: {
    color: "#8083A3",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 400,
    border:"none"
  },
  projectNoOfStyle: {
    color: "#8083A3",
    fontSize: "14px",
    fontFamily: "Inter",
    //fontWeight: 700,
    // margin: "-14px 0px"
    lineHeight: 1.5
  }
};
// Customizable Area End
