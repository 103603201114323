import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IProfile } from "../../rolesandpermissions/src/Roles.types";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  history?: any;
  location?: any;
  classes?: any;
  route?: any;
  id?: any;
}

export interface S {
  // Customizable Area Start
  currentUser: IProfile | null;
  availability:boolean;
  notificationModal: boolean;
  notificationAnchor: any;
  toggleflag:boolean;
  mobileOpen: boolean;
  profilePopup: HTMLElement | null;
  notificationPopup: HTMLElement | null;
  notificationcount:number
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class HeaderController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  currentPage:string="Freelancer";
  getNotificationCountCallId:any="";
  notificationId:any="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.LoginUserMessage),
      getName(MessageEnum.NotificationCount),
    ];

    this.state = {
      // Customizable Area Start
      currentUser: null,
      availability:true,
      notificationModal: false,
      notificationAnchor: null,
      toggleflag:false,
      mobileOpen: false,
      profilePopup: null,
      notificationPopup:null,
      notificationcount:0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (message.id === getName(MessageEnum.LoginUserMessage)) {
      const user = message.getData(getName(MessageEnum.LoginUserBodyMessage));
      this.setState({ currentUser: user },this.checkAuthorization);
    }
    if (message.id === getName(MessageEnum.NotificationCount)) {
      this.getNotificationCount()
    }
    if (apiRequestCallId === this.getNotificationCountCallId) {
      this.setState({ notificationcount: response?.unread_notification_count })
    }}
  async componentDidMount() {
    this.getNotificationCount()
    super.componentDidMount();
    const msg: Message = new Message(
      getName(MessageEnum.GetCurrentUserMessage)
    );
    let a = window.location.pathname
    if(window.location.pathname == a){
      this.setState({notificationModal:false})
    }
    this.send(msg);
    window.scrollTo({
      top: 0,
    });

    this.notificationId=setInterval(()=>{
        this.getNotificationCount()
    },120 * 1000)

  }

  async componentWillUnmount() {
    if(this.notificationId){
       clearInterval(this.notificationId)
    }
  }
  
  checkAuthorization=()=>{
    const {currentUser}=this.state;
    const ignorePath:string[]=["Chat","PaymentSuccess","QuoteProjectDetails"];
    const pathName=window.location.pathname.split("/")[1];
    if(ignorePath.includes(pathName)){
      return;
    }
    if(currentUser?.id){
      const isAuthorized=currentUser.currentState?.toLowerCase().includes(this.currentPage?.toLowerCase());
       if(!isAuthorized){
        const msg: Message = new Message(
          getName(MessageEnum.UnAuthorizeUserMessage)
        );
        this.send(msg);
       }
    }
  }

  getNotificationCount = async () => {

    const header = {
      "Content-Type": "application/json",
      token: await getStorageData("loginSuccessToken")
    };

    let IsSponsor = this?.currentPage?.toLowerCase()?.includes("sponsor") ? "sponsor" : "freelancer" 
 

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNotificationCountCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.notificationsCountAPIEndpoint}?role=${IsSponsor}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  closeNotificationModal = () => {
    this.setState({ notificationModal: false, notificationAnchor: null });
  };

  handletoggle = () =>{
    this.setState({toggleflag : !this.state.toggleflag})
  }
  handleNotificationPopup = (e: any) => {
    this.setState({
      notificationModal: !this.state.notificationModal,
      notificationAnchor: e?.currentTarget,
    });
  };
  handleProfilePopupOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      profilePopup: event.currentTarget,
    });
  };

  handleCloseProfilePopup = () => {
    this.setState({
      profilePopup: null,
    });
  };
  handleOpenNotificationPopup = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      notificationPopup: event.currentTarget,
    });
  };

  handleCloseNotificationPopup = () => {
    this.setState({
      notificationPopup: null,
    });
  };

  handleDrawerOpen = () => {
    this.setState({
      mobileOpen: true,
    });
  };

  handleavailability = () =>{
    this.setState((prev)=>{
      return {currentUser:prev.currentUser? {
        ...prev.currentUser,
        attributes:{
          ...prev.currentUser.attributes,availability:!prev.currentUser.attributes.availability
        }
      } : null}
    },()=>this.sendUserAvailibilityMessage())
  }
  handleDrawerClose = () => {
    this.setState({
      mobileOpen: false,
    });
  };

  sendLogoutMessage = () => {
    this.handleCloseProfilePopup()
    const msg: Message = new Message(getName(MessageEnum.UserLogoutMessage));
    this.send(msg);
  };
  sendSwitchAccountMessage = () => {
    const msg: Message = new Message(getName(MessageEnum.SwitchAccountMessage));
    this.send(msg);
  };
  sendUnAuthorizeMessage = () => {
    const msg: Message = new Message(getName(MessageEnum.UnAuthorizeUserMessage));
    this.send(msg);
  };

  sendUserAvailibilityMessage = () => {
    const user = this.state.currentUser?.attributes.availability  ? "true" : "false";
    const msg: Message = new Message(getName(MessageEnum.ChangeAvailibility));
    msg.addData(getName(MessageEnum.AvailibilityBodyMessage), user);
    this.send(msg);
  };
  navigateToHomePage=()=>{
     const pathName=window.location.pathname;
     const freelancerHomePath="FreelancerDashboard";
     const sponsorHomePath="SponsorDashboard";
     const homePages=[`/${freelancerHomePath}`,`/${sponsorHomePath}`];
     const currentPath=this.currentPage==="Freelancer"?freelancerHomePath:sponsorHomePath;
     if(homePages.includes(pathName)){
       window.location.reload();
     }else{
      this.props.navigation?.navigate(currentPath);
     }
  }
}
