import React,{useState} from "react";
import { Formik, Field, Form, FieldArray } from "formik";
import Select from "react-select";
import { corporateFreelancerValidationSchema } from "../../../blocks/social-media-account-registration/src/validationSchema";
import InputError from "../../../blocks/social-media-account-registration/src/InputError.web";
import CircularProgress from "@material-ui/core/CircularProgress";
import "../../../blocks/social-media-account-registration//assets/css/Accountinfo.css";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ICategoryDataModel,IIndustry} from '../../../blocks/social-media-account-registration/src/types';
import FormSaver from './FormSaver.web';
import CustomSelect from "../utill/CustomSelect.web";
import {MenuItem,Box} from '@material-ui/core';
const init = {
  company_name: "",
  companyReg_no: "",
  year_Regis: "",
  address1: "",
  address2: "",
  country: "",
  city: "",
  state: "",
  company_profile: "",
  company_website: "",
  award_certificate: "",
  postal_code: "",
  experience: "",
  skill_ids: [],
  hobby_ids: [],
  company_overview:"",
  number_of_employees:"",
  type_of_industry:"",
  tax_id:"",
  certifications:[""],
  experience_in_years:""
};
interface Props {
  initialValues?: any;
  submitHandler: any;
  cancelHandler: any;
  company_logo: any;
  handleChangeCompanyLogo: any;
  submitLoading: boolean;
  countryList:string[];
  countryLoading:boolean;
  stateList:string[];
  stateLoading:boolean;
  getStateList:any;
  getCityList:any;
  cityList:string[];
  getCityLoading:boolean;
  skills:ICategoryDataModel[];
  categories:ICategoryDataModel[];
  freelancingExperienceList:string[];
  industryList:IIndustry[];
}

const getInitialValue = () => {
  const corporateString = localStorage.getItem("corporateForm");
  let initialValue = init;
  try {
    if (corporateString) {
      const parsedValue = JSON.parse(corporateString);
      initialValue = parsedValue;
    }
    return initialValue;
  } catch (err) {
    console.log(err);
    return initialValue;
  }
};

const CorporateForm: React.FC<Props> = ({
  initialValues = getInitialValue(),
  submitHandler,
  cancelHandler,
  company_logo,
  handleChangeCompanyLogo,
  submitLoading,
  countryList,
  countryLoading,
  stateList,
  stateLoading,
  getStateList,
  skills,
  categories,
  getCityList,
  getCityLoading,
  cityList,
  freelancingExperienceList,
  industryList
}) => {
  const inputRef = React.useRef<any>(null);
  const [updateInfo,setUpdateInfo]=useState(true);

  const handleGoBack=()=>{
    setUpdateInfo(false)
    cancelHandler();
  }
  const handleDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const droppedFile = event.dataTransfer.files[0];
    handleChangeCompanyLogo(droppedFile);
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragEnter = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragLeave = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };
  const handleClick = (event: any) => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={submitHandler}
      validationSchema={corporateFreelancerValidationSchema}
    >
        {(props)=>(
        <FormSaver {...props} saveFieldName={"corporateForm"} saveInfo={updateInfo}>
          {({ errors, values, setFieldValue }:any) => (
            <Form translate={undefined}>
              <div className="upload-block">
                <div
                  id="dropzone"
                  className="drop-block"
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  onDragEnter={handleDragEnter}
                  onDragLeave={handleDragLeave}
                  onClick={handleClick}
                  test-id="dropzone-button"
                >
                  <p style={company_logo ? { height: "100%",width:"100%" } : {}}>
                    {company_logo ? (
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: "50%",
                        }}
                        src={URL.createObjectURL(company_logo)}
                      />
                    ) : (
                      <>
                        Attach or Drop Company logo here
                        <br />
                        <small>(5 MB Max)</small>
                      </>
                    )}
                  </p>
                </div>
                <div className="upload-btn-wrapper">
                  
                  <label style={{marginTop:"10px",padding:"8px 30px"}}  className="button btn-orange" htmlFor="corporate_profile">
                    Upload Company Logo
                  </label>
                  <input
                    id="corporate_profile"
                    type="file"
                    name="profilephoto"
                    onChange={(e) =>
                      handleChangeCompanyLogo(
                        e.target.files ? e.target.files[0] : undefined
                      )
                    }
                    style={{ display: "none" }}
                    ref={inputRef}
                    accept="image/*"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-50">
                  <div className="form-group">
                    <label htmlFor="company_name">Company Name*</label>
                    <Field
                      type="text"
                      id="company_name"
                      tabIndex={1}
                      name="company_name"
                      placeholder="Enter your company name"
                    />
                    <InputError errors={errors} name="company_name" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="companyReg_no">Company Registration No</label>
                    <Field
                      type="text"
                      id="companyReg_no"
                      tabIndex={3}
                      name="companyReg_no"
                      placeholder="Enter company registration no."
                    />
                    <InputError errors={errors} name="companyReg_no" />
                  </div>
                  <CustomSelect
                      value={values?.country}
                      onChange={(e:any)=>{
                        setFieldValue(`country`,e.target?.value);
                        setFieldValue(`state`,"");
                        setFieldValue(`city`,"");
                        getStateList(e.target?.value)
                      }}
                      label="Country*"
                      renderItem={(item,props)=>(
                                <MenuItem
                                    key={item}
                                    value={item}
                                    {...props}
                                  >
                                     {item}
                                 </MenuItem>
                       )}
                      name={`country`}
                      id={`country`}
                      data={countryList}
                      error={errors?.country || ""}
                      placeholder="Select your country"
                      labelStyle={{
                           marginBottom:"14px"
                      }}
                      rootStyle={{
                          paddingRight:"20px"
                      }}
                      tabIndex={7}
                  />
                        {
                                !getCityLoading && cityList?.length===0 && values?.country && values?.state?(
                                  <div className="form-group">
                                    <label htmlFor="city">City*</label>
                                    <Field
                                      test-id="city"
                                      id="city"
                                      name="city"
                                      tabIndex={9}
                                    />
                                    <InputError errors={errors} name="city" />
                                  </div>
                                  
                                ):(
                                  <>
                                    <CustomSelect
                                        tabIndex={9}
                                        value={values?.city}
                                        onChange={(e:any)=>{
                                          setFieldValue(`city`,e.target?.value);
                                        }}
                                        label="City*"
                                        renderItem={(item,props)=>(
                                                  <MenuItem
                                                      key={item}
                                                      value={item}
                                                      {...props}
                                                    >
                                                      {item}
                                                  </MenuItem>
                                        )}
                                        name={`city`}
                                        id={`city`}
                                        data={cityList}
                                        error={errors?.city || ""}
                                        placeholder="Select your city"
                                        disabled={!values?.state?.trim()} 
                                        labelStyle={{
                                            marginBottom:"7px"
                                        }}
                                    />
                                  </>
                                )
                        }
                  
                  <CustomSelect
                      value={values?.type_of_industry}
                      onChange={(e:any)=>{
                        setFieldValue(`type_of_industry`,e.target?.value);
                      }}
                      label="Company Industry"
                      renderItem={(item,props)=>(
                                <MenuItem
                                    key={item?.attributes?.industry_name}
                                    value={item?.attributes?.industry_name}
                                    {...props}
                                  >
                                     {item?.attributes?.industry_name}
                                 </MenuItem>
                       )}
                      name={`type_of_industry`}
                      id={`type_of_industry`}
                      data={industryList}
                      error={errors?.type_of_industry || ""}
                      placeholder="Select your industry"
                      labelStyle={{
                           marginBottom:"8px"
                      }}
                      tabIndex={11}
                  />  
                  <div className="form-group">
                  <label htmlFor="tax_id">Company Overview</label>
                    <Field
                       tabIndex={13}
                      type="text"
                      id="company_overview"
                      name="company_overview"
                      placeholder="Enter company overview"
                    />
                    <InputError
                        errors={errors}
                        name="company_overview"
                      />
                  </div>
                </div>

                <div className="col-50">
                  <div className="form-group">
                    <label htmlFor="year_Regis">Year of Registration*</label>
                    <Field
                      type="number"
                      id="year_Regis"
                      tabIndex={2}
                      name="year_Regis"
                      placeholder="Enter Year of Registration"
                    />
                    <InputError errors={errors} name="year_Regis" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="company_website">Company Website</label>
                    <Field
                      type="text"
                      id="company_website"
                      tabIndex={4}
                      name="company_website"
                      placeholder="Enter company website"
                    />
                    <InputError errors={errors} name="company_website" />
                  </div>
                 { !stateLoading && stateList.length> 0 ?  <CustomSelect
                      value={values?.state}
                      onChange={(e:any)=>{
                        setFieldValue(`state`,e.target?.value);
                        setFieldValue(`city`,"");
                        getCityList(e.target?.value)
                      }}
                      label="State*"
                      renderItem={(item,props)=>(
                                <MenuItem
                                    key={item}
                                    value={item}
                                    {...props}
                                  >
                                     {item}
                                 </MenuItem>
                       )}
                      name={`state`}
                      id={`state`}
                      data={stateList}
                      error={errors?.state || ""}
                      placeholder="Select your state"
                      labelStyle={{
                           marginBottom:"14px"
                      }}
                      disabled={!values?.country?.trim()}
                      tabIndex={8}
                  /> : <div className="form-group">
                  <label htmlFor="state">State*</label>
                  <Field
                    test-id="state"
                    id="state"
                    disabled={!values?.country?.trim()}
                    name="state"
                    placeholder="Enter your State"
                    tabIndex={10}
                    onChange={(e:any)=>{
                      setFieldValue("state",e.target.value);
                      setFieldValue("city","");
                      }}
                    />
                     <InputError errors={errors} name="state" />
                     
                </div>}
                  <div className="form-group">
                    <label htmlFor="postal_code">Postal Code*</label>
                    <Field
                      type="number"
                      id="postal_code"
                      tabIndex={10}
                      name="postal_code"
                      placeholder="Enter postal code"
                    />
                    <InputError errors={errors} name="postal_code" />
                  </div>
                  <div className="form-group">
                  <label htmlFor="number_of_employees">
                    Number of Employees
                  </label>
                  <Field
                     tabIndex={12}
                    type="number"
                    id="number_of_employees"
                    name="number_of_employees"
                    placeholder="Enter number of employees"
                  />
                  <InputError
                    errors={errors}
                    name="number_of_employees"
                  />
                  </div>
                  <div className="form-group">
                    <label htmlFor="tax_id">VAT ID</label>
                    <Field
                       tabIndex={14}
                      type="text"
                      id="tax_id"
                      name="tax_id"
                      placeholder="Enter your VAT ID"
                    />
                    <InputError
                        errors={errors}
                        name="tax_id"
                      />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="step-2">
                  <div className="block-title">
                    <h6>Certifications</h6>
                  </div>
                  <div>
                    <FieldArray name="certifications" test-id="educationDetails">
                      {({ push,remove }) => {
                        return(
                          <div>
                            {values?.certifications?.map(
                              (certification:string, index:number) => (
                                <div
                                  className="block-content"
                                  key={index}
                                >
                                  <div className="block-inner">
                                    <p>Certifications {index + 1}</p>
                                    {values?.certifications?.length > 1 && (
                                        <span
                                          test-id="removeExperienceDetailsId"
                                          className="link"
                                          onClick={() => remove(index)}
                                        >
                                          Remove
                                        </span>
                                      )}
                                      
                                    {index ===
                                      values?.certifications?.length -
                                      1 && (
                                        <span test-id="addEducationId"
                                          className="link"
                                          onClick={() =>
                                            push("")
                                          }
                                        >
                                          + Add another certification
                                        </span>
                                      )}
                                    
                                  </div>
                                  <div className="form-group">
                                      <label
                                        htmlFor={`certifications.${index}`}
                                      >
                                        Certification
                                      </label>
                                      <Field
                                        tabIndex={15}
                                        name={`certifications.${index}`}
                                        id={`certifications.${index}`}
                                      />
                                    </div>
                                </div>
                              )
                            )}
                          </div>
                        )
                      }}
                    </FieldArray>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-group">
                  <label htmlFor="company_profile">Company Profile</label>
                  <Field
                    type="text"
                    id="company_profile"
                    tabIndex={16}
                    name="company_profile"
                    placeholder="Please enter your company profile"
                  />
                  <InputError errors={errors} name="company_profile" />
                </div>
              </div>
              <div className="row">
                <div className="form-group">
                  <label htmlFor="award_certificate">Award Won by Company</label>
                  <Field
                    type="text"
                    id="award_certificate"
                    tabIndex={17}
                    name="award_certificate"
                    placeholder="Please enter your company profile"
                  />
                  <InputError errors={errors} name="award_certificate" />
                </div>
              </div>
              <div className="row">
                <div className="form-group form-linebreak multiselect">
                  <label htmlFor="hobby_ids">Categories Interested*</label>
                  <Select
                    className="multiselect-width"
                    id="hobby_ids"
                    tabIndex={'19'}
                    isClearable={false}
                    name="hobby_ids"
                    placeholder="Search and select your Categories"
                    options={categories}
                    isMulti
                    value={values.hobby_ids}
                    onChange={(selectedValues) =>
                      setFieldValue("hobby_ids", selectedValues)
                    }
                  />
                  <InputError errors={errors} name="hobby_ids" />
                </div>
              </div>
              <div className="row">
                  <div className="form-group form-linebreak multiselect">
                       <label htmlFor="skill_ids">Skills*</label>
                        <Select
                          id="skill_ids"
                          test-id="skill_ids"
                          isClearable={false}
                          className="multiselect-width"
                          name="skill_ids"
                          placeholder="Search and select your skills"
                          required
                          options={skills}
                          isMulti
                          value={values.skill_ids}
                          onChange={(selectedValue)=>setFieldValue("skill_ids",selectedValue?selectedValue:[])}
                        />
                        <InputError errors={errors} name="skill_ids"/>
                  </div>
                </div>
              <div className="row">
                <div className="col-50">
                  <div className="form-group">
                          <label htmlFor="experience_in_years">Freelancing Experience</label>
                          <input
                              type="number"
                              value={values?.experience_in_years}
                              onChange={(e:any)=>setFieldValue("experience_in_years",e.target.value)}
                              data-testid="experience"
                              name="experience_in_years"
                              tabIndex={20}
                              placeholder="Select Years"
                              min="0"
                            />
                            <InputError
                            errors={errors}
                            name="experience_in_years"
                          />
                        </div>
                </div>
              </div>
              <Box className="button-block" display="flex" flexWrap="wrap">
                <button
                  type="button"
                  className="button btn"
                  tabIndex={21}
                  onClick={handleGoBack}
                >
                  Cancel
                </button>
                <button
                  disabled={submitLoading}
                  type="submit"
                  tabIndex={22}
                  className="button btn-orange"
                  test-id="submitButtonId"
                >
                  {submitLoading ? <CircularProgress size={16} /> : "Complete"}
                </button>
              </Box>
            </Form>
          )}
        </FormSaver>
        )}
    </Formik>
  );
};

export default CorporateForm;
