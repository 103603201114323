Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "scheduling";
exports.labelBodyText = "scheduling Body";

exports.btnExampleTitle = "CLICK ME";
exports.getApiMethodType = "GET";
exports.applicationJsonApiContentType = "application/json";
exports.serviceProviderAPiEndPoint = "scheduling/service_provider_schedulings/get_sp_details";
exports.backBtnTxt = 'Back'
exports.closeProjectBtnTxt = 'Close Project'
exports.putonHoldBtnTxt = 'Put on Hold'
exports.labelVerify = 'Verified'
exports.submittedFilesLabelTxt ='Work submitted for review'
exports.uploadFilesSubmitted = 'Amin Mohamed M. submitted 2 files .9 Minutes Ago'
exports.paymentLabelTxt = 'Payment Details'
exports.paymentStatusLabel= 'Status : '
exports.AmountinEscrowLabelTxt ='Amount in Escrow :'
exports.originalAmountLabeltxt ='Original amount :'
exports.requestedAmountLabeltxt='Amount requested :'
exports.disputedAmount='Disputed Amount'
exports.approveBtnTxt='Approve & Pay'
exports.requestRevisionBtnTxt='Request Revision'
exports.revisonDeadlineTxt = 'You have 14 days from the submission of work to request revision.'
exports.learnMoreLabeltxt='Learn more'
exports.pendingabeltxt='Pending'
exports.addNewMilestoneBtntxt='Add New Milestone'
exports.revisionRequestLabelTxt = 'Revision Request'
exports.requestDetails='Request Details'
exports.requestDetailsDescription = 'Tell the freelancer what needs to be done in order to completed the milestone'
exports.uploadFileTxt ='Upload File'
exports.maxFileSizeTxt = 'Max file size: 15MB'
exports.cancelBtnTxt = 'Cancel'
exports.sendBtnTxt = 'Send'
exports.milestoneTitleLabelTxt = 'Milestone Title'
exports.milestoneDescriptionLabelTxt = 'Milestone Description'
exports.milestoneDuedateLabelTxt = 'Required Days'
exports.milestoneDepositAmountLabelTxt = 'Deposit Amount'
exports.descriptionPlaceholder = 'Enter milestone Description'
exports.titlePlaceholder = 'Enter milestone Title'
exports.despostiAMountPlaceholder = 'Enter Deposit Amount'
exports.monthName = 'September, 2022'
exports.sposorName = 'John Doe'
exports.postaProject ='Post a Project'
exports.messageSentLabel = 'Message Sent'
exports.releaseFromEscrow = 'Release from escrow'
exports.additionalBonus='Additional Bonus'
exports.additionalBonusPlaceholder='Enter Bonus Amount'
exports.escrowRelesedAmountPlaceholder='Enter Amount'
exports.optionalLabel = 'Optional'
exports.payLabel = 'Pay'
exports.httpGetMethod = 'GET'
exports.httpPutMethod = 'PUT'
exports.httpPostMethod = 'POST'
exports.httpDeleteMethod = 'Delete'
exports.getAllMilestonesEndpoint = 'bx_block_scheduling/project_milestones?project_id='
exports.createNewMilestoneEndpoint = 'bx_block_scheduling/project_milestones'
exports.revisionMilestonesEndpoint = '/bx_block_scheduling/project_milestones/1/request_revision'
exports.milestoneNumber = '1'
exports.requiredDaysPlaceholder = 'Enter Requred Days to Complete Milestone'
exports.getTemplateMilestones = 'bx_block_scheduling/get_milestones_through_category?category_id'
exports.daysLabelText = 'Days'
exports.editBtnTxt = 'Edit'
exports.deleteBtnTxt = 'Delete'
exports.approvePaymentAPIEndpoint = 'bx_block_scheduling/approve_milestones'
exports.requestRevisionAPIEndpoint = 'bx_block_scheduling/project_milestones/?milestone_id='
exports.projectDetailsAPIEndpoint = 'bx_block_profile/projects/'
exports.schedulingId = "schedulingId"
exports.milestonesDetailsApiCallUrl = "bx_block_scheduling/project_details?check_rating_from=sponsor&project_id="
exports.releasedAmountLabeltxt = "Amount released"
exports.errorNoDetailsForMilestones="No details for milestones found."
exports.noMilestoneRecordFound = "No Record for Milestones found"
exports.downloadInvoice = "Download Invoice"
exports.deadline = "Deadline"
exports.radiosLabel = "Please select a Project for Hire a Lancer"
exports.notification = "Ending the project will cancel the remaining milestones as well"
exports.modalHeading = "End Project"
exports.firstLabel = "Lancer is not responding"
exports.secondLabel = "Lancer is not responding"
exports.thirdLabel = "Other"
exports.writeHere = "write here"
exports.changeProjectStatusEndpoint = "/bx_block_profile/projects/change_project_status"
exports.onholdProjectEndpoint = "/bx_block_profile/projects/onhold_project_flow"
exports.resumeProjectEndpoint = "/bx_block_profile/projects/resume_project_flow"
exports.httpPatchMethod = "PATCH"
exports.submitBtnTxt = "Submit"
exports.rateLancer = "Rate Freelancer"
exports.contentHeading = "How would you rate this freelancer ?"
exports.feedbackLabel = "Any Feedback"
exports.poor = "Poor"
exports.average = "Average"
exports.good = "Good"
exports.veryGood = "Very Good"
exports.excellent = "Excellent"
exports.shareFeedback = "Share Your Feedback"
exports.projectCompleted = "Project Finished"
exports.rateLancers = "Rate Freelancer"
exports.rateLancerAPIEndpoint = "/bx_block_reviews/reviews"

exports.createConversationApiUrl="bx_block_chat/chats"
exports.putProjectOnHold = "Put Project on Hold"
exports.dollar='$';
exports.payButtonText='Pay';
exports.getSingleProjectApiUrl='bx_block_profile/projects';
exports.getMilestoneCostApiUrl='bx_block_scheduling/get_project_costs_via_milestone_id';
exports.payMilestoneApiUrl='bx_block_ccavenueintegration2/pay_milestone';
exports.getDisputeCategoriesAPIEndpoint = "/bx_block_profile/projects/all_dispute_categories"
exports.raiseDisputeAPIEndpoint = "bx_block_profile/projects/raise_dispute"
exports.projectClosureRequested = "Project Closure Requested"
exports.projectClosureApproved = "Project Closure Approved"
exports.projectClosureRejected = "Project Closure Rejected"
exports.payAndStartMilestone = "Pay & start Milestone"
exports.amount="Amount *"
exports.category="Category *"
exports.periodStatus="start"
exports.labelAcceptanceEnd= "Validation period end date:"
exports.dueDateFormat ="MMM DD, YYYY"
exports.labelCoolingEnd= "Cooling period end date:"
exports.refund = "Refund"
exports.withdrawRefund = "Withdraw Refund"
exports.refundWithdrawn="Refund Withdrawn"
exports.refundRequestAPIEndpoint = "bx_block_refund_management/refund_managements/create_refund?id"
exports.reasonForRefund = "reason_for_refund"
exports.reasonHeading = "Please select a reason for ending the project"
exports.onholdReasonHeading = "Please select a reason to put project on hold"
exports.raiseDisputeForMilestoneAPIENdpoint = "bx_block_scheduling/raise_disputes/raise_dispute?role=sponsor"
exports.titleBeforeArbitration = "Dispute request has been escalated for Arbitration stage.You have to provide the evidence and pay for this process whoever wins the dispute will receive the money."
exports.submitEvidenceAndPay = "Submit Evidence & Pay"
exports.submitEvidenceAPIEndpoint = "bx_block_scheduling/raise_disputes/milestone_evidance?role=sponsor"
exports.titleAfterArbitration = "Arbitration process has started for the dispute. We will keep you updating with latest status."
exports.requestForRefund = "Request for Refund"
exports.refundOptionsLabel = "Please select the reason for refund"
exports.menuDefaultTxt=""
exports.categoryPlaceHolderTxt="Select category"
exports.errorTxtMaxUpload="File size exceeds 15 MB.";
exports.pathProjects="Projects"
exports.disputeProjectType="disputeproject";
exports.categoryValidationTxt='Category is required';
exports.amountValidationTxt='Amount is required';
exports.descriptionValidationTxt='Description is required';
exports.disputeDetailsHeading="Dispute Details"
exports.disputeDiscriptionLabel="Description*"
exports.arbitrationAmountApiCallUrl='bx_block_scheduling/raise_disputes/arbitration_amount'
exports.payLabel="Pay $"
exports.submitEvidenceTxt="Submit Evidence"
exports.attachmentsTxt="Attachments"
exports.selectFileTxt="Please select a file."
exports.ratingSuccessMsg="Rating submitted."
exports.ratingErrorMsg="Please give the ratings."
exports.feedbackPlaceholder="Enter your feedback"

exports.resumeDisputeProjectEndpoint = "bx_block_scheduling/raise_disputes/resume_project_for_milestone"
exports.submitArbitrationEvidenceAPIEndpoint = "bx_block_ccavenueintegration2/payment_for_arbitration_processes"
exports.paymentErrorMsg ="Something went wrong, Please try again."
exports.submittedTxt = 'submitted'
exports.currencyConvertionText1="Converted from"
exports.currencyConvertionText2="into"
exports.currencyConvertionText3="based on today's conversion rate."
// Customizable Area End