import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";
import { IProfile } from "../../rolesandpermissions/src/Roles.types";
import { Message } from "../../../framework/src/Message";
import CreateRestApiMessage from "../../../components/src/CreateRestApiMessage.web";
import { IBidData } from "../../dashboard/src/freelancer-projects/bid.types";
import {toast} from 'react-toastify'
import { IProjectData } from "../../dashboard/src/types";
// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    currentUser:IProfile|null;
    getBidLoading:boolean;
    currentBids:IBidData|null;
    project:IProjectData|null;
    // Customizable Area End
}
interface SS {
    id: any;
    isUserLoggedIn: boolean;
}

export default class FreelancerBidNegotionController extends BlockComponent<Props, S, SS> {
    getBidInfoApiUrl:string="";
    getProjectInfoApiCallId:string="";
    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [];
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.AlertMessage),
            getName(MessageEnum.LoginUserMessage),
        ];

        this.state = {
            currentUser:null,
            getBidLoading:false,
            currentBids:null,
            project:null
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        if (message.id === getName(MessageEnum.LoginUserMessage)) {
          const loggedinUser = message.getData(getName(MessageEnum.LoginUserBodyMessage));
          this.setState({ currentUser: loggedinUser });
        }
    
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
          const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
    
          const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );

          if (apiRequestCallId === this.getBidInfoApiUrl) {
            this.handleBidPayload(responseJson);
          }else if (apiRequestCallId === this.getProjectInfoApiCallId) {
            this.handleGetProjectPayload(responseJson);
          }
        }
      }
    
  handleGetProjectPayload=(responseJson:any)=>{
    if(responseJson?.data?.id){
      this.setState({
        getBidLoading:false,
        project:responseJson?.data
      })
    }else{
       this.setState({
        getBidLoading:false,
       },()=>{
        toast.error("Project not found!")
       })
    }
  }  
      handleBidPayload=(responseJson:any)=>{
        if(responseJson?.bid?.data?.id){
            this.setState({
                currentBids:responseJson?.bid?.data,
            },()=>{
                this.getProjectInfo(this.state.currentBids?.attributes?.project_id?.toString() || "")
            })
        }else{
            toast.error("Quote Not Found!")
            this.setState({getBidLoading:false})
        }
      }
      async componentDidMount() {
        this.sendLoginUserMessage();
        this.getBidInfo();
      }
      sendLoginUserMessage=()=>{
        const msg: Message = new Message(
          getName(MessageEnum.GetCurrentUserMessage)
        );
        this.send(msg);
      }
      getBidInfo = () => {
        this.setState({
          getBidLoading: true,
        });
        const token = localStorage.getItem("loginSuccessToken");
        const header = {
          token: token,
          "Content-Type": "application/json",
        };
        const apiRequestMessage = CreateRestApiMessage({
          header,
          apiUrl: `${configJSON.getSingleBidApiUrl}/${this.getBidId()}?calculate=true`,
          body: null,
          method: "GET",
        });
    
        this.getBidInfoApiUrl = apiRequestMessage.messageId;
        runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
      };
      
    goBack=()=>{
      this.props.navigation?.goBack();
    }
    
  getProjectInfo=(projectId:string)=>{
    const token = localStorage.getItem("loginSuccessToken");
    const header = {
      token: token,
      "Content-Type": "application/json",
    };
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.getSingleProjectApiUrl}/${projectId}`,
      body: null,
      method: "GET",
    });

    this.getProjectInfoApiCallId = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  }
    getBidId = () => {
        return this.props.navigation?.getParam("bidId");
    };

}
