import * as Yup from "yup";
import moment from "moment";
import { parsePhoneNumber, CountryCode } from 'libphonenumber-js'
const calculateAge = (dob:string) => {
  const now = moment();
  const birthDate = moment(dob);
  const years = now.diff(birthDate, "years");
  return years>=18;
};
const compareDate=(stDate:string,eDate:string)=>{
  const startDate = new Date(stDate);
  const endDate = new Date(eDate);
  return endDate >= startDate;
}

export const fieldValidator = (values:any[], errorMessage: string) => {
  const fields = values?.slice(0, values?.length - 2);
  let isOtherFieldEntered = fields?.some(
    (field: string | undefined) => field && field !== ""
  );
  if (isOtherFieldEntered) {
    return Yup.string().required(errorMessage);
  } else {
    return Yup.string().notRequired();
  }
};
export const isValidPhoneNumber = (mobile_number: string, id: CountryCode): boolean => {
  try {
    const parsedPhoneNumber = parsePhoneNumber(mobile_number, id);
    return !!parsedPhoneNumber && parsedPhoneNumber.isPossible();
  } catch (error) {
    console.log(error,"error")
    return false
  }
};
export const basicInfoSchema = Yup.object({
  first_name: Yup.string().required("Please enter first name"),
  last_name: Yup.string().required("Please enter last name"),
  gender: Yup.string().required("Please select gender"),
  mobile_number: Yup.string()
  .required("Please enter mobile number")
  .test('mobile_number', 'Invalid Phone number', function(value) {
    if (!value || value.length === 0) {
      return this.createError({ message: 'Please enter your mobile number' });
    }
    let number = value.split("");
    number.splice(0, this.parent.dialCode.length);
    if (!number || number.length === 0) {
      return this.createError({ message: 'Please enter your mobile number' });
    }
    return isValidPhoneNumber(number.join(""), this.parent.countryCode);
  }),

  address: Yup.string().required("Please enter address line 1")
                      .min(10,"Address line 1 should have minimum 10 and maximum 150 characters")
                      .max(150,"Address line 1 should have minimum 10 and maximum 150 characters"),
  address_line_2: Yup.string().required("Please enter address line 2") 
                      .min(10,"Address line 2 should have minimum 10 and maximum 150 characters")
                      .max(150,"Address line 2 should have minimum 10 and maximum 150 characters"),
  country: Yup.string().required("Please select country"),
  countryCode:Yup.string(),
  city: Yup.string().required("Please select city"),
  state: Yup.string().required("Please select state"),
  postal_code: Yup.string().required("Please enter postal code").min(3,"Postal code should have minimum 3 digits."),
  date_of_birth: Yup.string().required("Please select birthdate").test("birthday","DOB should not be later than today",function(val){
    return new Date() >= new Date(val as string)
  }).test("birthday", "Age must be over 18 years", function(val) {
    return calculateAge(val as string);
}),
  time_zone: Yup.string().required("Please enter timezone"),
  currency: Yup.string().required("Please select your currency"),
});

export const sponsorvalidationSchema = Yup.object({
  hobby_ids: Yup.array().min(1, "Please select atleast one category"),
  company_name: Yup.string().notRequired(),
  website: Yup.string().notRequired(),
  industry: Yup.string().notRequired(),
  number_of_employee: Yup.string().notRequired(),
  mobile_number: Yup.string().required("Please enter mobile number"),
  vat_id: Yup.string().required("Please enter vat id"),
  address1: Yup.string().required("Please enter your address"),
});

const creerExperienceSchema=Yup.object().shape(
  {
    
    employerName: Yup.string().when(
      ["jobTitle", "country", "city", "jobDescription", "state"],
      (...values: any) => fieldValidator(values, "Please enter employer name")
    ),
    jobTitle: Yup.string().when(
      ["employerName", "country", "city", "jobDescription", "state"],
      (...values: any) => fieldValidator(values, "Please enter job title")
    ),
    country: Yup.string().when(
      ["jobTitle", "employerName", "city", "jobDescription", "state"],
      (...values: any) => fieldValidator(values, "Please select country")
    ),
    city: Yup.string().when(
      ["jobTitle", "employerName", "country", "jobDescription", "state"],
      (...values: any) => fieldValidator(values, "Please select city")
    ),
    state: Yup.string().when(
      ["jobTitle", "employerName", "country", "city", "jobDescription"],
      (...values: any) => fieldValidator(values, "Please select state")
    ),
    jobDescription: Yup.string().when(
      ["jobTitle", "employerName", "country", "city", "state"],
      (...values: any) =>
        fieldValidator(values, "Please enter job description")
    ),
  },
  [
    ["jobDescription", "jobTitle"],
    ["city", "jobTitle"],
    ["country", "jobTitle"],
    ["employerName", "jobTitle"],
    ["state", "jobTitle"],

    ["city", "jobDescription"],
    ["jobTitle", "jobDescription"],
    ["country", "jobDescription"],
    ["employerName", "jobDescription"],
    ["state", "jobDescription"],

    ["jobDescription", "city"],
    ["jobTitle", "city"],
    ["country", "city"],
    ["employerName", "city"],
    ["state", "city"],

    ["jobDescription", "country"],
    ["employerName", "country"],
    ["city", "country"],
    ["jobTitle", "country"],
    ["state", "country"],

    ["jobDescription", "state"],
    ["jobTitle", "state"],
    ["city", "state"],
    ["employerName", "state"],
    ["country", "state"],
  ]
)

export const freelancerIndiviualValidationSchema = Yup.object({
  skill_ids: Yup.array().min(1, "Please select atleast one skill"),
  hobby_ids: Yup.array().min(1, "Please select atleast one category"),
  certification: Yup.string().notRequired(),
  experience_in_years: Yup.string()
  .test('experience_in_years', 'Experience must be less than or equal to 60 years.', function(value) {
    if (Number(value) <= 60) {
      return true
    }
    return false
  }),

  educational_qualifications: Yup.array(
    Yup.object({
      education: Yup.string().required("Please select education"),
      college: Yup.string().required("Please select university/college"),
      field: Yup.string().required("Please select field of study"),
    })
  ),
  career_experiences: Yup.array(
    creerExperienceSchema
  ),
});

export const corporateFreelancerValidationSchema = Yup.object({
  company_name: Yup.string().required("Please enter company name"),
  year_Regis: Yup.string().required("Please enter year of registration"),
  companyReg_no: Yup.string().notRequired(),
  country: Yup.string().required("Please select country"),
  city: Yup.string().required("Please select city"),
  state: Yup.string().required("Please select state"),
  postal_code: Yup.string().required("Please enter postal code"),
  company_profile: Yup.string().notRequired(),
  company_website: Yup.string().notRequired(),
  award_certificate: Yup.string().notRequired(),
  experience_in_years: Yup.string()
  .test('experience_in_years', 'Experience must be less than or equal to 60 years.', function(value) {
    if (Number(value) <= 60) {
      return true
    }
    return false
  }),
  skill_ids: Yup.array()
    .min(1, "Please select atleast one skill")
    .nullable(),
  hobby_ids: Yup.array()
    .min(1, "Please select atleast one category")
    .nullable(),
  company_overview: Yup.string()
    .required("Please enter company overview"),  
  number_of_employees: Yup.string()
    .required("Please enter number of employees"),
  type_of_industry: Yup.string()
    .required("Please select industry"),
  tax_id: Yup.string()
    .required("Please enter vat id"),
  certifications: Yup.array()
    .nullable(),
    
    
});

export const studentFreelancerValidationSchema = Yup.object({
  birthDate: Yup.date().notRequired(),
  skill_ids: Yup.array().min(1, "Please select atleast one skill"),
  hobby_ids: Yup.array().min(1, "Please select atleast one category"),
  certification: Yup.string().required("Please enter certifications"),
  experience_in_years: Yup.string()
  .test('experience_in_years', 'Experience must be less than or equal to 60 years.', function(value) {
    if (Number(value) <= 60) {
      return true
    }
    return false
  }),
  educational_qualifications: Yup.array(
    Yup.object({
      birthDate: Yup.date().notRequired(),
      education: Yup.string().required("Please select education"),
      college: Yup.string().required("Please select university/college"),
      field: Yup.string().required("Please select field of study"),
      start_year: Yup.date().required("Please enter start date")
                             .min(Yup.ref('birthDate'),"Start date should be greater than birthdate")
                             .max(Yup.ref('end_year'), 'Start date should be lower than end date'),
      end_year: Yup.date().required("Please enter end date").min(Yup.ref('start_year'), 'End date should be greater than start date'),
    })
  ),
  college_id: Yup.string().required("Please enter college id"),
  career_experiences: Yup.array(
    creerExperienceSchema
  ),
});