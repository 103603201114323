// Customizable Area Start
import React from "react";
import FreelancerCompletedProjectController, {
  Props,
  configJSON,
} from "./FreelancerBrowseProjectController.web";
import { Typography, Box, Paper } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CircularProgress from "@material-ui/core/CircularProgress";
import ProjectFilter from "./ProjectFilter.web";
import ProjectCard2 from "../../../../components/src/ProjectCard2.web";
import { webStyle } from "./FreelancerBrowseProject.web";
class FreelancerCompletedProject extends FreelancerCompletedProjectController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      projectList,
      totalProjects,
      projectLoading: loadingProject,
    } = this.state;
    const enableShowMore = totalProjects > projectList?.length;
    return (
      <Box>
        <ProjectFilter
          onSearch={this.searchHandler}
          onFilter={this.filterHandler}
          onCancelFilter={this.cancelFilterHandler}
        />
        <br />


        {!loadingProject && projectList?.length === 0 && (
          <Paper className="border-block p-0 projectloading-main" >
            <Box className='projectloading'>
              <Typography variant='h2'>{configJSON.loadingMsg}</Typography>
            </Box>
          </Paper>
        )}

        {projectList?.map((card: any, index: number) => (
          <ProjectCard2 key={card?.id} card={card} navigation={this.props.navigation} />
        ))}
        <Box style={webStyle.loadMoreBtn}>
          {loadingProject && (
            <Box>
              <CircularProgress />
            </Box>
          )}

          {enableShowMore && !loadingProject && (
            <button
              data-testid="loadMore"
              style={webStyle.showMoreBtn}
              onClick={this.handleProjectLoadMore}
              hidden={loadingProject}
            >
              {configJSON.showMoreTxt}
              <ArrowForwardIosIcon style={webStyle.forwardIcon} />
            </button>
          )}
        </Box>
      </Box>
    );
  }
}

export default FreelancerCompletedProject;

// Customizable Area End
