import React from "react";

import {
  // Customizable Area Start
  Box,
  Button,
  MenuItem,
  InputLabel,
  Select,
  Grid,
  Typography,
  TextField
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
const durationItems = [
  {
    id: 4,
    label: "Hour(s)",
    value: "hours"
  },
  {
    id: 3,
    label: "Day(s)",
    value: "days"
  }, {
    id: 1,
    label: "Month(s)",
    value: "months"
  },
  {
    id: 2,
    label: "Year(s)",
    value: "years"
  }
];

interface IProps {
  onComponentChange: (view: string) => void;
  onComponentBackChange: (view: string) => void;
  projectSize: string;
  experienceList:string[];
  selectDuration: string;
  experienceLevel: string;
  onChange: (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  formErrors: IFormErrors;
}
interface S {}

interface SS {
  id: any;
}

// Customizable Area End

import ProjecttemplatesController, {
  IFormErrors,
  Props,
  configJSON
} from "./ProjecttemplatesController";

export default class ProjectScopeDetailsPage extends BlockComponent<
  IProps,
  SS,
  S
> {
  constructor(props: IProps) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={webStyle.mainContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <InputLabel style={webStyle.label}>
              {configJSON.projectSizeLabelTxt}
            </InputLabel>
            <TextField
              id={`project_input_projectSize`}
              value={this.props.projectSize}
              onChange={event =>
                this.props.onChange(event as React.ChangeEvent<
                  HTMLInputElement
                >)
              }
              InputProps={{
                style: {
                  ...webStyle.projectDurationStyle,
                  
                },
                inputProps:{
                  style:{
                    paddingBottom:"0px",
                    marginTop:"5px"
                  }
                }
              }}
              name="projectSize"
              fullWidth
              data-testid="projectSize"
              type="number"
              placeholder={configJSON.projectDurationPlaceholder}
            />
            {this.props.formErrors && this.props.formErrors.projectSize && (
              <Typography style={{ ...webStyle.errorStyle, marginTop: "20px" }}>
                {this.props.formErrors.projectSize}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel style={webStyle.label} />
            <Select
              id={`project_input_projectDuration`}
              value={this.props.selectDuration}
              onChange={event =>
                this.props.onChange(event as React.ChangeEvent<
                  HTMLSelectElement
                >)
              }
              style={webStyle.selectItemValuestyle}
              fullWidth
              name="projectDuration"
              renderValue={
                this.props.selectDuration !== ""
                  ? undefined
                  : () => (
                      <Typography style={webStyle.placeholderStyle}>
                        {configJSON.durationPlaceholderTxt}
                      </Typography>
                    )
              }
              displayEmpty
              data-testid="projectDuration"
              type="number"
            >
              {durationItems.map(duration => {
                return (
                  <MenuItem
                    key={duration.id}
                    value={duration.value}
                    style={webStyle.selectItemValuestyle}
                  >
                    {duration.label}
                  </MenuItem>
                );
              })}
            </Select>
            {this.props.formErrors && this.props.formErrors.projectDuration && (
              <Typography style={webStyle.errorStyle}>
                {this.props.formErrors.projectDuration}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} style={webStyle.nextbox}>
            <InputLabel style={webStyle.label}>
              {configJSON.freelancerLabelTxt}
            </InputLabel>
            <Select
              fullWidth
              value={this.props.experienceLevel}
              style={webStyle.selectItemValuestyle}
              onChange={event =>
                this.props.onChange(event as React.ChangeEvent<
                  HTMLSelectElement
                >)
              }
              renderValue={
                this.props.experienceLevel !== ""
                  ? undefined
                  : () => (
                      <Typography style={webStyle.placeholderStyle}>
                        {configJSON.projectSizePlaceholder}
                      </Typography>
                    )
              }
              displayEmpty
              name="experienceLevel"
              id={`project_input_experienceLevel`}
              data-testid="experiencelevel"
            >
              {this.props.experienceList?.map(experience => {
                return (
                  <MenuItem
                    key={experience}
                    value={experience}
                    style={webStyle.selectItemValuestyle}
                  >
                    {experience}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          {this.props.formErrors && this.props.formErrors.experienceLevel && (
            <Typography style={webStyle.errorStyle2}>
              {this.props.formErrors.experienceLevel}
            </Typography>
          )}
        </Grid>
        <Box style={webStyle.btnContainerStyle}>
          <Button
            style={webStyle.backBtnStyle}
            onClick={this.props.onComponentBackChange.bind(this, "skills")}
          >
            {configJSON.backBtnTxt}
          </Button>
          <Button
            style={webStyle.continueBtnSyle}
            onClick={this.props.onComponentChange.bind(this, "budget")}
          >
            {configJSON.continueBtnTxt}
          </Button>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  nextbox: {
    marginTop: "15px",
    marginBottom: "70px"
  },
  label: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px",
    color: "#8083A3",
    margin: " 0 0 1.5rem 0"
  },
  buttonc: {
    background: "#00A7A2",
    borderRadius: "23px",
    fonFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    color: "#FFFFFF",
    padding: "10px 1px"
  },
  buttonb: {
    marginRight: "10px",
    padding: "10px 1px",
    borderRadius: "23px",
    fonFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    color: "#222222"
  },
  mainContainer: {
    padding: 20
  },
  placeholderStyle: {
    color: "#8083A3",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 400
  },
  btnContainerStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 40,
    columnGap: 10,
    marginBottom: 20
  },
  backBtnStyle: {
    borderRadius: 23,
    background: "#F2F2F2",
    color: "#222",
    textAlign: "center" as "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    textTransform: "capitalize" as "capitalize",
    padding: "5 35"
  },
  continueBtnSyle: {
    borderRadius: 23,
    backgroundColor: "#00A7A2",
    color: "#fff",
    textAlign: "center" as "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    textTransform: "capitalize" as "capitalize",
    padding: "5 35"
  },
  errorStyle: {
    paddingTop: 10,
    color: "#eb020a",
    fontSize: "14px"
  },
  errorStyle2: {
    marginTop: "-4rem",
    color: "#eb020a",
    fontSize: "14px"
  },
  selectItemValuestyle: {
    color: "#8083A3",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 400,
    lineHeight: 1.5
  },
  projectDurationStyle: {
    color: "#8083A3",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 400,
    margin: "-28px 0px",
  
  }
};
// Customizable Area End
