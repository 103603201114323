import React,{useState} from 'react'
import InputError from '../../../blocks/social-media-account-registration/src/InputError.web'
import { Formik, Field, Form } from "formik";
import { sponsorvalidationSchema } from '../../../blocks/social-media-account-registration/src/validationSchema';
import Select from 'react-select';
import "../../../blocks/social-media-account-registration/assets/css/Accountinfo.css";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ICategoryDataModel, IIndustry } from '../../../blocks/social-media-account-registration/src/types';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import FormSaver from './FormSaver.web';
import CustomSelect from '../utill/CustomSelect.web';
import {MenuItem, Box} from '@material-ui/core'

const init={
    categoriesInterested: "",
    company_name: "",
    website: "",
    industry: "",
    number_of_employee: "",
    companyName: "",
    countrycode: "",
    mobile_number: "",
    vat_id: "",
    hobby_ids:[],
    address1:""
  }

interface Props{
  initialValues?:any;
  submitHandler:any;
  cancelHandler:any;
  submitLoading:boolean;
  categories:ICategoryDataModel[];
  industryList:IIndustry[];
}


const getInitialValue=()=>{
  const sponsorString= localStorage.getItem("sponsorForm")
  let initialValue=init;
  try{
    if(sponsorString){
      const parsedSponsorValue=JSON.parse(sponsorString);
      initialValue=parsedSponsorValue;
    }
    return initialValue;
  }catch(err){
     return initialValue;
  }
}


const SponsorForm:React.FC<Props>=({
    initialValues=getInitialValue(),
    submitHandler,
    cancelHandler,
    submitLoading,
    categories,
    industryList
})=>{
  const [updateInfo,setUpdateInfo]=useState(true);

  const handleGoBack=()=>{
    setUpdateInfo(false)
    cancelHandler();
  }

  
    return(
      <Formik
        enableReinitialize={true}
        validationSchema={sponsorvalidationSchema}
        initialValues={initialValues}
        onSubmit={submitHandler}
      >
        {(props)=>(
          <FormSaver {...props} saveFieldName={"sponsorForm"} saveInfo={updateInfo}>
          {({errors,setFieldValue,values}:any) => (
            <Form translate={undefined}>
              <div className="form-group form-linebreak multiselect" data-test-id="undefiendid">
                
                <label className="hobbuclass" htmlFor="hobby_ids">
                  Categories Interested*
                </label>
                <Select
                  className="multiselect-width"
                  id="hobby_ids"
                  name="hobby_ids"
                  isClearable={false}
                  placeholder="Search and select your Categories"
                  options={categories}
                  isMulti
                  value={values.hobby_ids}
                  onChange={(selectedValues)=>{
                    setFieldValue("hobby_ids",selectedValues?selectedValues:[])
                  }}
                />
                <InputError
                  errors={errors}
                  name="hobby_ids"
                />
              </div>
              <div className="d-flex sponsor_form">
                <div className="form-group form-linebreak">
                  <label htmlFor="company_name">Company Name</label>
                  <Field
                    type="text"
                    id="company_name"
                    name="company_name"
                    placeholder="Enter your company name"
                  />
                  <InputError
                    errors={errors}
                    name="company_name"
                  />
                </div>
                <div className="form-group form-linebreak">
                  <label htmlFor="website">Website</label>
                  <Field
                    type="text"
                    id="website"
                    name="website"
                    placeholder="Enter your website"
                  />
                  <InputError
                    errors={errors}
                    name="website"
                  />
                </div>
              </div>
              <div className="d-flex sponsor_form">
                                   <CustomSelect
                                            value={values?.industry}
                                            onChange={(e:any)=> setFieldValue(`industry`,e.target.value)}
                                            label="Company Industry"
                                            renderItem={(item,props)=>(
                                                <MenuItem
                                                  key={item?.attributes?.industry_name}
                                                  value={item?.attributes?.industry_name}
                                                  {...props}
                                                >
                                                {item?.attributes?.industry_name}
                                              </MenuItem>
                                            )}
                                            name={`industry`}
                                            id={`industry`}
                                            data={industryList}
                                            error={errors?.industry || ""}
                                            placeholder="Select your industry"
                                            labelStyle={{
                                              marginBottom:"14px"
                                            }}
                                            rootStyle={{
                                              paddingRight:"20px"
                                            }}
                                          />
                <div className="form-group form-linebreak">
                  <label htmlFor="number_of_employee">
                    Number of Employees
                  </label>
                  <Field
                    type="number"
                    id="number_of_employee"
                    name="number_of_employee"
                    placeholder="Enter number of employees"
                  />
                  <InputError
                    errors={errors}
                    name="number_of_employee"
                  />
                </div>
              </div>
              <div className="form-group form-linebreak">
              <label htmlFor="address1">Address Line 1*</label>
                <Field
                  name="address1"
                  as="textarea"
                  rows="5"
                  placeholder="Enter your company address"
                />
                <InputError
                    errors={errors}
                    name="address1"
                  />
              </div>
              <div className="d-flex sponsor_form">
                <div className="form-group form-linebreak signin-phone">
                  <label htmlFor="countrycode">Mobile Number*</label>
                        <PhoneInput
                              inputStyle={{
                                width:"100%",
                                border:"none",
                                borderRadius:"0px",
                                borderBottom:"1px solid #e4e6e8"
                              }}
                              value={values.mobile_number}
                              onChange={phone => setFieldValue("mobile_number",phone)}
                              country={"in"}
                            />
                  <InputError
                      errors={errors}
                      name="mobile_number"
                    />
                </div>
                <div className="form-group form-linebreak">
                  <label htmlFor="vat_id">VAT ID*</label>
                  <Field
                    type="text"
                    id="vat_id"
                    name="vat_id"
                    placeholder="Enter your VAT TD"
                  />
                  <InputError
                      errors={errors}
                      name="vat_id"
                    />
                </div>
              </div>
              <Box className="button-block" display="flex" flexWrap="wrap">
                <button type="reset" className="button btn" onClick={handleGoBack}>
                  Cancel
                </button>
                <button disabled={submitLoading} type="submit" className="button btn-orange" test-id="submitButtonId2">
                  {
                    submitLoading?<CircularProgress size={16} />:"Complete"
                  }
                </button>
              </Box>
            </Form>
          )}
        </FormSaver>
        )}
      </Formik>
    )


}


export default SponsorForm;