import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";


// Customizable Area Start
import { WithStyles} from '@material-ui/core';
import { getNavigationMessage } from "../../../components/src/Navigation.web"
import { IProfile } from "../../rolesandpermissions/src/Roles.types";
import { getStorageData } from "framework/src/Utilities";
import CreateRestApiMessage from "../../../components/src/CreateRestApiMessage.web";
// Customizable Area End

export const configJSON = require("./config");




export interface Props extends WithStyles{
  navigation:any;
  id:string;

}

export interface IConversionParams{
  currency: string;
  currency_symbol: string;
  amount: number;
  tax: number;
}
export interface ICurrencyConversion {
  sponsor: IConversionParams;
  freelancer: IConversionParams;
}
export interface ITransectionHistory{
  id: string;
    type: string;
    attributes: {
        id: number;
        status: string;
        project_name: string;
        freelancer_name: string | null;
        title: string;
        description: string;
        date: string;
        amount: number | null;
        designation: string | null;
        escrowCredit: number;
        avatar: string[];
        currency_conversion:ICurrencyConversion;
        payment_type:"withdrawn"
    };
}
export interface S {
  // Customizable Area Start
  balance:{
    main:number;
    inProgress:number;
  },
  currentUser:IProfile | null;
  transectionHistory:ITransectionHistory[];
  getBalanceLoading:boolean;
  getTransectionHistoryLoading:boolean;
  totalHistory: number;
  currentPage: number;
  // Customizable Area End
}
export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class FreelancerEarningsController extends BlockComponent<
  Props, S, SS
> {

    // Customizable Area Start
  getFreelancerBalanceApiCallId:string="";
  getFreelancerPaymentReportsApiCallId:string="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.LoginUserMessage),
    ];

        this.state = {
            // Customizable Area Start
          balance:{
            inProgress:0,
            main:0
          },
          currentUser:null,
          transectionHistory:[],
          getBalanceLoading:false,
          getTransectionHistoryLoading:false,
          totalHistory: 0,
          currentPage: 1,
          // Customizable Area End
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }
  async componentDidMount() {
    // Customizable Area Start
    this.sendGetCurrentUserMessage();
    this.getFreelancerBalance();
    this.getPaymentHistory();
     // Customizable Area End
  }
  

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
  
    // Customizable Area Start
    if (message.id === getName(MessageEnum.LoginUserMessage)) {
      const userInfo = message.getData(getName(MessageEnum.LoginUserBodyMessage));
       this.handleCurrentUserResponse(userInfo);
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleApiRequest(apiRequestCallId,response)
     
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleApiRequest=(apiRequestCallId:string,responseJson:object)=>{
    if(apiRequestCallId===this.getFreelancerBalanceApiCallId){
       this.handleFreelancerBalanceApiPayload(responseJson);
    }else if(apiRequestCallId===this.getFreelancerPaymentReportsApiCallId){
       this.handleFreelancerPaymentHistoryApiPayload(responseJson);
    }
  }
  handleFreelancerPaymentHistoryApiPayload=(responseJson?:{
    reports?:{
      data?:ITransectionHistory[]
    };
    pagination?:{
      current_page:number|null;
      next_page:number|null;
      total_data:number|null;
    }
  })=>{

      if (responseJson && responseJson?.reports && responseJson?.reports?.data) {
        this.setState((prev) => {
          let newReportsList: ITransectionHistory[] = [];
          if (prev.currentPage === 1) {
            newReportsList = responseJson?.reports?.data || [];
          } else {
            const newList=responseJson?.reports ?responseJson?.reports?.data || []:[];
            newReportsList = [...prev.transectionHistory, ...newList];
          }
          return {
            transectionHistory: newReportsList,
            getTransectionHistoryLoading: false,
            totalHistory: responseJson?.pagination?.total_data || 0,
          };
        });
      } else {
        this.setState({ getTransectionHistoryLoading: false,transectionHistory:[],totalHistory:0 });
      }
  }
handleFreelancerBalanceApiPayload=(responseJson:{
  balance?:number;
  in_progress_balance?:number;
})=>{
  this.setState({
    getBalanceLoading:false,
    balance:{
      main:responseJson?.balance || 0,
      inProgress:responseJson?.in_progress_balance || 0
    }

  })
}
  handleCurrentUserResponse=(user:IProfile)=>{
    if(user?.id){
      this.setState({ currentUser: user });
    }
   
  }
  handleWithdrawFunds=()=>{
    this.send(getNavigationMessage('FreelancerWithdrawal', this.props));
  }
  onHandleBackToDashboard=()=>{
    this.send(getNavigationMessage('FreelancerDashboard', this.props));
  }
  sendGetCurrentUserMessage=()=>{
    const loginUserMessage: Message = new Message(
      getName(MessageEnum.GetCurrentUserMessage)
    );

    this.send(loginUserMessage);
  }

  getHeader=async()=>{
    const token = await getStorageData("loginSuccessToken");
    const header = {
      token: token,
      "Content-Type": "application/json",
    };
    return header;
  }

  getFreelancerBalance=async()=>{
      this.setState({
        getBalanceLoading:true
      });
      const header = await this.getHeader();
      const apiRequestMessage = CreateRestApiMessage({
        header,
        apiUrl: configJSON.getFrelancerBalanceApiCallId,
        body: null,
        method: "GET",
      });
      this.getFreelancerBalanceApiCallId= apiRequestMessage.messageId;
      runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  }
  getPaymentHistory=async()=>{
    const { currentPage } = this.state;
    const query=`current_page=${currentPage}&per_page=${4}`
      this.setState({
        getTransectionHistoryLoading:true
      });
      const header = await this.getHeader();
      const apiRequestMessage = CreateRestApiMessage({
        header,
        apiUrl: `${configJSON.getFreelancerEarningHistoryApiCallId}&${query}`,
        body: null,
        method: "GET",
      });
      this.getFreelancerPaymentReportsApiCallId= apiRequestMessage.messageId;
      runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  }
  handleHistoryLoadMore = () => {
    const { getTransectionHistoryLoading } = this.state;
    if (!getTransectionHistoryLoading) {
      this.setState(
        (prev) => ({ currentPage: prev.currentPage + 1 }),
        this.getPaymentHistory
      );
    }
  };


 // Customizable Area End
}

