import React from "react";

// Customizable Area Start
import {
  Typography,
  InputLabel,
  Box,
  Button,
 } from "@material-ui/core";

import { withStyles, createTheme,ThemeProvider } from "@material-ui/core/styles";
import { Formik, Field, Form, ErrorMessage } from "formik";
import CircularProgress from "@material-ui/core/CircularProgress";
// Customizable Area End

// Customizable Area Start
export const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

// Customizable Area End

import Customisableuserprofiles2Controller, {
  Props,
  configJSON,
  HoursValidation,
} from "./Customisableuserprofiles2Controller";

export default class HourlyRatePage extends Customisableuserprofiles2Controller {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box className={classes.mainContainer}>
          <Typography className={classes.skillsHeading}>
            {configJSON.hourlyRateandAvialabiltyTxt}
          </Typography>
          <Formik
            enableReinitialize={true}
            initialValues={this.state.hourlyChargesValueData}
            onSubmit={values => {
              this.submitHoursAndAvailabilty(values);
            }}
            
            validationSchema={HoursValidation}
            data-testid = 'formik'
          >
            {({  resetForm }) => (
              
              <Form translate={undefined}>
          <Box className={classes.hoursDetailsInputContainer}>
      
              <Box style={{width:'100%'}}>
                <InputLabel className={classes.inputLabelTxt}>
                  {configJSON.hourlyRateLabel}
                </InputLabel>
                <Box className={classes.mainboxStyle}>
                <Typography className={classes.perHourDollarStyle}>{this.state.apidata.currency_symbol}</Typography>
                <Field
                  name="hourlyChargesValue"
                   data-testid="hourlyChargesValue"
                   onKeyPress={this.onKeyPressAvoidNumbers}
                   className={classes.InputFieldStyle}
                   />
                    <Typography className={classes.perHourTxtStyle}>{configJSON.perHourTxt}</Typography>
                  </Box>   
                         <ErrorMessage
                         name="hourlyChargesValue"
                         component="div"
                         className="error-class"
                       />
              
               </Box>
         
          </Box>
          <Box className={classes.bottonsBlock}>
            <Button
            variant = "contained"
            data-testid="cancelHourlyCharges"
            disableElevation
             className={classes.cancelBtn} 
             onClick={this.resetHoursFormValues.bind(this, resetForm)}>
              {configJSON.cancelBtnTxt}
            </Button>

            <Button
            variant = "contained"
              type="submit"
              className={classes.buttonBasicDetails}
              data-testid="saveHourlyCharges"
              disableElevation
            >
              {this.state.isHourlyRateLoaded?<CircularProgress size={24} className={classes.loaderColorStyle} />:configJSON.saveBtnTxt}
              </Button>
          </Box>

          </Form>
            )}
          </Formik>
        </Box>

      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const styles = () => ({
  mainContainer: {
    border: "1px solid #E4E6E8",
    borderRadius: "14px",
    padding: 20,
  },
  skillsHeading: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "24px",
    color: "#222222",
    "@media only screen and (max-width: 400px)": {
      fontSize: "14px",
    },
  },
  mainboxStyle:{
display:'flex',
width:'100%'
  },
  buttonBasicDetails: {
    border:'none',
    minWidth: '140px',
    borderRadius: '23px',
    backgroundColor: "#ec952d",
    padding: '9px 30px',
    color: '#fff',
    fontSize: '14px',
    margin: '0 15px',
    fontWeight:700,
    textTransform:'none' as const,
    '&:hover': {
      backgroundColor: "#ec952d",
    },
    "@media only screen and (max-width: 370px)": {
      minWidth: '100px',
    }
  },
  perHourDollarStyle:{
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    color: "#8083A3",
    display:'flex',
    alignItems:'center',
  },
  bottonsBlock:{
    display:'flex',
    alignItem:'center',
    justifyContent:'center',
    marginTop:'50px'
  },
  cancelBtn: {
      minWidth: '140px',
      borderRadius: '23px',
      backgroundColor: "#f2f2f2",
      padding: '9px 30px',
      color: '#000',
      fontSize: '14px',
      margin: '0 15px',
      textTransform:'none' as const,
      border:'none',
      '&:hover': {
        backgroundColor: "#f2f2f2",
      },
      "@media only screen and (max-width: 370px)": {
        minWidth: '100px',
      }
  },
  InputFieldStyle:{
    width:'auto',
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    color: "#8083A3",
    "@media only screen and (max-width: 370px)": {
      width:'200%'
    },
  },

  hoursDetailsInputContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: "30px",
    width:'100%'
  },
  perHourTxtStyle:{
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    color: "#8083A3",
    width:'100%',
    marginTop:'8px',
    "@media only screen and (max-width: 370px)": {
      marginLeft:'20px',
    },
  },
  loaderColorStyle:{
    color:'#8083a3'},
    inputLabelTxt:{
      fontFamily: "Inter",
      fontWeight: 700,
      fontSize: "14px",
      color: "#8083A3",
    }
});

export const HourlyRatePageStyles = withStyles(styles)(HourlyRatePage);
// Customizable Area End
