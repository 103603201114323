import React, { useState } from 'react';
import InputError from '../../../blocks/social-media-account-registration/src/InputError.web'
import { Formik, Field, Form, FieldArray } from "formik";
import Select from 'react-select';
import "../../../blocks/social-media-account-registration//assets/css/Accountinfo.css";
import { studentFreelancerValidationSchema } from '../../../blocks/social-media-account-registration/src/validationSchema';
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {ICategoryDataModel, IEducationalQualification, IFieldOfStudy, IUniversityCollege } from '../../../blocks/social-media-account-registration/src/types';
import FormSaver from './FormSaver.web';
import LocationContainer from './LocationContainer';
import CustomSelect from '../utill/CustomSelect.web';
import {MenuItem, IconButton,Box} from '@material-ui/core'

import {AddOutlined} from '@material-ui/icons';

export const getBirthdate=()=>{
  const basicDetailsInfoString = localStorage.getItem(
    "Basicdetailarray"
  ) as string;
  if(basicDetailsInfoString){
   return JSON.parse(basicDetailsInfoString)?.date_of_birth || "";
  }
  return ""

}

const init={
    birthDate:"",
    skill_ids:[],
    hobby_ids:[],
    uploaded_documents: null,
    certification: "",
    freelancing: "",
    education: "",
    college: "",
    student_id: "",
    field_of_study: "",
    start_year: "",
    end_year: "",
    currentlyWorking: false,
    educational_qualifications: [
      { education: "", college: "", field: "",start_year:"",end_year:"",current_study:false,birthDate:""}
    ],
    career_experiences: [
      {
        jobTitle: "",
        employerName: "",
        country: "",
        city: "",
        jobDescription: ""
      }
    ]
}

interface Props{
    initialValues?:any;
    submitHandler:any;
    cancelHandler:any;
    uploaded_documents:any;
    student_id:any;
    handleUploadDocumentChange:any;
    handleStudentIdChange:any;
    submitLoading:boolean;
    countryList:string[];
    countryLoading:boolean;
    cityList:string[];
    cityLoading:boolean;
    getCityList:any;
    skills:ICategoryDataModel[];
    categories:ICategoryDataModel[];
    educationList:IEducationalQualification[];
    fieldOfStudyList:IFieldOfStudy[];
    universityList:IUniversityCollege[];
    stateList:string[];
    getStateLoading:boolean;
    getStateList:any;
    freelancingExperienceList:string[];
    handleOpenUniversityModal:(open:boolean)=>void;
}


const getInitialValue=()=>{
  const studentFormString= localStorage.getItem("studentForm")
  let initialValue=init;
  try{
    if(studentFormString){
      const parsedStudentValue=JSON.parse(studentFormString);
      initialValue=parsedStudentValue;
    }
    return initialValue;
  }catch(err){
     return initialValue;
  }
}



const StudentForm:React.FC<Props>=({
    initialValues=getInitialValue(),
    submitHandler,
    cancelHandler,
    uploaded_documents,
    student_id,
    handleStudentIdChange,
    handleUploadDocumentChange,
    submitLoading,
    countryList,
    countryLoading,
    cityList,
    cityLoading,
    getCityList,
    skills,
    categories,
    universityList,
    stateList,
    getStateList,
    getStateLoading,
    fieldOfStudyList,
    educationList,
    freelancingExperienceList,
    handleOpenUniversityModal
})=>{
    const [updateInfo,setUpdateInfo]=useState(true);
    const studentFormikRef=React.useRef<any>(null);
    const handleGoBack=()=>{
       setUpdateInfo(false)
       cancelHandler();
    }

    React.useEffect(()=>{
        const birthDate=getBirthdate();
        studentFormikRef.current.setFieldValue("educational_qualifications.0.birthDate",birthDate);
        studentFormikRef.current.setFieldValue("birthDate",birthDate);
    },[])


  let fileExtension = student_id?.name.split('.').pop();
    return(
      <Formik
        innerRef={studentFormikRef}
        enableReinitialize={true}
        initialValues={initialValues}
        test-id="submitButtonId"
        onSubmit={submitHandler}
        validationSchema={studentFreelancerValidationSchema}
        
      >
        {(props)=>(
          <FormSaver {...props} saveFieldName={"studentForm"} saveInfo={updateInfo}>
                 {({ values, setFieldValue,errors }:any) => (
                  
                  <Form translate={undefined}>
                    {console.log("Birthdate",values.birthDate)}
                    <div className="step-1">
                      <div className="form-group form-linebreak multiselect ff">
                        <label htmlFor="skill_ids">Skills*</label>
                        <Select
                          id="skill_ids"
                          test-id="skill_ids"
                          isClearable={false}
                          className="multiselect-width"
                          name="skill_ids"
                          placeholder="Search and select your skills"
                          required
                          options={skills}
                          isMulti
                          value={values.skill_ids}
                          onChange={(selectedValue)=>setFieldValue("skill_ids",selectedValue?selectedValue:[])}
                        />
                        <InputError errors={errors} name="skill_ids"/>
                      </div>
                      <div className="form-group form-linebreak multiselect" data-test-id="12">
                        <label htmlFor="hobby_ids">
                          Categories Interested*
                        </label>
                        <Select
                         isClearable={false}
                          className="multiselect-width"
                          id="hobby_ids"
                          name="hobby_ids"
                          placeholder="Search and select your Categories"
                          required
                          options={categories}
                          isMulti
                          value={values.hobby_ids}
                          onChange={(selectedValue)=>setFieldValue("hobby_ids",selectedValue?selectedValue:[])}
                        />
                        <InputError errors={errors} name="hobby_ids"/>
                      </div>
                      <div className="step-2">
                        <div className="block-title">
                          <h6>Education Details</h6>
                        </div>
                        <div>
                          <FieldArray name="educational_qualifications" test-id="educationDetails">
                            {({ push,remove }) => {
                              const educationErrors=errors?.educational_qualifications || [] as any;
                              return(
                                <div>
                                  {values.educational_qualifications.map(
                                    (detail:any, index:number) => (
                                      <div
                                        className="block-content"
                                        key={index}
                                      >
                                        <div className="block-inner">
                                          <p>Education Details {index + 1}</p>
                                          {values.educational_qualifications.length > 1 && (
                                              <span
                                                test-id="removeExperienceDetailsId"
                                                className="link"
                                                onClick={() => remove(index)}
                                              >
                                                Remove
                                              </span>
                                            )}
                                            
                                          {index ===
                                            values.educational_qualifications.length -
                                            1 && (
                                              <span test-id="addEducationId"
                                                className="link"
                                                onClick={() =>
                                                  push({ education: "", college: "", field: "",start_year:"",end_year:"",current_study:false,birthDate:values?.birthDate})
                                                }
                                              >
                                                + Add another education detail
                                              </span>
                                            )}
                                        </div>
                                        <div className="d-flex">
                                        <CustomSelect
                                            value={values?.educational_qualifications[index]?.education}
                                            onChange={(e:any)=>setFieldValue(`educational_qualifications.${index}.education`,e.target.value)}
                                            label="Education*"
                                            renderItem={(item,props)=>(
                                                <MenuItem
                                                  key={item?.id}
                                                  value={item?.id}
                                                  {...props}
                                                >
                                                {item?.attributes?.education}
                                              </MenuItem>
                                            )}
                                            name={`educational_qualifications.${index}.education`}
                                            id={`educational_qualifications.${index}.education`}
                                            data={educationList}
                                            error={educationErrors[index]?.education || ""}
                                            placeholder=" Select your degree/diploma"
                                            labelStyle={{
                                              marginBottom:"14px"
                                            }}
                                            rootStyle={{
                                              paddingRight:"20px"
                                            }}
                                          />

                                        <CustomSelect
                                            value={values?.educational_qualifications[index]?.college}
                                            onChange={(e:any)=>setFieldValue(`educational_qualifications.${index}.college`,e.target.value)}
                                            label="University/College*"
                                            renderItem={(item,props)=>(
                                                <MenuItem
                                                  key={item?.id}
                                                  value={item?.id}
                                                  {...props}
                                                >
                                                {item?.attributes?.name}
                                              </MenuItem>
                                            )}
                                            name={`educational_qualifications.${index}.college`}
                                            id={`educational_qualifications.${index}.college`}
                                            data={universityList}
                                            error={educationErrors[index]?.college || ""}
                                            placeholder=" Select your University/College"
                                            labelStyle={{
                                              marginBottom:"14px"
                                            }}
                                            labelSuffix={
                                              <IconButton style={{padding:0,marginLeft:"12px"}} onClick={()=>handleOpenUniversityModal(true)}>
                                                <AddOutlined/>
                                              </IconButton>
                                            }
                                          />
                                        </div>

                                        <CustomSelect
                                            value={values?.educational_qualifications[index]?.field}
                                            onChange={(e:any)=> setFieldValue(`educational_qualifications.${index}.field`,e.target.value)}
                                            label="Field of Study*"
                                            renderItem={(item,props)=>(
                                                <MenuItem
                                                  key={item?.id}
                                                  value={item?.id}
                                                  {...props}
                                                >
                                                {item?.attributes?.field_of_study}
                                              </MenuItem>
                                            )}
                                            name={`educational_qualifications.${index}.field`}
                                            id={`educational_qualifications.${index}.field`}
                                            data={fieldOfStudyList}
                                            error={educationErrors[index]?.field || ""}
                                            placeholder=" Select your field of study"
                                            labelStyle={{
                                              marginBottom:"14px"
                                            }}
                                          />
                                        <div className="d-flex">
                                          <div className="form-group form-linebreak">
                                            <label htmlFor={`educational_qualifications.${index}.start_year`} data-test-id="1">Start Date*</label>
                                            <input
                                              name={`educational_qualifications.${index}.start_year`}
                                              id={`educational_qualifications.${index}.start_year`}
                                              value={values?.educational_qualifications[index]?.start_year}
                                              onChange={(e)=> setFieldValue(`educational_qualifications.${index}.start_year`,e.target.value)}
                                              placeholder="Select your Start Date"
                                              className="textbox-n"
                                              type="date"
                                            />
                                          <InputError errors={educationErrors[index] || {}} name="start_year"/>
                                          </div>
        
                                          <div className="form-group form-linebreak f23">
                                            <label htmlFor={`educational_qualifications.${index}.end_year`}>End Date*</label>
                                            <input
                                               name={`educational_qualifications.${index}.end_year`}
                                               id={`educational_qualifications.${index}.end_year`}
                                               value={values?.educational_qualifications[index]?.end_year}
                                               onChange={(e)=> setFieldValue(`educational_qualifications.${index}.end_year`,e.target.value)}
                                              placeholder="Select your End Date"
                                              className="textbox-n"
                                              type="date"
                                            />
                                            <InputError errors={educationErrors[index] || {}} name="end_year"/>
                                          </div>
                                        </div>
                                        { values.educational_qualifications.length-1 === index &&
                                          <div style={{marginTop:"-24px"}} className='d-flex'>
                                        <FormControlLabel
                                            control={
                                              <Checkbox
                                                  checked={values?.educational_qualifications[index]?.current_study}
                                                  onChange={(e:any)=> setFieldValue(`educational_qualifications.${index}.current_study`, e.target.checked)}
                                                  name="checkedB"
                                                  color="primary"
                                                />
                                            }
                                            label="I currently study here"
                                            />
                                        </div>}
                                      </div>
                                    )
                                  )}
                                </div>
                              )
                            }}
                          </FieldArray>
                        </div>
                      </div>
                      <div className="d-flex college_id_desc">
                        <div className="form-group form-linebreak" test-id="1">
                          <label htmlFor="college_id">College ID No*</label>
                          <Field 
                            name="college_id"
                             id="college_id"
                            type="text"
                            placeholder="Enter College ID No."
                          />
                        <InputError errors={errors} name="college_id"/>
                        </div>
                        <div className="form-group form-linebreak" data-test-id="a">
                          <label htmlFor="college">College ID</label>
                          {student_id ? student_id.name.length > 15 ?  student_id.name.substring(0, 15) + "." + fileExtension :  student_id.name : <span>
                            Upload the image of your college ID
                          </span>}
                          <div className="upload-btn" style={{top: "27px"}}>
                            <div className="upload-btn-wrapper">
                              <button
                                type="button"
                                className="button btn-orange"
                              >
                                Upload a file
                              </button>
                              <input test-id="uploadStudentId"
                                type="file"
                                id="student_id"
                                name="student_id"
                                placeholder="Upload your college ID (.DOC, .DOCX, .PDF. Max file size 10MB)"
                                accept='image/*'
                                onChange={(event: any) => {
                                  setFieldValue(
                                    "student_id",
                                    event.target?.files[0]
                                  );
                                  handleStudentIdChange(event)
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group form-linebreak" style={{paddingRight:"0px"}}>
                        <label htmlFor="upload" id="12">Upload Resume</label>
                        {uploaded_documents ? uploaded_documents.name : <span>
                          Upload your resume (.DOC, .DOCX, .PDF. Max file
                          size 10MB)
                        </span>}
                        
                        <div className="upload-btn">
                          <div className="upload-btn-wrapper">
                            <button
                              type="button"
                              className="button btn-orange"
                            >
                              Upload a file
                            </button>
                            <input test-id="uploadId"
                              type="file"
                              id="uploaded_documents"
                              name="uploaded_documents"
                              placeholder="Upload your resume (.DOC, .DOCX, .PDF. Max file size 10MB)"
                              accept=".pdf, .doc, .xls, .xlsx, .docx ,.odf ,.ppt"
                              onChange={(event: any) => {
                                setFieldValue(
                                  "uploaded_documents",
                                  event.target?.files[0]
                                );
                                handleUploadDocumentChange(event)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="certification">Certifications</label>
                        <Field test-id="certificateId"
                          type="text"
                          data-test-id="a1"
                          id="certification"
                          name="certification"
                          placeholder="Enter your certifications"
                        />
                        <InputError errors={errors} name="certification"/>
                      </div>
                        <div className="form-group">
                          <label htmlFor="freelanceexperience">Freelancing Experience</label>
                          <input
                              type="number"
                              value={values?.experience_in_year}
                              onChange={(e:any)=>setFieldValue("experience_in_years",e.target.value)}
                              data-testid="experience"
                              name="freelanceexperience"
                              placeholder="Select Years"
                              min="0"
                            />
                            <InputError
                            errors={errors}
                            name="experience_in_years"
                          />
                        </div>
                    </div>
        
                    <div className="step-3">
                      <div className="block-title">
                        <h6>Experience Details</h6>
                      </div>
                      <div>
                        <FieldArray name="career_experiences" test-id="experienceDetailsId">
                          {({ push, remove }) => (
                            <div>
                              {values.career_experiences.map((detail:any, index:number) => {
                                const career_experiencesErrors=errors?.career_experiences || [] as any;
                            
                                return(
                                    <div className="block-content" key={index}>
                                    <div className="block-inner">
                                      <p>Experience Details {index + 1}</p>
                                      
                                      {values.career_experiences.length > 1 && (
                                        <span
                                          test-id="removeExperienceDetailsId"
                                          className="link"
                                          onClick={() => remove(index)}
                                        >
                                          Remove
                                        </span>
                                      )}
                                    </div>
                                    <div className="d-flex" test-id="experienceFormId">
                                      <div className="form-group form-linebreak">
                                        <label htmlFor={`career_experiences.${index}.jobTitle`}>
                                          Job Title*
                                        </label>
                                        <Field
                                          placeholder="Job Title"
                                          data-test-id="aasd"
                                          name={`career_experiences.${index}.jobTitle`}
                                          id={`career_experiences.${index}.jobTitle`}
                                        />
                                        <InputError
                                            errors={career_experiencesErrors[index]||{}}
                                            name="jobTitle"
                                        />
                                      </div>
                                      <div className="form-group">
                                        <label htmlFor={`career_experiences.${index}.employerName`}>
                                          Employer Name*
                                        </label>
                                        <Field
                                          placeholder="Employer Name"
                                          name={`career_experiences.${index}.employerName`}
                                          id={`career_experiences.${index}.employerName`}
                                        />
                                        <InputError
                                            errors={career_experiencesErrors[index]||{}}
                                            name="employerName"
                                        />
                                      </div>
                                    </div>
                                    <LocationContainer
                                        key={index}
                                        cityLoading={cityLoading}
                                        index={index}
                                        countryLoading={countryLoading}
                                        experienceErrors={career_experiencesErrors}
                                        getCityList={getCityList}
                                        getStateList={getStateList}
                                        getStateLoading={getStateLoading}
                                        setFieldValue={setFieldValue}
                                        values={values}
                                        countryList={countryList}
                                        cityList={cityList}
                                        stateList={stateList}
                                    />
                                    <div className="form-group form-linebreak">
                                      <label
                                        htmlFor={`career_experiences.${index}.jobDescription`}
                                      >
                                        Job Description*
                                      </label>
                                      <Field
                                        name={`career_experiences.${index}.jobDescription`}
                                        id={`career_experiences.${index}.jobDescription`}
                                        as="textarea"
                                        rows="5"
                                        placeholder="Enter job description"
                                      />
                                       <InputError
                                            errors={career_experiencesErrors[index]||{}}
                                            name="jobDescription"
                                        />
                                    </div>
                                    {index === values.career_experiences.length - 1 && (
                                        <span
                                          test-id="addExperienceDetailsId"
                                          className="link"
                                          onClick={() =>
                                            push({
                                              jobTitle: "",
                                              employerName: "",
                                              country: "",
                                              city: "",
                                              state:"",
                                              jobDescription: "",
                                              currently_working_here: false
                                            })
                                          }
                                        >
                                          + Add another experience detail
                                        </span>
                                      )}
                                  </div>
                                )
                              })}
                            </div>
                          )}
                        </FieldArray>
                      </div>
                    </div>
                    <Box className="button-block" display="flex" flexWrap="wrap">
                      <button
                        data-test-id="continueBtn"
                        type="reset"
                        className="button btn"
                        onClick={handleGoBack}
                      >
                        Cancel
                      </button>
                      <button disabled={submitLoading} type="submit" className="button btn-orange" test-id="submitButtonId2">
                        {
                          submitLoading?<CircularProgress size={16} />:"Complete"
                        }
                      </button>
                    </Box>
                  </Form>
                )}
          </FormSaver>
        )}
       
      </Formik>
    )
}

export default StudentForm;