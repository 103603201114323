import React from "react";

import {
  // Customizable Area Start
  Grid,
  Box,
  Typography,
  Avatar,
  Divider,
  Modal,
  IconButton,
  InputLabel,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent
} from "@material-ui/lab";
import { withStyles, createTheme, ThemeProvider } from "@material-ui/core/styles";

import {
  Check as CheckIcon, HighlightOff,
  CheckCircleOutlineRounded as CheckCircleOutlineRoundedIcon,
  CheckCircle as CheckCircleIcon
} from "@material-ui/icons";
import { messageBlueIcon, milestoneCreatedIcon, download, document, deleteFile } from "./assets";
import moment from 'moment';
import StarRatings from 'react-star-ratings';
import Dropzone from "react-dropzone";

const theme = createTheme({
  palette: {
    },
  typography: {
      fontFamily:'inter',
    },
    overrides: {
    MuiInputBase: {
      input: {
              color: '#8083a3',
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: 400,
          },
      },
  },
 
});

const projectHistory = [
  {
    id: 1,
    name: "Bid Accepted",
    bottomLabel: "Agreed Amount",
    bottomValue: "$10",
    bottomLabel2: "Dead Line",
    bottomValue2: "24-Sep-2022",
    icon: CheckIcon,
  },
  {
    id: 2,
    name: "Milestone Created",
    bottomLabel: "Name",
    bottomValue: "Milestone Name 1",
    bottomLabel2: "Dead Line",
    bottomValue2: "24-Sep-2022",
    icon: messageBlueIcon,
  },
];

const StyledTimelineItem = withStyles({
  missingOppositeContent: {
    "&:before": {
      display: "none",
    },
  },
})((props) => <TimelineItem {...props} />);

const StyledTextFeedbackField = withStyles({
  root: {
    maxWidth: '100%',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    color: '#8083a3',
    "& .MuiFilledInput-root": {
      backgroundColor: "transparent",
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: "transparent",
    },
    "& .MuiFilledInput-root:hover": {
      backgroundColor: "transparent",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      }
    },
    "& input::placeholder": {
      fontFamily: 'Inter',
      fontSize: '14',
      fontWeight: 400,
      color: '#8083a3'
    },
  }
})(TextField);

// Customizable Area End

import SchedulingController, {
  Props,
  configJSON,
} from "./SchedulingController";
import {getSponsorMulitCurrencyHTML} from "../../../components/src/utill/getMultiCurrencyHtml";
import { TimeOnly,replaceBidToQuote } from "../../../components/src/utill/TimeAndDate.web";


export default class ProjectDetails extends SchedulingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  getArbitrationTitle = (evidenceStatus: String | null) => {
    if (evidenceStatus) {
      return configJSON.titleAfterArbitration
    }
    else if (!evidenceStatus) {
      return configJSON.titleBeforeArbitration
    }


  }
  renderAcceptedBid = (bid_accepted: any) => {
    const { classes } = this.props;
    let title=bid_accepted.title;
    if(!title){
      title=bid_accepted.flag_title;
    }
     title=replaceBidToQuote(title);
    
    return <Grid container className={classes.displayDetailGrid}>
      <Box className={classes.displayDetailsMainBox}>
        <Box className={classes.displayVerifyBox}>
          <CheckCircleOutlineRoundedIcon className={classes.milestoneTitleImage} />
          {
          (bid_accepted.flag_title=== this.state.milestoneFlags[0] 
            || bid_accepted.flag_title===this.state.milestoneFlags[1]
            || bid_accepted.flag_title===this.state.milestoneFlags[8] 
            || bid_accepted.flag_title===this.state.milestoneFlags[13] 
            || bid_accepted.flag_title===this.state.milestoneFlags[21]
            ) && 
          <Typography className={classes.timelineContentWorkReview}>
            {title}
            </Typography>
          }
          {
          bid_accepted.flag_title=== this.state.milestoneFlags[19]  && 
          <Typography className={classes.timelineContentWorkReview}>
            {bid_accepted.milestone_title}-{bid_accepted.title}
            </Typography>
          }
        </Box>
        {this.renderUser(classes, bid_accepted)}
      </Box>
      <Divider className={classes.divider}></Divider>
      {this.renderMilestoneDescription(classes, bid_accepted)}
    </Grid>
  }

  renderCreatedMilestone = (milestone_created: any) => {
    const { classes } = this.props;
    return <Grid container className={classes.displayDetailGrid}>
      <Box className={classes.displayDetailsMainBox}>
        <Box className={classes.displayVerifyBox}>
          <img className={classes.milestoneTitleImage} src={milestoneCreatedIcon} />
          <Typography className={classes.timelineContentWorkReview}>{milestone_created.title}</Typography>
        </Box>
        {this.renderUser(classes, milestone_created)}
      </Box>
      <Divider className={classes.divider}></Divider>
      {this.renderMilestoneDescription(classes, milestone_created)}
    </Grid>
  }

  renderFileUploadedMileStone = (file_uploaded: any) => {
    const { classes } = this.props;
    return <Grid container className={classes.displayDetailGrid}>
      <Box className={classes.displayDetailsMainBox}>
        <Box className={classes.displayVerifyBox}>
          <img className={classes.milestoneTitleImage} src={milestoneCreatedIcon} />
          <Typography className={classes.timelineContentWorkReview}>{file_uploaded.title}</Typography>
        </Box>
        <Box className={classes.displayVerifyBox}>
          <Avatar src={file_uploaded.profile_pic.url} className={classes.avatarStyle} />
          <Typography className={classes.timelineAvatarText}>{file_uploaded.user_name}</Typography>
        </Box>
      </Box>
      <Divider className={classes.divider}></Divider>
      <Grid container className={classes.downloadDetailsBox}>
        {file_uploaded.description[0].documents?.map((doc: any) => (
          <Grid item xs={12} sm={6} md={6} key={doc.blob_id}>
            {this.renderUploadedFiles(doc)}
          </Grid>
        ))
        }
      </Grid>
      <Box className={classes.timeWrapper}>
        <Typography className={classes.timeStyle} >
           <TimeOnly date={file_uploaded.created_at} />
        </Typography>
      </Box>
    </Grid>
  }

  renderSentMessage = (msg_sent: any) => {
    const { classes } = this.props;
    return <Grid container className={classes.displayDetailGrid}>
      <Box className={classes.displayDetailsMainBox}>
        <Box className={classes.displayVerifyBox}>
          <img className={classes.milestoneTitleImage} src={messageBlueIcon} />
          <Typography className={classes.timelineContentWorkReview}>{msg_sent.title}</Typography>
        </Box>
        {this.renderUser(classes, msg_sent)}
      </Box>

      <Divider className={classes.divider}></Divider>

      {this.renderMilestoneDescription(classes, msg_sent)}
    </Grid>
  }

  renderReceivedMessage = (msg_rec: any) => {
    const { classes } = this.props;
    return <Grid container className={classes.displayDetailGrid}>
      <Box className={classes.displayDetailsMainBox}>
        <Box className={classes.displayVerifyBox}>
          <img className={classes.milestoneTitleImage} src={messageBlueIcon} />
          <Typography className={classes.timelineContentWorkReview}>{msg_rec.title}</Typography>
        </Box>
        {this.renderUser(classes, msg_rec)}
      </Box>
      <Divider className={classes.divider}></Divider>
      {this.renderMilestoneDescription(classes, msg_rec)}
    </Grid>
  }


  getRevisonRequestDescription=(request_revision: any)=>{
    const { milestoneFlags } = this.state;
    let description = request_revision.flag_title === milestoneFlags[5] ? request_revision.description[0].revision_details :
    request_revision.description.html;
    if(request_revision.flag_title===milestoneFlags[20]){
      description=request_revision.description.reason;
    }
    return description;
  }

  renderRevisionRequested = (request_revision: any) => {
    const { milestoneFlags } = this.state
    let title = request_revision.title
    const arbitrationStatus = request_revision.description.evidence_status;
    if (request_revision.flag_title !== milestoneFlags[5]) {
      title = `${request_revision.description.milestone} - ${request_revision.title}`
    }
   
    let files = request_revision.flag_title === milestoneFlags[5] ? request_revision.description[0].images :
      request_revision.description.dispute_documents
    let milestoneInformation = request_revision.flag_title === milestoneFlags[5] ? request_revision.description[0].deadline :
      request_revision.description.html_dispute_amount
    let isMulticurrencyAmount=false;
    
    if(request_revision.flag_title===milestoneFlags[18] || request_revision.flag_title===milestoneFlags[20]){
      const multiHTML=getSponsorMulitCurrencyHTML(request_revision.description.html_dispute_amount,request_revision?.currency_conversion);
      milestoneInformation= multiHTML;
      isMulticurrencyAmount=true;
    }
    if(request_revision.flag_title===milestoneFlags[20]){
      files=request_revision.description.rejection_documents;
    }
    const description=this.getRevisonRequestDescription(request_revision);
    const { classes } = this.props;
    return <Grid container className={classes.displayDetailGrid}>
      <Box className={classes.displayDetailsMainBox}>
        <Box className={classes.displayVerifyBox}>
          <img className={classes.milestoneTitleImage} src={milestoneCreatedIcon} />
          <Typography className={classes.timelineContentWorkReview}>{title}</Typography>
        </Box>
        <Box className={classes.displayVerifyBox}>
          <Avatar src={request_revision.profile_pic.url} className={classes.avatarStyle} />
          <Typography className={classes.timelineAvatarText}>{request_revision.user_name}</Typography>
        </Box>
      </Box>
      <Divider className={classes.divider}></Divider>
      <Typography
        className={`${classes.timelineContentDesc} resTimelineContent `}
        dangerouslySetInnerHTML={{ __html: description }}
        style={isMulticurrencyAmount?{
          marginBottom: "0",
          display:"flex",
          alignItems:"center",
          gap:"2px"
        }:{ marginBottom: "0" }} />
      <Divider className={classes.divider}></Divider>
      <Grid container className={classes.downloadDetailsBox}>
        {files?.map((file: any) => (
          <Grid item xs={12} sm={6} md={6} key={file.blob_id}>
            {this.renderUploadedFiles(file)}
          </Grid>
        ))
        }
      </Grid>
      {
      (request_revision.description?.dispute_documents?.length > 0 || files?.length > 0 ) &&
      <Divider className={classes.divider}></Divider>
      }
      <style>
            {`
              @media(max-width: 767px) {
                .resEvidenceBtn {
                  flex-direction: column;
                  gap: 15px;
                }
              }
            `}
          </style>
      <Typography
        style={isMulticurrencyAmount?{
          display:"flex",
          gap:"2px"
        }:{  }}
        className={`${classes.timelineContentDesc} ${isMulticurrencyAmount?"scheduling_history_description":""} resTimelineContent`}
        dangerouslySetInnerHTML={{ __html: milestoneInformation }} />
     
      {request_revision.title === milestoneFlags[20] && (
          
  
          <Box className="resEvidenceBtn" display={"flex"} justifyContent={"space-between"}>
            <Typography
              className={classes.timelineContentDesc}>
              {this.getArbitrationTitle(arbitrationStatus)}</Typography>
             {(!arbitrationStatus || arbitrationStatus == configJSON.submittedTxt ) && (
              <Box style={{ flex: "0 0 30%" }}>
                <Button className={classes.shareYourFeedBackReview}
                  onClick={() => this.handleStartArbitrationProcess(request_revision.description.dispute_id)}
                  variant="contained"
                   disabled={request_revision.description.evidence_status !== null || this.state.lancerData.projectStatus===5}
                  data-test-id="submitEvidence"
                  >
                  {configJSON.submitEvidenceAndPay} 
                
                </Button>

              </Box>
            )}
          </Box>
     
      )}
           <Box className={classes.timeWrapper}>
            <Typography className={classes.timeStyle} >
              <TimeOnly date={request_revision.created_at} />
            </Typography>
          </Box>
    </Grid>
  }

  renderProjectCompleted = (isRated: boolean) => {
    const { classes } = this.props
    return (
      <Grid container className={classes.displayDetailGrid}>
        <Box className={classes.displayDetailsMainBox} data-testid="projectCompleted">
          <Box className={classes.displayVerifyBox}>
            <CheckCircleIcon className={classes.milestoneTitleImage} />
            <Typography className={classes.timelineContentWorkReviews}>{configJSON.projectCompleted}</Typography>
          </Box>
        </Box>
        <Divider className={classes.divider}></Divider>
        <Button
          variant="contained"
          onClick={() => this.handleShowRatingModal("rating")}
          className={classes.shareYourFeedBackReview}
          style={{
            fontWeight: "bold",
            textTransform: "none"
          }}
          data-testId="shareFeedback"
          disabled={isRated}
        >
          {configJSON.shareFeedback}
        </Button>
      </Grid>
    )
  }

  renderProjectClosureRequested = (projectClosureRequested: any) => {
    const { classes } = this.props
    return (
      <Grid container className={classes.displayDetailGrid}>
        <Box className={classes.displayDetailsMainBox} data-testid="projectCompleted">
          <Box className={classes.displayVerifyBox}>
            <img className={classes.milestoneTitleImage} src={milestoneCreatedIcon} />
            <Typography className={classes.timelineContentWorkReview}>
              {projectClosureRequested.flag_title == this.state.milestoneFlags[9] ? configJSON.projectClosureRequested : configJSON.projectClosureApproved}
            </Typography>
          </Box>
          <Box className={classes.displayVerifyBox}>
            <Avatar src={projectClosureRequested.profile_pic.url} className={classes.avatarStyle} />
            <Typography className={classes.timelineAvatarText}>{projectClosureRequested.user_name}</Typography>
          </Box>
        </Box>
        <Divider className={classes.divider}></Divider>
        <Typography dangerouslySetInnerHTML={{ __html: projectClosureRequested.description[0] }} />

        <Box className={classes.timeWrapper}>
          <Typography className={classes.timeStyle} >
            <TimeOnly date={projectClosureRequested.created_at} />
          </Typography>
        </Box>

      </Grid>
    )
  }

  renderProjectClosureRejected = (projectClosureRejected: any) => {
    const { classes } = this.props
    const disputeStatus = projectClosureRejected.dispute_request_status
    let disputeMilestone = false
    if (projectClosureRejected.flag_title == this.state.milestoneFlags[12] || projectClosureRejected.flag_title == this.state.milestoneFlags[14]) {
      disputeMilestone = true
    }
    let description = disputeMilestone ? projectClosureRejected.description[0]?.description_html : projectClosureRejected.description[0]
    if (projectClosureRejected.flag_title === this.state.milestoneFlags[14]) {
      description = projectClosureRejected.description[0].html
    }
    const files = projectClosureRejected.flag_title == this.state.milestoneFlags[12] ? projectClosureRejected.description[0].images_html :
      projectClosureRejected.description[0]?.image

    const disputeInfo = projectClosureRejected.flag_title === this.state.milestoneFlags[12] ? projectClosureRejected.description[0].html :
      projectClosureRejected.description[0]?.reason_html

    return (
      <Grid container className={classes.displayDetailGrid}>
        <Box className={classes.displayDetailsMainBox}>
          <Box className={classes.displayVerifyBox}>
            <img className={classes.milestoneTitleImage} src={milestoneCreatedIcon} />
            <Typography className={classes.timelineContentWorkReview}>
              {projectClosureRejected.flag_title}
            </Typography>
          </Box>
          <Box className={classes.displayVerifyBox}>
            <Avatar src={projectClosureRejected.profile_pic.url} className={classes.avatarStyle} />
            <Typography className={classes.timelineAvatarText}>{projectClosureRejected.user_name}</Typography>
          </Box>
        </Box>
        <Divider className={classes.divider}></Divider>
        <Typography dangerouslySetInnerHTML={{ __html: description }} />
        {projectClosureRejected.flag_title == this.state.milestoneFlags[10] && (
          <>
            <Divider className={classes.divider} style={{ marginTop: "0px" }}></Divider>
            <Box display={"flex"}>
              <Button
                onClick={() => this.props.navigation.navigate("Chat")}
                className={classes.shareYourFeedBackReview}
                style={{
                  fontWeight: "bold",
                  textTransform: "none",
                  padding: "4px 20px",
                  marginRight: "9px"
                }}
                data-testId="message"
              >
                Message
              </Button>
              <Button
                disabled={disputeStatus}
                className={classes.shareYourFeedBackReview}
                style={{
                  padding: "4px 10px",
                  fontWeight: "bold",
                  textTransform: "none",
                  background: "#fff",
                  color: "#222",
                  opacity: disputeStatus && "0.15",

                }}
                data-testId="raiseDispute"
                onClick={() => this.props.navigation.navigate("Disputes", { projectId: this.state.projectId })}
              >
                Raise a Dispute
              </Button>

            </Box>
          </>
        )}
        {disputeMilestone && (
          <>
            {files?.length > 0 && (
              <>
                <Divider className={classes.divider} style={{ marginTop: 0 }}></Divider>
                <Grid container className={classes.downloadDetailsBox}>
                  {files?.map((doc: any) => (
                    <Grid item xs={6} md={6} key={doc.blob_id}>
                      {this.renderUploadedFiles(doc)}
                    </Grid>

                  ))
                  }
                </Grid>
              </>
            )}
            <Divider className={classes.divider}></Divider>
            <Typography dangerouslySetInnerHTML={{ __html: disputeInfo }} />
             {/* --------------------- submit evidence button ----------------------- */}
             {projectClosureRejected.flag_title == this.state.milestoneFlags[14] &&   
             <Box display={"flex"} justifyContent={"space-between"}>
             <Typography
              className={classes.timelineContentDesc}>
              {this.getArbitrationTitle(projectClosureRejected.description[0].evidence_status)}</Typography>

              <Box 
              className={classes.arbitrationBoxStyle}
              >
                <Button className={classes.shareYourFeedBackReview}
                   onClick={() => this.handleStartArbitrationProcess(projectClosureRejected.description[0].raise_dispute_id)}
                  variant="contained"
                   disabled={projectClosureRejected.description[0].evidence_status !== null || this.state.lancerData.projectStatus===5}
                  data-test-id="submitProjectEvidence"
                  >
                  {configJSON.submitEvidenceAndPay} 
                
                </Button>

              </Box>
      
          </Box>
             }
          </>
        )}
        <Box className={classes.timeWrapper}>
          <Typography className={classes.timeStyle} >
            <TimeOnly date={projectClosureRejected.created_at} />
          </Typography>
        </Box>

      </Grid>
    )
  }

  renderRefundMilestones = (refundMilestone: any) => {
    const { classes } = this.props
    const refundDescription = refundMilestone.flag_title === this.state.milestoneFlags[16] ?
    getSponsorMulitCurrencyHTML(refundMilestone.description.html_refund_amount,refundMilestone?.currency_conversion)
       : refundMilestone.description.html
    const isRefundRequestResolved=refundMilestone.flag_title === this.state.milestoneFlags[16];
    return (
      <Grid container className={classes.displayDetailGrid}>
        <Box className={classes.displayDetailsMainBox}>
          <Box className={classes.displayVerifyBox}>
            <img className={classes.milestoneTitleImage} src={milestoneCreatedIcon} />
            <Typography className={classes.timelineContentWorkReview}>
              {`${refundMilestone.description.milestone}- ${refundMilestone.title}`}
            </Typography>
          </Box>
          <Box className={classes.displayVerifyBox}>
            <Avatar src={refundMilestone.profile_pic.url} className={classes.avatarStyle} />
            <Typography className={classes.timelineAvatarText}>{refundMilestone.user_name}</Typography>
          </Box>
        </Box>
        <Divider className={classes.divider}></Divider>
        <Typography 
          style={isRefundRequestResolved?{
            display:"flex",
            alignItems:"center",
            gap:"2px"
          }:{}}
          className={`${isRefundRequestResolved? "scheduling_history_description":""}  resTimelineContent`} 
          dangerouslySetInnerHTML={{ __html: refundDescription }} />


        <Box className={classes.timeWrapper}>
          <Typography className={classes.timeStyle} >
            <TimeOnly date={refundMilestone.created_at} />
          </Typography>
        </Box>

      </Grid>

    )
  }
  renderUser = (classes: any, milestone: any) => {
    return (
      <Box className={classes.displayVerifyBox}>
        <Avatar src={milestone.profile_pic.url} className={classes.avatarStyle} />
        <Typography className={classes.timelineAvatarText}>{milestone.user_name}</Typography>
      </Box>
    )
  }
  renderMilestoneDescription = (classes: any, msg_sent: any) => {
    return (
      <>
       {
       ( msg_sent.flag_title=== this.state.milestoneFlags[0] 
        || msg_sent.flag_title===this.state.milestoneFlags[1] 
        || msg_sent.flag_title===this.state.milestoneFlags[8]
        || msg_sent.flag_title===this.state.milestoneFlags[13]
        || msg_sent.flag_title===this.state.milestoneFlags[21]
        ) && 
        <Typography 
          style={{
            display:"flex",
            gap:"2px",
          }}
          className={`${classes.timelineContentDesc}  scheduling_history_description resTimelineContent`} 
          dangerouslySetInnerHTML={{ __html:getSponsorMulitCurrencyHTML(msg_sent?.description,msg_sent?.currency_conversion) }} 
        />
      }
       {
       msg_sent.flag_title===this.state.milestoneFlags[19] && 
        <Typography className={classes.timelineContentDesc} dangerouslySetInnerHTML={{ __html: msg_sent.description.html }} />
      }
        <style>
          {`
            .resTimelineContent > div {
              display: inline-flex !important;
              flex-wrap: wrap;
            }
            @media(max-width: 575px) {
              .resTimelineContent {
                display: block !important;
                font-size: 12px;
              }
            }
          `}
        </style>
        <Box className={classes.timeWrapper}>
          <Typography className={classes.timeStyle} >
             <TimeOnly date={msg_sent.created_at} />
          </Typography>
        </Box>
      </>
    )
  }

  renderTimelineDate = (date: any) => {
    const { classes } = this.props
    return (
      <Box className={classes.displayDetailBox}>
        <Typography className={classes.timelineContentHeader}>
          {moment(date).format('MMMM')}, {moment(date).format('YYYY')}
        </Typography>
      </Box>
    )
  }

  renderTimelineSeperator = (date: any) => {
    const { classes } = this.props
    return (
      <TimelineSeparator>
        <TimelineDot className={classes.timelineDetailDotSubmit}>
          {date.substr(8, 2)}
        </TimelineDot>
        <TimelineConnector className={classes.timelineDetailConectorSubmit} />
      </TimelineSeparator>
    )
  }

  renderUploadedFiles = (doc: any) => {
    const { classes } = this.props
    return (
      <Box className={classes.downloadBox}>
        <Box className={classes.downloadInnerBox}>
          <img className={classes.milestoneGridImage} src={document} />
          <Typography className={classes.downloadFileName}>{this.convertFileName(doc.name)}</Typography>
          <Typography data-testid="file_size"
            className={classes.downloadFileSize}>
            {this.onConvertFileSize(doc.size)}
          </Typography>
        </Box>
        <Box>
          <img
            src={download}
            className={classes.deleteIconStyle1}
            alt="download1"
            onClick={this.handleDownloadFile.bind(this, doc.url, doc.name,"","")}
          />
        </Box>
      </Box>
    )
  }

  renderTextInsideDropzone = () => {
    const { classes } = this.props
    return (
      <Box
        className={classes.dropZoneStyle}
      >
        <Typography
          className={classes.uploadFileTxt}
        >
          {configJSON.uploadFileTxt}
        </Typography>
        <Typography
          className={classes.maxFileSizeTxt}
        >
          {configJSON.maxFileSizeTxt}
        </Typography>
      </Box>
    )
  }

  renderDisputeMilestones = (milestone: any) => {
    if (milestone.flag_title === this.state.milestoneFlags[14]) {
      return this.renderProjectClosureRejected(milestone)
    }
    else if (milestone.flag_title === this.state.milestoneFlags[12]) {
      return this.renderProjectClosureRejected(milestone)
    }
    else if (milestone.flag_title === this.state.milestoneFlags[13]) {
      return this.renderAcceptedBid(milestone)
    }
    else if (milestone.flag_title == this.state.milestoneFlags[8]) {
      return this.renderAcceptedBid(milestone)
    }
    else if (milestone.flag_title === this.state.milestoneFlags[9]) {
      return this.renderProjectClosureRequested(milestone)
    }
    else if (milestone.flag_title === this.state.milestoneFlags[10]) {
      return this.renderProjectClosureRejected(milestone)
    }
    else if (milestone.flag_title === this.state.milestoneFlags[11]) {
      return this.renderProjectClosureRequested(milestone)
    }
    else if (milestone.flag_title === this.state.milestoneFlags[15]) {
      return this.renderRefundMilestones(milestone)
    }
    else if (milestone.flag_title === this.state.milestoneFlags[16]) {
      return this.renderRefundMilestones(milestone)
    }
    else if (milestone.flag_title === this.state.milestoneFlags[17]) {
      return this.renderRefundMilestones(milestone)
    }
    else if (milestone.flag_title === this.state.milestoneFlags[18]) {
      return this.renderRevisionRequested(milestone)
    }
    else if (milestone.flag_title === this.state.milestoneFlags[19]) {
      return this.renderAcceptedBid(milestone)
    }
    else if (milestone.flag_title === this.state.milestoneFlags[20]) {
      return this.renderRevisionRequested(milestone)
    }
    else if (milestone.flag_title === this.state.milestoneFlags[21]) {
      return this.renderAcceptedBid(milestone)
    }

  }

  renderEvidenceDialogBox = () => {
    const { classes } = this.props;
    const { arbitrationDialog, arbitrationFiles} = this.state

    return  <Dialog
    open={arbitrationDialog}
    maxWidth="sm"
    PaperProps={{
      style: {
        borderRadius: '14px',
        minWidth: "500",
        minHeight: "350px"
      },
    }}>

     <Box display={"flex"} justifyContent={"space-between"}>
      <DialogTitle>
        <Typography className={classes.modalHeadings}>{configJSON.submitEvidenceTxt}</Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => this.handleShowRatingModal("arbitration")}
        data-test-id="dialogheading"
      >
        <HighlightOff />
      </IconButton>
    </Box>
    <DialogContent dividers>
      <Box p={1} maxWidth={500}>
        <Box className={classes.validationBox}>
            <Typography className={classes.timelineContentWorkReview}>
              {configJSON.attachmentsTxt}
            </Typography>
            <Box> 
                {this.state.evidenceFileError !== "" && 
                <Typography className={classes.validationErrorTxt}> {this.state.evidenceFileError} </Typography>
                }
            </Box>
         </Box>
        <Dropzone onDrop={this.handleFileDrop} data-test-id="uploadFiles">
          {({ getRootProps, getInputProps }) => (
            <section className="container">
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                {this.renderTextInsideDropzone()}
              </div>
            </section>
          )}
        </Dropzone>
        {this.state.showReasonValidation && (
                <Typography className={classes.validationSelectFileErrorTxt}>
                 {configJSON.selectFileTxt}
                </Typography>
              )}

        <Grid container spacing={1}
          className={classes.downloadDetailsBox}>
          {arbitrationFiles.map((doc: any, ind: number) => (
            <Grid item xs={arbitrationFiles.length > 1 ? 6 : 8} key={doc.blob_id}>
              <Box className={classes.downloadBox}>
                <Box className={classes.downloadInnerBox}>
                  <img className={classes.milestoneGridImage} src={document} />
                  <Typography className={classes.downloadFileName}>{this.convertFileName(doc.name)}</Typography>
                  <Typography data-testid="file_size"
                    className={classes.downloadFileSize}>
                    {this.onConvertFileSize(doc.size)}
                  </Typography>
                </Box>
                <Box paddingLeft={"15px"}>
                  <img
                    src={deleteFile}
                    className={classes.deleteIconStyle1}
                    alt="download1"
                    onClick={()=>this.handleDeleteFile(this,ind)}
                    data-test-id="delete"
                  />
                </Box>
              </Box>
            </Grid>
          ))
          }
        </Grid>
      </Box>

    </DialogContent>
    <DialogActions>
      <Box className={classes.modalButtons}>
        <Button
          className={classes.onHoldBtnStyle}
          onClick={() => this.handleShowRatingModal("arbitration")}
          data-test-id="cancelbutton"
        >
          {configJSON.cancelBtnTxt}
        </Button>
        <Button
          className={classes.closeBtnStyle}
          onClick={this.handleSubmitEvidence}
          data-test-id="submitEvidenceRequest"
          disabled={this.state.arbitrationLoader}
        >
           {
           this.state.arbitrationLoader ?
                            <CircularProgress 
                            data-testid={'arbitrationLoader'}
                             size={24} />
                          : this.state.currencysymbol +  this.state.arbitrationAmount
            }
        </Button>
      </Box>
    </DialogActions>

  </Dialog>
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    let isRated = false
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {this.state.detailsLoader ? (
          <Box className={classes.detailLoaderStyle}>
            <CircularProgress size={24} style={{ color: 'grey' }} />
          </Box>
        ) : (
          <>
            {Object.keys(this.state.milestoneDetails).length !== 0 ? (
              <>
                {Object.keys(this.state.milestoneDetails)?.map((date: any, index: number) => (
                  <React.Fragment key={date}>
                    <Timeline className={classes.timelineDetailStyle}>
                      <TimelineItem>
                        <TimelineOppositeContent style={{ display: 'none' }} />
                        {this.renderTimelineSeperator(date)}
                        <TimelineContent>
                          {this.renderTimelineDate(date)}
                          {this.state.milestoneDetails[date].map((item: any) => {
                            isRated = item.is_rated
                            return (
                              <React.Fragment key={item}>
                                {item.flag_title == this.state.milestoneFlags[0] && this.renderAcceptedBid(item)}
                                {item.flag_title == this.state.milestoneFlags[1] && this.renderCreatedMilestone(item)}
                                {item.flag_title == this.state.milestoneFlags[2] && this.renderSentMessage(item)}
                                {item.flag_title == this.state.milestoneFlags[3] && this.renderReceivedMessage(item)}
                                {item.flag_title == this.state.milestoneFlags[4] && this.renderFileUploadedMileStone(item)}
                                {item.flag_title == this.state.milestoneFlags[5] && this.renderRevisionRequested(item)}
                                {item.flag_title == this.state.milestoneFlags[7] && this.renderAcceptedBid(item)}
                                {item.flag_title == configJSON.projectCompleted && this.renderProjectCompleted(isRated)}
                                {this.renderDisputeMilestones(item)}

                              </React.Fragment>
                            )
                          })}
                        </TimelineContent>
                      </TimelineItem>
                    </Timeline>
                  </React.Fragment>
                ))}


                <Modal
                  open={this.state.showRatingModal}
                >
                  <Box
                    flexDirection={'column'}
                    className={classes.modalStyles}
                    style={{ position: 'absolute' }}
                    data-testid='modalContainer'
                  >
                    <Box className={classes.headingContainers}>
                      <Typography className={classes.modalHeadings}>{configJSON.rateLancers}</Typography>
                      <IconButton
                        aria-label="close"
                        onClick={() => this.handleShowRatingModal("rating")}
                        data-test-id="modalHeading"
                      >
                        <HighlightOff />
                      </IconButton>
                    </Box>
                    <Box
                      flexDirection={'column'}
                      className={classes.ratingContainer}
                    >
                      <Typography className={classes.rateLancers} style={{ marginBottom: '0.5rem' }} >
                        {configJSON.contentHeading}
                      </Typography>
                      <StarRatings
                        rating={this.state.rating}
                        changeRating={this.handleRatingChange}
                        starDimension="40px"
                        starSpacing="20px"
                        starRatedColor="orange"
                        starEmptyColor="gray"
                        starHoverColor="orange"
                        data-test-id="ratingIcons" />
                      <Box style={{ display: "flex", padding: "0.5rem 0" }}>
                        <Typography
                          className={classes.ratingLabelText}
                          style={{ margin: "0 1rem 0 1rem" }}
                        >
                          {configJSON.poor}
                        </Typography>
                        <Typography
                          className={classes.ratingLabelText}
                          style={{ margin: '0 1rem 0 1.4rem' }}
                        >
                          {configJSON.average}
                        </Typography>
                        <Typography
                          className={classes.ratingLabelText}
                          style={{ margin: '0 1rem 0 1rem' }}
                        >
                          {configJSON.good}
                        </Typography>
                        <Typography
                          className={classes.ratingLabelText}
                          style={{ margin: '0 1rem 0 1rem' }}
                        >
                          {configJSON.veryGood}
                        </Typography>
                        <Typography
                          className={classes.ratingLabelText}
                        >
                          {configJSON.excellent}
                        </Typography>
                      </Box>

                    </Box>
                    {this.state.ratingError !== "" && 
                        <Typography className={classes.validationErrorTxt}>
                          {this.state.ratingError} 
                          </Typography>
                    }
                    <Box p={2}>
                      <InputLabel className={classes.inputFeedbackStyle}>{configJSON.feedbackLabel}</InputLabel>
                      <StyledTextFeedbackField
                        data-testid="anyfeedback"
                        name="name"
                        fullWidth
                        onChange={this.handleReviewChange}
                        placeholder={configJSON.feedbackPlaceholder}
                      />
                    </Box>
                    <Box className={classes.modalButtons}>
                      <Button
                        className={classes.onHoldBtnStyle}
                        onClick={() => this.handleShowRatingModal("rating")}
                        data-test-id="cancelRating"
                      >
                        {configJSON.cancelBtnTxt}
                      </Button>
                      <Button
                        className={classes.closeBtnStyle}
                        onClick={this.handleSponsorValidateRatings}
                        disabled={this.state.ratingLoader}
                        data-testid="submitSponsorRatings"
                      >
                           {this.state.ratingLoader?
                          <CircularProgress 
                           data-testid='ratingsLoader' 
                           size={24} 
                           />
                         : configJSON.sendBtnTxt
                         } 
                      </Button>
                    </Box>
                  </Box>
                </Modal>
                {this.renderEvidenceDialogBox()}
              </>
            ) : (
              <Typography className={classes.errorNoDetail}>{configJSON.errorNoDetailsForMilestones}
              </Typography>
            )}
          </>
        )}
      </ThemeProvider>



      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  timelineDetailDotSubmit: {
    backgroundColor: '#00a7a2',
    height: '25px',
    width: '25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: 'none',
    color: '#ffffff',
    fontSize: '14px',
    fontWeight: 700,
    marginLeft: '0',
    marginRight: '0',
  },

  timelineContentHeaders: {
    color: '#222222',
    fontSize: '16px',
    fontWeight: 700,
    marginRight: '0.7vw'
  },

  timelineContentDesc: {
    color: '#8083a3',
    fontSize: '14px',
    fontFamily: "Inter",
    fontWeight: 500,
    whiteSpace: 'pre-wrap' as const,
    wordBreak: 'break-word' as const,
  },

  displayDetailBox: {
    display: 'flex',
    alignItems: 'center',
    margin: '16px 0',

  },
  displayVerifyBox: {
    display: 'flex',
    alignItems: 'center',
  },

  displayDetailGrid: {
    display: 'flex',
    alignItems: 'center',
    padding: '2vw',
    borderRadius: '10px',
    backgroundColor: '#f1f1f1',
    marginBottom: '40px',
  },

  milestoneGridImage: {
    width: '20px',
    height: '21px',
    marginRight: '1vw',
  },
  milestoneTitleImage: {
    color: '#00a7a2',
    width: '24px',
    height: '24px',
    marginRight: '0.6vw',
  },

  timelineDetailConectorSubmit: {
    borderLeft: '1px dashed #00a7a2',
    width: '0',
    backgroundColor: '#fff',
  },
  divider: {
    margin: '1.5vw 0',
    width: '100%'
  },
  timelineAvatarText: {
    color: '#8083a3',
    fontSize: '12px',
    fontWeight: 600,
    fontFamily: "Inter",
  },
  timelineContentWorkReview: {
    color: '#222222',
    fontSize: '14px',
    fontWeight: 700,
    fontFamily: "Inter"
  },

  timelineDetailStyle: {
    padding: '0px 16px',
  },
  avatarStyle: {
    width: '20px',
    height: '20px',
    marginRight: '1vw',
  },
  timeWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: '0.7vw',
  },
  timeStyle: {
    color: "#8083a3",
    fontSize: '10px',
    fontWeight: 500,
  },
  displayDetailsMainBox: {
    width: '100%',
    display: 'flex',
    alignItems: "center",
    justifyContent: 'space-between',
    flexWrap: "wrap" as const,
    gap: "8px"
  },
  downloadDetailsBox: {
    display: 'flex',
    alignItems: 'center',
  },
  downloadBox: {
    marginBottom: '1vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1vw',
    borderRadius: '10px',
    border: '1px solid #e4e6e8',
    backgroundColor: '#ffffff',
    width: '90%',
    boxSizing: 'border-box' as const,
    "@media(max-width: 599px)": {
      width: "100%"
    }
  },
  downloadInnerBox: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: "wrap" as const,
  },

  downloadFileName: {
    color: '#8083a3',
    fontFamily: "Inter",
    fontSize: '12px',
    fontWeight: 600,
    marginRight: '0.5vw',
  },
  downloadFileSize: {
    color: 'rgba(128,131,163,0.76)',
    fontSize: '10px',
    fontWeight: 500,
  },
  downloadImgStyle: {
    width: '26px',
    height: '26px',
    marginRight: '1vw',
  },
  deleteIconStyle1: {
    borderRadius: "50%",
    border: "1px solid rgba(228, 230, 232, 0.60)",
    background: "#FFF",
    cursor: 'pointer'
  },
  detailLoaderStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  errorNoDetail: {
    color: '#222',
    padding: "2vw",
    fontSize: '16px',
    fontWeight: 500,
  },
  modalStyles: {
    display: "flex",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: '0px 3px 4px rgba(153,155,168,0.25)',
    background: '#ffffff',
    borderRadius: '16px',
  },
  headingContainers: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #8083a3',
    padding: '0.5vw 0 0 1vw',
  },
  modalHeadings: {
    color: '#222222',
    fontSize: '16px',
    fontWeight: 700,
    fontFamily: 'inter',
  },
  rateLancers: {
    color: '#ffffff',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
  },
  ratingContainer: {
    backgroundColor: '#00a7a2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.5rem 1.5rem'
  },
  ratingLabelText: {
    color: '#ffffff',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,

  },
  label: {
    marginBottom: "0px",
  },
  inputStyle: {
    color: "#222",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    "&::placeholder": {
      color: "#8083A3",
      fontSize: "12px",
      fontWeight: 500,
    },
    "& .MuiInputBase-input": {
      padding: "6px 0 0"
    },

  },
  modalButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '1vw',
  },
  onHoldBtnStyle: {
    borderRadius: 23,
    background: "#F2F2F2",
    color: "#222",
    textAlign: "center" as "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    textTransform: "capitalize" as "capitalize",
    padding: "5 35",
  },
  closeBtnStyle: {
    borderRadius: 23,
    backgroundColor: "#00A7A2",
    color: "#fff",
    textAlign: "center" as "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    textTransform: "capitalize" as "capitalize",
    padding: "5 35",
    "&:hover": {
      backgroundColor: "#00A7A2",
    },
  },
  shareYourFeedBackReview: {
    alignItems: "center",
    backgroundColor: "#00a7a2",
    color: "#fff",
    borderRadius: "30px",
    fontSize: "14px",
    cursor: "pointer",
    padding: '0.5vw 1vw',
    display: "flex",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#00A7A2",
    }
  } as const,
  timelineContentsHeader: {
    color: '#222222',
    fontSize: '16px',
    fontWeight: 700,
    marginRight: '0.7vw'
  },
  dropZoneStyle: {
    borderRadius: "10px",
    border: "1.5px dashed #00A7A2",
    background:
      "linear-gradient(0deg, rgba(0, 167, 162, 0.04) 0%, rgba(0, 167, 162, 0.04) 100%), #FFF",
    padding: "20px",
    margin: "15px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column" as "column",
    rowGap: 5,
    cursor: "pointer",
  },
  uploadFileTxt: {
    color: "#fff",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 700,
    borderRadius: "20px",
    height: '18px',
    width: '80px',
    padding: '8px 14px',
    backgroundColor: "#00A7A2",
  },
  maxFileSizeTxt: {
    color: "#8083A3",
    fontSize: "10px",
    fontFamily: "Inter",
    fontWeight: 500,
  },
  validationBox:{
    display:"flex",
    alignItems:'center',
    justifyContent:'space-between'
  },
  validationErrorTxt:{
    padding:'0 16px',
    marginTop:'10px',
     fontSize:'10px',
     color:"red"
    },
    validationSelectFileErrorTxt:{
       fontSize:'10px',
       color:"red"
      },
      inputFeedbackStyle: {
        color: "#8083A3",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
      },
  arbitrationBoxStyle:{
    flex: "0 0 30%" 
  }
};

export const ProjectDetailsPageStyles = withStyles(webStyle)(ProjectDetails);
// Customizable Area End