import React from "react";

// Customizable Area Start
import {
    Button,
    TextField,
    Grid,
    InputAdornment,
    FormControl, InputLabel, MenuItem, Select
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SearchIcon from "@material-ui/icons/Search";

import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import ClearIcon from "@material-ui/icons/Clear";

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CircularProgress from "@material-ui/core/CircularProgress";
import GenericCardFilter from "../../../components/src/GenericCardFilter";
import SearchHireFreeLancerController, { Props } from "./SearchHireFreelancerController.web";
import DashboardFooter from "../../dashboard/src/DashboardFooter.web";
import FreelancerHeader from "../../dashboard/src/FreelancerHeader.web";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GenericTab from '../../../components/src/GenericTab';
import "../assets/css/Search.css";
import CardList from "../../../components/src/CardList.web";

// Customizable Area End

// Customizable Area Start

// Customizable Area End

export default class SearchHireFreeLancer extends SearchHireFreeLancerController {
    constructor(props: Props) {
        super(props);

        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const {
          proposalSearchQuery,
          proposalCards,
          proposalPageNumber,
          proposalActiveTab,
          proposalLoading,
          proposalTotalCards,
          proposalSelectedOption,
          proposalOptions,
          filterOptions,
          freeLancerListSelectedOption,
          displayFilterSortCard,
          filterActiveTab,
          tabLables,
          proposalVisibleCards,
          error
        } = this.state;
      
        return (
          <>
            <FreelancerHeader navigation={undefined} history={undefined} location={undefined} />
      
            {error ? (
              <div>{error}</div>
            ) : (
              <div style={{ marginLeft: "10%", marginRight: "10%" }}>
                <Grid container spacing={2}>
                  <Grid item xs={3} sm={3} md={3} lg={1}>
                    <Button
                      data-test-id="backButtonId"
                      className="freelancer-search-secondary-button"
                      variant="contained"
                      color="default"
                      startIcon={<ArrowBackIcon />}
                      onClick={this.handleProposalBack}
                      style={{
                        backgroundColor: "#F2F2F2",
                        borderRadius: "20px",
                        height: "45px",
                        width: "85px",
                        margin: "0 9px"
                      }}
                    >
                      Back
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={7}
                    sm={7}
                    md={7}
                    lg={7}
                    style={{
                      fontWeight: "bold",
                      color: "#000000",
                      fontSize: "x-large",
                      marginLeft: "20px"
                    }}
                  >
                    Logo Design for Insurance Company
                  </Grid>
                </Grid>
      
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  style={{ margin: "20px 0" }}
                >
                  <Grid item xs={12} sm={10} lg={8} md={8}>
                    <TextField
                      data-test-id="searchInputId"
                      onChange={this.handleProposalSearchInputChange}
                      variant="outlined"
                      fullWidth
                      placeholder="Search Proposal"
                      value={proposalSearchQuery}
                      style={{ width: "100%", fontWeight: 'normal', fontSize: '14px' }}
                      className="input-box"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {proposalSearchQuery && (
                              <button
                                onClick={this.handleProposalClearSearch}
                                data-test-id="clearButtonId"
                                style={{
                                  backgroundColor: "#00A7A2",
                                  borderRadius: "50%",
                                  width: "40px",
                                  height: "40px",
                                  border: "none",
                                  color: "#fff",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginRight: "5px",
                                  cursor: 'pointer'
                                }}
                              >
                                <ClearIcon />
                              </button>
                            )}
                            <button
                              onClick={this.handleProposalSearchClick}
                              data-test-id="searchButtonId"
                              disabled={proposalLoading}
                              style={{
                                backgroundColor: "#00A7A2",
                                borderRadius: "50%",
                                width: "40px",
                                height: "40px",
                                border: "none",
                                color: "#fff",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: proposalLoading ? 'notAllowed' : 'pointer',
                                opacity: proposalLoading ? 0.5 : 1
                              }}
                            >
                              <SearchIcon />
                            </button>
                          </InputAdornment>
                        ),
                        style: { borderRadius: "35px", paddingRight: "5px" }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2} md={2} lg={2}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <InputLabel id="select-label" style={{ marginBottom: '0px' }}>
                        Sort:
                      </InputLabel>
                      <FormControl style={{ minWidth: 120 }}>
                        <Select
                          data-test-id="optionButtonId"
                          style={{ width: '100%' }}
                          fullWidth
                          labelId="select-label"
                          value={proposalSelectedOption}
                          onChange={this.handleProposalOptionsChange}
                          displayEmpty
                          disableUnderline
                          inputProps={{ 'aria-label': 'Without label' }}
                          IconComponent={() => (
                            <div style={{ position: 'absolute', top: '50%', right: '8px', transform: 'translateY(-50%)', zIndex: -1 }}>
                              <ExpandMoreIcon />
                            </div>
                          )}
                        >
                          <MenuItem value="" disabled>
                            Order
                          </MenuItem>
                          {proposalOptions.map((option, index) => (
                            <MenuItem key={index} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item justifyContent="space-between">
                    <Grid item xs={12} sm={2} md={1} lg={1}>
                      <button
                        data-test-id="filterButtonId"
                        onClick={this.handleFilterSortCard}
                        style={{
                          color: 'rgb(255, 255, 255)',
                          borderRadius: '26px',
                          fontSize: '14px',
                          fontWeight: 600,
                          padding: '0px 18px',
                          background: '#00A7A2',
                          height: '40px',
                          width: '100px',
                          border: 'none',
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <AddCircleOutlineRoundedIcon /><span>Filter</span>
                      </button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={2} md={12} lg={12}>
                    <GenericCardFilter
                      displayFilterSortCard={displayFilterSortCard}
                      labels={["Skills", "Country", "Experience"]}
                      selectedfilterOption={freeLancerListSelectedOption}
                      filterOptions={filterOptions}
                      loading={proposalLoading}
                      activeTab={filterActiveTab}
                      tabLabels={tabLables.tabFilterLabels}
                      activeTabColor={"#00A7A2"}
                      handleFilterSortCard={this.handleFilterSortCard}
                      handleOptionsChange={this.handleProjectBidOptionsChange}
                      handleClearSelectedOptions={this.handleClearSelectedOptions}
                      handleProjectApplyClick={this.handleProjectApplyClick}
                      handleTabChange={(e, val) => this.handleProjectBidTabChange(e, val, 0)}
                    />
                  </Grid>
                </Grid>
      
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  wrap="wrap"
                  style={{ margin: "20px 0" }}
                >
                  <Grid item xs={12} sm={12}>
                    
                      <GenericTab className={'allTabs'} activeTabColor={"#00A7A2 "} tabLabels={["All Proposals","Favourites","Message","Archive"]} activeTab={proposalActiveTab} handleTabChange={(e: React.ChangeEvent<{}>, val: number) => this.handleProposalTabChange(e, val)} />
            
                  </Grid>
                </Grid>
      
                <Grid container spacing={2} alignItems="center" style={{ margin: "20px 0" }}>
                  <CardList
                    cards={proposalCards}
                    loading={proposalLoading}
                    cardLoading={this.state.proposalCardLoading}
                    message={this.state.proposalMessage}
                    hire={this.state.proposalHire}
                    handleMessage={this.handleProposalMessage}
                    handleFavourite={this.handleProposalFavourite}
                    handleHire={this.handleProposalHire}
                    hireList={true}
                  />
                </Grid>
      
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} className="freelancer-search-load-more-grid">
                    {proposalPageNumber < Math.ceil(proposalTotalCards/proposalVisibleCards) && !proposalLoading && (
                      <Button
                        data-test-id="loadMoreId"
                        className="freelancer-search-secondary-button"
                        variant="contained"
                        color="default"
                        style={{
                          backgroundColor: "#F2F2F2",
                          borderRadius: "20px",
                          marginBottom: "10px"
                        }}
                        onClick={this.handleProposalLoadMore}
                        hidden={proposalLoading}
                      >
                        Show More
                        <ArrowForwardIosIcon className="freelancer-search-ArrowForwardIosIcon" />
                      </Button>
                    )}
                    {proposalLoading && <CircularProgress />}
                  </Grid>
                </Grid>
              </div>
            )}
      
            <DashboardFooter history={undefined} location={undefined} />
          </>
        );
      }
      
      
}

// Customizable Area Start

// Customizable Area End
