import React from "react";

// Customizable Area Start
import "../assets/css/Accountinfo.css";
import { lancerlogo } from "./assets";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

import ProfilesetupController, { Props } from "./ProfilesetupController.web";
import ProfileSetupHeader from "../../../components/src/profile-setup/ProfileSetupHeader.web";
import IndividualForm from "../../../components/src/profile-setup/IndividualForm.web";
import ProfileSetupSteper from "../../../components/src/profile-setup/ProfileSetupSteper.web";
import CreateUniversityModal from "../../../components/src/utill/CreateUniversityModal.web";

export default class FreelancerProfieIndividual extends ProfilesetupController {
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.handleChange = this.handleChange.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { uploaded_documents } = this.state;
    return (
      // Customizable Area Start
      <div className="main-wrapper individual_freelancer">
        <ProfileSetupHeader
          email={this.state.loggedInUserEmail}
          onLogout={this.sendLogoutMessage}
        />
        <div className="site-wrapper  individual_freelancer">
          <div className="setup-account-block  individual_freelancer">
            <div className="inner-block">
              <a href="#">
                <img src={lancerlogo} alt="logo" />
              </a>
              <h3>
                Welcome to the Lancerscape Portal. Quickly setup your account to
                get started.
              </h3>
            </div>
            <ProfileSetupSteper
              activeBasicDetails={true}
              activeProfileSetup={true}
            />
          </div>

          <div className="choose-profile-block individual_freelancer">
            <div className="profile-heading  individual_freelancer">
              <h6 style={{}} className="individual_freelancer_subtext">Fill the details for profile(s) selected by you</h6>
            </div>
            <div className="profile-heading  individual_freelancer">
              <b style={{fontWeight:700,color:"#222"}}>Freelancer Profile (Individual)</b>
            </div>
            <div className="profile-tab-wrapper  individual_freelancer">
              <div className="tab-panel-block">
                <div>
                  <div className="form-block">
                    <IndividualForm
                      cancelHandler={this.handleGoBack}
                      submitHandler={(values:any,{setErrors}:any)=>{
                        localStorage.setItem("individualForm",JSON.stringify(values))
                        this.checkIndividualFreelancer({values,setErrors})
                      }}
                      freelancingExperienceList={this.state.freelancingExperienceList}
                      uploaded_documents={uploaded_documents}
                      handleDocumentChange={ this.handleFileChange}
                      submitLoading={this.state.submitLoading}
                      countryList={this.state.countryDataList}
                      countryLoading={this.state.getCountryLoading}
                      getCityList={this.getCityDataList}
                      cityList={this.state.cityDataList}
                      cityLoading={this.state.getCityLoading}
                      stateList={this.state.stateDataList}
                      getStateLoading={this.state.getStateLoading}
                      getStateList={this.getStateDataList}
                      skills={this.state.skillList}
                      categories={this.state.categoriesList}
                      educationList={this.state.educationList}
                      fieldOfStudyList={this.state.fieldOfStudyList}
                      universityList={this.state.universityList}
                      getEducationLoading={this.state.getEducationLoading}
                      handleOpenUniversityModal={this.handleCreateUniversityModalOpen}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CreateUniversityModal
            open={this.state.openCreateUniversityModal}
            setOpen={this.handleCreateUniversityModalOpen}
            createUniversityLoading={this.state.createUniversityLoading}
            onCreateUniversity={this.createNewUniverisy}
        />
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End