import React from "react";
// Customizable Area Start
import {
    Grid,
    Typography,
    Box,
    Avatar,
    Button,
    Divider,
    Tooltip

} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import Sponserfooter from "../../dashboard/src/Sponserfooter.web";
import Sponserheader from "../../dashboard/src/Sponserheader.web";
import PayMilestoneController ,{Props,configJSON,ConversionParams} from './PayMilestoneController.web';
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import CircularProgress from "@material-ui/core/CircularProgress";
import InfoIcon from '@material-ui/icons/Info';
import KnowYourCustomerKycVerification from "../../knowyourcustomerkycverification/src/KnowYourCustomerKycVerification.web";



const getMilestoneCostElement = (freelancerCost: ConversionParams, sponsorCost: ConversionParams,fieldName:"amount"|"first_milestone_cost_with_tax"="amount") => {
  const freelancerAmount=freelancerCost[fieldName] || 0;
  const sponsorAmount=sponsorCost[fieldName] || 0;
  return (
      <>
          {freelancerCost?.currency_symbol} {freelancerAmount?.toFixed(2)} {"   "}
          {sponsorCost?.currency != freelancerCost?.currency &&
                    <>
                        <span style={{ color: "#00a7a2", fontWeight: 400, fontSize: "12px" }} >({sponsorCost?.currency_symbol}{sponsorAmount?.toFixed(2)})</span>
                        <Tooltip enterTouchDelay={0} leaveTouchDelay={3000} title={`Converted from (${freelancerCost?.currency}) into (${sponsorCost?.currency}) based on today's conversion rate`}>
                           <InfoIcon style={
                                {height:'16px',
                                  width:'16px',
                                  color:'#000'}}
                             />
                        </Tooltip></>
          }
      </>
  )
} 
// Customizable Area End

export class PayMilestone extends PayMilestoneController {
    constructor(props: Props) {
        super(props);

        // Customizable Area Start
        // Customizable Area End
    }
      // Customizable Area Start
     

    renderFreelancerHeader = () => {
        const{classes}=this.props;
        const {project}=this.state;
        return <Box className={classes.personalDetailsWrapper}>
                <Box className={classes.frelancerDetails}>
                <Box style={{
                      display:"flex",
                      alignItems:"center",
                      justifyContent:"space-between",
                      width:"100%"
                  }}>
                  <Typography className={classes.pm_projectName}>{project?.attributes?.project_name}</Typography>
                        <div>
                           <KnowYourCustomerKycVerification
                              currentUser={this.state.currentUser}
                              navigation={this.props.navigation}
                           />
                        </div>
                  </Box>
               
                <Grid container>
                                <Grid item xs={12} className={classes.pm_projectDetailsGrid}>
           
                                    <Box className={classes.pm_projectDetailsAvatarBox}>
                              
                                        <Avatar 
                                        // src={auctionBidData?.attributes?.photo ? auctionBidData?.attributes?.photo[0]?.url : ""}
                                        src={project?.attributes?.Hired_freelancers_photo?.url || ""}
                                        className={classes.pm_projectDetailsAvatar}
                                        />
                                        <Box className={classes.pm_projectDetailsNameBox}>
                                        <Typography className={classes.pm_projectDetailsLancerName}>{project?.attributes?.Hired_freelancers?.full_name}</Typography>
                                        <Typography className={classes.pm_skillsStyle}>
                                        </Typography>
                                    </Box>

                                    </Box> 
                                </Grid>
                            </Grid>
                </Box>
            </Box>
     
    };
    renderFreelancerContent = () => {
        const{classes}=this.props;
        const {milestoneCost,payMilestoneLoading,getMilestoneCostLoading}=this.state;
        const conversion=milestoneCost?.CurrencyConversion;
        if(getMilestoneCostLoading){
          return <CircularProgress size={20}/>;
        }else if(!getMilestoneCostLoading && !milestoneCost){
          return <Typography>Unable to fetch milestone cost</Typography>
        }
        return <Box className={classes.personalDetailsWrapper}>
                <Typography className={classes.pm_escrowTxtStyle}>Escrow Deposits</Typography>
                <Box className={classes.pm_escrowAmtDetails }>

                <Typography className={classes.pm_projectDetailsLancerName}>Total Amount </Typography>     
                <Typography className={classes.projectAmtStyle}>{getMilestoneCostElement(conversion?.freelancer as ConversionParams,conversion?.sponsor as ConversionParams,"first_milestone_cost_with_tax")} 
                </Typography>
                </Box>
                <Box>
                                        {
                                            milestoneCost?.TaxForMilestone?.map((item)=>(
                                                <Box className={classes.taxItemContainer}>
                                                    <Typography className={classes.taxItemText}>{item?.name}({item.cost})</Typography>
                                                    <Typography className={classes.taxItemText}>
                                                      {getMilestoneCostElement(
                                                        item.lanceer_currency,
                                                        {
                                                          amount:item.total_cost,
                                                          currency_symbol:conversion?.sponsor.currency_symbol as string,
                                                          currency:conversion?.sponsor.currency as string,
                                                          first_milestone_cost_with_tax:0
                                                        },
                                                        "amount"
                                                      )}</Typography>
                                                </Box>
                                            ))
                                        }
                                        <Divider/>
                                          <Box className={classes.taxItemContainer}>
                                              <Typography className={classes.taxItemText}>Milestone Cost</Typography>
                                              <Typography className={classes.taxItemText}>{getMilestoneCostElement(conversion?.freelancer as ConversionParams,conversion?.sponsor as ConversionParams)} </Typography>
                                          </Box>
                                          <Divider/>
                                          <Box className={classes.taxItemContainer}>
                                              <Typography className={classes.taxItemText}>Total Cost</Typography>
                                              <Typography className={classes.taxItemText}>{getMilestoneCostElement(conversion?.freelancer as ConversionParams,conversion?.sponsor as ConversionParams,"first_milestone_cost_with_tax")} </Typography>
                                          </Box>
                                    </Box>
                <Box className={classes.pm_buttonBox}>
                <Button disabled={payMilestoneLoading} onClick={this.payMilestoneCost} disableFocusRipple variant="contained" className={classes.pm_buttonPay} >
                  {payMilestoneLoading?<CircularProgress size={18}/>:configJSON.payButtonText}
                </Button>
            </Box>

           </Box>
      
    };
     
       // Customizable Area End
    render() {
        // Customizable Area Start
        const {classes}=this.props;
        // Customizable Area End
        return (

            // Customizable Area Start
             <Box>
             <Sponserheader navigation={this.props.navigation} id={""} route={"projects"} />
                <Box className="body_wrapper"> 
                <Box style={{display:"flex",gap:"20px", flexWrap: "wrap", alignItems:"center"}}>
                     <Button
                        data-testid="backBtn"
                        onClick={this.onGoBack}
                        className={classes.backButton}
                        variant="contained"
                        startIcon={<KeyboardBackspaceIcon />}
                        > 
                        Back 
                    </Button>
                    <Typography className={classes.headingText}>Bid For Project</Typography>
                </Box>
                {this.renderFreelancerHeader()}
                {this.renderFreelancerContent()}
                </Box>

                <Sponserfooter navigation={this.props.navigation} id={""} />
             </Box>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
export const styles = () => ({
    backButton: {
        boxShadow: "none",
        borderRadius: "20px",
        padding: "10px 30px",
        textTransform: "capitalize",
      } as const,
      headingText: {
        fontWeight: "bolder",
        fontSize: "24px",
        color: "black",
      } as const,
    personalDetailsWrapper: {
        border: '1px solid #e4e6e8',
        borderRadius: '16px',
        margin: '20px 0',
        padding: '20px 25px',
        display: "flex",
        flexDirection:'column' as const,
        flexWrap: "wrap" as const,
        justifyContent: "space-between",
        alignItems: 'flex-start',
        backgroundColor: '#F8F7FC',
      },
      frelancerDetails :{
        display: "flex",
        flexWrap: "wrap" as const,
        justifyContent: "space-between",
        alignItems: 'flex-start',
      },
      pm_escrowAmtDetails :{
        display: "flex",
        flexWrap: "wrap" as const,
        alignItems: 'center',
        marginTop:'1vw',
        gap: "10px"
      },

      pm_escrowTxtStyle:{
        color: '#333333',
        fontSize: '20px',
        fontWeight: 500,
      },

   
pm_projectDetailsGrid:{
  padding: '1.5vw',
},

  pm_projectDetailsAvatarBox:{
    display:'flex', 
    alignItems:'center',
    marginTop:'0.5vw',
    gap: "10px"
  },
  pm_projectDetailsName:{
    color: '#222222',
    fontSize: '20px',
    fontWeight: 700,
  },
  pm_projectDetailsAvatar:{
    width: '50px',
    height: '50px',
  },
  pm_projectDetailsLancerName:{
    color: '#333333',
    fontSize: '16px',
    fontWeight: 600,
  },
  projectAmtStyle:{
    color: '#333333',
    fontSize: '20px',
    fontWeight: 700,
    display: "flex",
    alignItems: "center"
  },
  pm_skillsStyle:{
    color: '#8083a3',
    fontSize: '14px',
    fontWeight: 400,
  },
  pm_projectName:{
    color: '#333333',
    fontSize: '24px',
    fontWeight: 600,
  },

  pm_projectDetailsNameBox:{
    display:'flex', 
    flexDirection: 'column' as const, 
    paddingRight:'2vw',
    paddingLeft:'1vw',
  },

   pm_buttonBox:{
    display:'flex',
    margin: '2vw 0',
  },

  pm_buttonPay:{
    color: '#fff',
    fontSize:'12px',
    fontWeight: 700,
    width: '168px',
    height: '42px',
    borderRadius: '20px',
    backgroundColor: '#00a7a2',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    '&:hover': {
        backgroundColor: '#00a7a2',
      },
},
taxItemContainer:{
  minWidth:"350px",
  display:"flex",
  alignItems:"center",
  justifyContent:"space-between",
  "@media(max-width: 640px)": {
    width: "100%",
    minWidth: "auto"
  },
},
taxItemText:{
  color:"#8083a3",
  fontSize:"12px",
  display: "flex",
  alignItems: "center"
},
 
});

export default withStyles(styles)(PayMilestone);

// Customizable Area End