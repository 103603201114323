Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.dashboardBannerGetUrl = "bx_block_custom_ads/advertisements";
exports.getsponsoractivebidUrl='bx_block_auctionbidding/bids/sponsor_active_bids?current_page=1&per_page=3'
exports.notificationsCountAPIEndpoint = "bx_block_notifications/notification_count"
exports.loadingMsg = "No Project Found";
exports.fixedPriceTxt= "Fixed Price";
exports.activebidtext= "Active Quotes";
exports.estimatedTimeTxt="Est. Time: 1 to 2 Months";
exports.estimatedTimeTxtLabel="Est. Time:";
exports.estimatedBudgetTxt="Estimated Budget";
exports.durationTxt="Duration";
exports.experienceTxt="Experience";
exports.languageTxt="Language";
exports.currentBidTxt="Current Quotes";
exports.currentBidTxtData="49";
exports.showSimilarTxt="Show Similiar";
exports.bidOnThisProjectTxt="Quote On this Project";
exports.showMoreTxt="Show More";
exports.somethingWentWrongTxt="Some Thing Went Wrong";
exports.browseAllProjectTxt="Browse all Project";
exports.placeHolderNameTxt="Search Project, Skill, or Category";
exports.placeHolderNameTxtSponsor= "Search Freelancer, Skill, or Category";
exports.filterTxt="Filter";
exports.getProfileIdEndpoint = 'bx_block_profile/profiles/null'
exports.httpGetMethod = 'GET'
exports.totalBudgetTxt = 'Total Budget'
exports.totalAmtPaidTxt = 'Total Amount Paid'
exports.totalAmtEscrowTxt = 'Total Amount in Escrow'
exports.totalMilestonesTxt = 'No. of Milestone Completed'
exports.postedOnText = 'Posted '
exports.getSingleProjectApiUrl="bx_block_profile/projects"
exports.submitBidApiUrl="bx_block_auctionbidding/bids"
exports.getMyBidsApiUrl="bx_block_auctionbidding/bids"
exports.applyProjectApiUrl="bx_block_auctionbidding/bids"
exports.getCurrentUserApiUrl="bx_block_profile/profiles/profile_detail"
exports.getFooterDataApiUrl = "bx_block_content_management/sections"
exports.sortAndistoText="Sort:"
exports.getHourlyRatesApiCallUrl = "bx_block_profile/hour_rates"
exports.dollarSignTxt = "$"
exports.amountPlaceholderTxt= "Enter Quote Amount"
exports.proposalPlaceholderTxt="Write your proposal here"
exports.getRatingAndReviewsAPIEndpoint= "bx_block_reviews/reviews/?current_page=1&per_page=2&rated_from=sponsor"
exports.performanceChartLabel = "Performance Chart"
exports.getFreelancerProjectsApiCallUrl ='bx_block_profile/projects/freelancer_completed_projects_list'
exports.getFreelancerProjectsChartListApiCallUrl ='bx_block_profile/projects/freelancer_performance_chart?project_id='
exports.getSponsorProjectsApiCallUrl ='bx_block_profile/projects/sponsor_completed_projects_list'
exports.getPaymentDataApiCallUrl ='bx_block_profile/profiles/payment_report'
exports.getSponsorProjectsChartListApiCallUrl ='bx_block_profile/projects/sponsor_performance_chart?project_id='
exports.activeProjectsLabel="Active Projects"
exports.allProjectsLabel= "All Projects"
exports.NoProjectsLabel= "No projects found"
exports.NoChartLabel= "No data found for performance chart"
exports.addFavouritesURL = "bx_block_favourites/favourites"
exports.getProjectApiUrl="bx_block_profile/projects"
exports.httpPostMethod = 'POST'
exports.showMoreBtn="Show More"
exports.getFreelancingExperienceApiUrl="bx_block_auctionbidding/freelancer_experiences"
exports.getCountryListApiUrl="bx_block_profile/profiles/get_countries"

// Customizable Area End